import { CustomGridColDef } from '@/hooks/useTable/types';

export enum ACTION_OPTIONS {
  EDIT = 'EDIT',
  DETAIL = 'DETAIL',
  PREVIEW = 'PREVIEW',
}

// interface IGenerateColumnProps {
  // onAction: (option: ACTION_OPTIONS, row: CustomerInterface) => void;
// }

export const getTableColumns = (): CustomGridColDef[] => [
  {
    field: 'name',
    headerName: 'Name',
    minWidth: 174,
    flex: 1,
    sortable: true,
    hide: true,
    link: params => `/customers/${params.row.id}`,
  },
  {
    field: 'address',
    headerName: 'Address',
    minWidth: 227,
    flex: 1,
    sortable: true,
    link: params => `/customers/${params.row.id}`,
  },
  {
    field: 'phone',
    headerName: 'Mobile',
    minWidth: 152,
    flex: 1,
    sortable: false,
    renderCell: params => params.row.phone[0],
    link: params => `/customers/${params.row.id}`,
  },
  {
    field: 'email',
    headerName: 'Email',
    minWidth: 164,
    hide: true,
    flex: 1,
    sortable: false,
    renderCell: params => params.row.email,
    link: params => `/customers/${params.row.id}`,
  },
  {
    field: 'lastService',
    headerName: 'Last Service',
    type: 'date',
    minWidth: 209,
    flex: 1,
    sortable: false,
    renderCell: params => params.row.date.toLocaleDateString(),
    link: params => `/customers/${params.row.id}`,
  },
];
