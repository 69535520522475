import { useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { CategoryInterface, CategoryOptions } from '@plenumpro/shared';
import {
  fetchCategoriesOptionsApi,
  getAllCategoriesApi,
  getCategoryIdApi,
  getSubCategoriesApi,
} from './api';

export const useFetchCategoryId = (categoryId: string) => {
  return useQuery<CategoryOptions[], AxiosError>(
    ['categoryOptions', categoryId],
    () => getCategoryIdApi(categoryId),
    {
      enabled: !!categoryId,
      onError: (error: AxiosError) => {
        console.error('Error fetching category options:', error.message);
      },
    },
  );
};

export const useFetchAllCategories = () => {
  return useQuery<CategoryInterface[], AxiosError>(
    'allCategories',
    getAllCategoriesApi,
    {
      staleTime: 5 * 60 * 1000,
      onError: error => {
        console.error('Error fetching categories:', error.message);
      },
    },
  );
};

export const useGetSubCategories = () => {
  return useQuery<CategoryInterface[], AxiosError>(
    'subCategories',
    getSubCategoriesApi,
    {
      staleTime: 5 * 60 * 1000,
      onError: (error: AxiosError) => {
        console.error('Error fetching subcategories:', error.message);
      },
    },
  );
};

export const useFetchCategoriesOptions = () => {
  return useQuery<CategoryOptions[], AxiosError>(
    'categoriesOptions',
    fetchCategoriesOptionsApi,
    {
      staleTime: 5 * 60 * 1000,
      onError: (error: AxiosError) => {
        console.error('Error fetching subcategories:', error.message);
      },
    },
  );
};
