import styled from '@emotion/styled';
import { Box, Card } from '@mui/material';

export const StyledCard = styled(Card)(() => ({
  padding: 2,
  flexGrow: 1,
  maxHeight: '300px',
  minHeight: '50px',
  backgroundColor: 'white',
  borderRadius: '12px',
  boxShadow: '0px 1px 2px 0px #0000001A',
}));

export const StyledCardHeader = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '16px',
}));
