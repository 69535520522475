const SettingIcon = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none">
      <path
        d="M12.0001 16C11.0718 16 10.1816 15.6313 9.52521 14.9749C8.86883 14.3185 8.50008 13.4283 8.50008 12.5C8.50008 11.5717 8.86883 10.6815 9.52521 10.0251C10.1816 9.36875 11.0718 9 12.0001 9C12.9283 9 13.8186 9.36875 14.475 10.0251C15.1313 10.6815 15.5001 11.5717 15.5001 12.5C15.5001 13.4283 15.1313 14.3185 14.475 14.9749C13.8186 15.6313 12.9283 16 12.0001 16ZM19.4301 13.47C19.4701 13.15 19.5001 12.83 19.5001 12.5C19.5001 12.17 19.4701 11.84 19.4301 11.5L21.5401 9.87C21.7301 9.72 21.7801 9.45 21.6601 9.23L19.6601 5.77C19.5401 5.55 19.2701 5.46 19.0501 5.55L16.5601 6.55C16.0401 6.16 15.5001 5.82 14.8701 5.57L14.5001 2.92C14.4798 2.80222 14.4184 2.69543 14.3269 2.61855C14.2354 2.54168 14.1196 2.49968 14.0001 2.5H10.0001C9.75008 2.5 9.54008 2.68 9.50008 2.92L9.13008 5.57C8.50008 5.82 7.96008 6.16 7.44008 6.55L4.95008 5.55C4.73008 5.46 4.46008 5.55 4.34008 5.77L2.34008 9.23C2.21008 9.45 2.27008 9.72 2.46008 9.87L4.57008 11.5C4.53008 11.84 4.50008 12.17 4.50008 12.5C4.50008 12.83 4.53008 13.15 4.57008 13.47L2.46008 15.13C2.27008 15.28 2.21008 15.55 2.34008 15.77L4.34008 19.23C4.46008 19.45 4.73008 19.53 4.95008 19.45L7.44008 18.44C7.96008 18.84 8.50008 19.18 9.13008 19.43L9.50008 22.08C9.54008 22.32 9.75008 22.5 10.0001 22.5H14.0001C14.2501 22.5 14.4601 22.32 14.5001 22.08L14.8701 19.43C15.5001 19.17 16.0401 18.84 16.5601 18.44L19.0501 19.45C19.2701 19.53 19.5401 19.45 19.6601 19.23L21.6601 15.77C21.7801 15.55 21.7301 15.28 21.5401 15.13L19.4301 13.47Z"
        fill="#FEFEFE"
      />
    </svg>
  );
};

export default SettingIcon;
