import { FC, ReactNode, SyntheticEvent, useState } from 'react';
import { Box } from '@mui/material';

// import { PrimaryButton } from '../common';
// import { DesignServices } from '@mui/icons-material';
// import useToggle from '@/hooks/useToggle';

import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { CategoryListing, SubCategoriesListing, ServiceListing } from './parts';

interface TabData {
  label: string;
  value: string;
  content: ReactNode;
}

const ServicesCatalog: FC = () => {
  const [value, setValue] = useState('services');

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  // Define an array of tab data
  const tabs: TabData[] = [
    {
      label: 'Services',
      value: 'services',
      content: <ServiceListing rows={[]} searchText="" />,
    },
    {
      label: 'Categories',
      value: 'category',
      content: <CategoryListing rows={[]} searchText="" />,
    },
    {
      label: 'Sub Categories',
      value: 'subCategory',
      content: <SubCategoriesListing rows={[]} searchText="" />,
    },
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '48px',
        padding: '30px',
        height: '100%',
      }}
    >
      <Box
        sx={{
          width: '100%',
          typography: 'body1',
        }}
      >
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="dynamic tabs example">
              {tabs.map(tab => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </TabList>
          </Box>
          {tabs.map(tab => (
            <TabPanel key={tab.value} value={tab.value}>
              {tab.content}
            </TabPanel>
          ))}
        </TabContext>
      </Box>
    </Box>
  );
};

export default ServicesCatalog;
