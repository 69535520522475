const PlenumLogo = () => {
  return (
    <svg
      width="131"
      height="33"
      viewBox="0 0 131 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M42.5012 6.98368C41.4886 6.47028 40.3416 6.21387 39.0599 6.21387H31.3054V26.7863H34.1304V18.7817H39.0599C40.3416 18.7817 41.4886 18.5206 42.5012 17.9972C43.5137 17.4735 44.3151 16.7419 44.9061 15.8024C45.4967 14.8644 45.7928 13.7626 45.7928 12.4971C45.7928 11.2315 45.4967 10.1018 44.9061 9.16227C44.3154 8.22427 43.5137 7.49856 42.5012 6.98368ZM42.4114 14.5427C42.0599 15.1164 41.5799 15.5604 40.9683 15.8774C40.3565 16.1929 39.6612 16.3508 38.8792 16.3508H34.1307V8.64416H38.8792C39.6612 8.64416 40.3568 8.80207 40.9683 9.11758C41.5799 9.43486 42.0602 9.87857 42.4114 10.4523C42.7614 11.0245 42.938 11.7075 42.938 12.4965C42.938 13.2854 42.7614 13.9699 42.4114 14.5422V14.5427Z"
        fill="white"
      />
      <path
        d="M51.7434 5.62012H49.0382V26.7856H51.7434V5.62012Z"
        fill="white"
      />
      <path
        d="M65.6446 13.2977C64.6321 12.7049 63.4657 12.4082 62.1434 12.4082C60.8211 12.4082 59.5128 12.7299 58.4 13.3712C57.2888 14.0143 56.4066 14.8888 55.756 15.995C55.1039 17.1027 54.7795 18.3474 54.7795 19.7297C54.7795 21.112 55.1102 22.389 55.7712 23.4952C56.4322 24.6014 57.3341 25.4759 58.4767 26.1175C59.6177 26.7606 60.901 27.082 62.3232 27.082C63.4448 27.082 64.5021 26.8741 65.4952 26.4595C66.4869 26.0437 67.3229 25.4418 68.0048 24.6511L66.2609 22.9019C65.7809 23.455 65.2037 23.8693 64.5319 24.1466C63.8605 24.4238 63.1246 24.5612 62.3229 24.5612C61.3613 24.5612 60.5089 24.3591 59.7686 23.9534C59.0268 23.5493 58.4555 22.9754 58.0548 22.235C57.8094 21.7821 57.6406 21.2822 57.5464 20.738H68.7255C68.7854 20.4416 68.8256 20.179 68.845 19.952C68.8659 19.725 68.8763 19.5124 68.8763 19.3148C68.8763 17.9904 68.5907 16.806 68.0194 15.7571C67.4482 14.71 66.6569 13.8899 65.6446 13.2968V13.2977ZM57.5524 18.5144C57.6436 18.0057 57.8008 17.5408 58.025 17.1221C58.4063 16.4096 58.9463 15.8565 59.6475 15.4613C60.3488 15.0661 61.161 14.8685 62.0823 14.8685C63.0036 14.8685 63.7156 15.0514 64.3375 15.4172C64.9583 15.783 65.4398 16.2964 65.7806 16.9586C66.0126 17.4099 66.1634 17.9304 66.2368 18.5147H57.5521L57.5524 18.5144Z"
        fill="white"
      />
      <path
        d="M125.098 14.8982C124.607 14.1081 123.951 13.4953 123.129 13.0604C122.307 12.6261 121.376 12.4082 120.334 12.4082C119.292 12.4082 118.41 12.6305 117.568 13.0751C116.727 13.5197 116.04 14.1325 115.509 14.9129C115.499 14.9273 115.491 14.9429 115.482 14.9573C115.47 14.9379 115.461 14.9173 115.449 14.8982C114.978 14.1081 114.337 13.4953 113.526 13.0604C112.714 12.6261 111.807 12.4082 110.805 12.4082C109.804 12.4082 108.861 12.6305 108.04 13.0751C107.433 13.4036 106.922 13.827 106.507 14.3442V12.7046H103.802V26.7853H106.507V18.248C106.507 17.5564 106.667 16.9636 106.988 16.4693C107.308 15.9756 107.729 15.5901 108.25 15.3131C108.771 15.0367 109.362 14.8982 110.024 14.8982C111.005 14.8982 111.822 15.1996 112.473 15.8024C113.124 16.4055 113.45 17.2303 113.45 18.2777V26.7853H116.155V18.248C116.155 17.5564 116.315 16.9636 116.636 16.4693C116.956 15.9756 117.382 15.5901 117.913 15.3131C118.444 15.0367 119.041 14.8982 119.702 14.8982C120.664 14.8982 121.47 15.1996 122.121 15.8024C122.772 16.4055 123.098 17.2303 123.098 18.2777V26.7853H125.833V17.744C125.833 16.6375 125.588 15.6889 125.097 14.8982H125.098Z"
        fill="white"
      />
      <path
        d="M97.4608 24.1179H99.2062H100.165V20.5897V12.7051H97.4608V24.1179Z"
        fill="white"
      />
      <path
        d="M93.6405 24.1179C93.1512 24.1179 92.6637 24.0077 92.2395 23.7674C92.2365 23.7657 92.2338 23.7642 92.2308 23.7624C91.7088 23.466 91.314 23.0352 91.0433 22.4718C90.7726 21.9099 90.6366 21.2418 90.6366 20.4717V12.7051H87.9326V20.5899C87.9326 21.8155 88.1914 22.8923 88.7132 23.8215C88.7701 23.9233 88.8298 24.0221 88.8912 24.1179C89.3951 24.9068 90.0623 25.5338 90.8921 25.9998C91.8238 26.5235 92.8811 26.7858 94.0642 26.7858H97.4605V24.1176H93.6402L93.6405 24.1179Z"
        fill="white"
      />
      <path
        d="M74.5255 15.373H72.7801H71.8215V18.901V26.7856H74.5255V15.373Z"
        fill="white"
      />
      <path
        d="M78.9182 15.373C79.4075 15.373 79.8949 15.4832 80.3192 15.7235C80.3222 15.7252 80.3249 15.7267 80.3279 15.7285C80.8499 16.0249 81.2447 16.4556 81.5154 17.019C81.7861 17.581 81.9221 18.249 81.9221 19.0191V26.7855H84.6261V18.9009C84.6261 17.6754 84.3673 16.5986 83.8455 15.6694C83.7885 15.5676 83.7289 15.4688 83.6675 15.373C83.1636 14.584 82.4963 13.9571 81.6666 13.4911C80.7348 12.9674 79.6776 12.7051 78.4945 12.7051H74.5258V13.6284V15.3733H78.9185L78.9182 15.373Z"
        fill="white"
      />
      <path
        d="M131 24.9365V26.7861H130.747V25.4837L130.179 26.4071H129.995L129.422 25.4764V26.7861H129.169V25.1606H128.407V26.7861H128.152V25.1606H127.515V24.9365H129.347L130.085 26.1209L130.822 24.9365H131Z"
        fill="white"
      />
      <path
        d="M14.5789 12.4159V6.20801H0V20.5843H6.29645V12.4159H14.5789Z"
        fill="white"
      />
      <path d="M0 32.9999H6.29645V29.4725V26.792H0V32.9999Z" fill="white" />
      <path
        d="M14.5789 6.20792H20.8753V2.68083V0H14.5789V6.20792Z"
        fill="white"
      />
      <path
        d="M14.5788 12.416V20.5843H6.29639V26.7923H20.8753V12.416H14.5788Z"
        fill="white"
      />
    </svg>
  );
};

export default PlenumLogo;
