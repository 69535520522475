import { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Grid, IconButton, Typography } from '@mui/material';

import {
  ButtonsContainer,
  InfoWrapper,
  ScheduleWrapper,
  DateTimeWrapper,
} from '../CreateJob.styles';
import { BoxedLeftArrowIcon, BoxedRightArrowIcon } from '@/assets/icons';
import {
  StringInputHook,
  SecondaryButton,
  CheckBoxInputHook,
} from '@/components';
import { JobFormSchema, TIME_OPTIONS, TJobForm } from '@/utils';

interface JobFormProps {
  onBack: () => void;
  onClose: () => void;
  onCreateCustomer: (data: TJobForm) => void;
}

const JobForm: FC<JobFormProps> = ({ onBack, onClose, onCreateCustomer }) => {
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [displayedDates, setDisplayedDates] = useState<Date[]>([]);

  // const { control, handleSubmit } = useForm<TJobForm>({
  //   resolver: zodResolver(JobFormSchema),
  //   mode: 'onSubmit',
  // });

  const formMethods = useForm<TJobForm>({
    resolver: zodResolver(JobFormSchema),
    mode: 'all',
  });

  const { handleSubmit } = formMethods;

  const onSubmit = (data: TJobForm) => {
    onCreateCustomer(data);
    onClose();
  };

  const handleBackToAppointment = () => {
    onBack();
  };

  const generateAvailableDates = (startDate: Date): Date[] => {
    const dates: Date[] = [];
    const currentMonth = startDate.getMonth();
    const currentYear = startDate.getFullYear();
    let date = new Date(startDate);
    date.setDate(date.getDate() - (date.getDay() || 7) + 1);
    const today = new Date().setHours(0, 0, 0, 0);
    while (dates.length < 5 && date.getMonth() === currentMonth) {
      if (date.getDay() !== 0 && date.getDay() !== 6) {
        dates.push(new Date(date));
      }
      date.setDate(date.getDate() + 1);
    }
    if (dates.length < 5) {
      date = new Date(currentYear, currentMonth + 1, 1);
      while (dates.length < 5) {
        if (date.getDay() !== 0 && date.getDay() !== 6) {
          if (date.getTime() >= today) {
            dates.push(new Date(date));
          }
        }
        date.setDate(date.getDate() + 1);
        if (date.getMonth() !== currentMonth + 1) {
          break;
        }
      }
    }
    return dates;
  };

  useEffect(() => {
    setDisplayedDates(generateAvailableDates(currentDate));
  }, [currentDate]);

  const handleNextWeek = () => {
    const nextDate = new Date(currentDate);
    nextDate.setDate(nextDate.getDate() + 7);
    setCurrentDate(nextDate);
    setDisplayedDates(generateAvailableDates(nextDate));
  };

  const handlePreviousWeek = () => {
    const previousWeekDate = new Date(currentDate);
    previousWeekDate.setDate(previousWeekDate.getDate() - 7);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    if (previousWeekDate < today) {
      setCurrentDate(today);
      setDisplayedDates(generateAvailableDates(today));
    } else {
      setCurrentDate(previousWeekDate);
      setDisplayedDates(generateAvailableDates(previousWeekDate));
    }
  };

  const getCurrentMonthYear = () => {
    return currentDate.toLocaleString('default', {
      month: 'long',
      year: 'numeric',
    });
  };

  const todayDate = new Date();
  todayDate.setHours(0, 0, 0, 0);
  const options = displayedDates.map(date => ({
    label: date.getDate().toString(),
    value: date.toISOString(),
    day: date.toLocaleString('default', { weekday: 'short' }),
    slot: '5',
    disabled: date < todayDate,
  }));

  return (
    <>
      <FormProvider {...formMethods}>
        <Grid alignSelf={'center'}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <InfoWrapper>
              <StringInputHook
                label="Customer Name"
                name="customerName"
                placeholder="Customer Name"
              />
              <StringInputHook
                label="Job Description"
                name="jobDescription"
                placeholder="Job Description"
                multiline={true}
                rows={4}
              />

              <Grid
                item
                xs={6}
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' },
                  gap: '12px',
                }}
              >
                <StringInputHook
                  label="Status"
                  name="status"
                  placeholder="Status"
                  fullWidth
                />
                <StringInputHook
                  label="Amount"
                  name="amount"
                  placeholder="Amount"
                  fullWidth
                />
              </Grid>
            </InfoWrapper>

            <ScheduleWrapper>
              <Typography
                sx={{
                  color: '#4F4F4F',
                  fontWeight: 700,
                  fontSize: '16px',
                  marginBottom: '8px',
                }}
              >
                Scheduled for
              </Typography>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                marginBottom="24px"
                paddingRight={{ xs: '12px', md: '0px' }}
                paddingLeft={{ xs: '12px', md: '0px' }}
              >
                <Grid item>
                  <IconButton
                    onClick={handlePreviousWeek}
                    disabled={displayedDates[0] <= new Date()}
                  >
                    <BoxedLeftArrowIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  <Typography variant="h6">{getCurrentMonthYear()}</Typography>
                </Grid>
                <Grid item>
                  <IconButton onClick={handleNextWeek}>
                    <BoxedRightArrowIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <DateTimeWrapper>
                <CheckBoxInputHook
                  name="dateoptions"
                  options={options}
                  width={{ xs: '61px', md: '80px' }}
                  height={'106px'}
                  gap={{ xs: '7px', lg: '11px' }}
                  paddingX={{ xs: '0px' }}
                />
                <Box
                  paddingLeft={{ xs: '12px', md: '0px', lg: '5px', xl: '0px' }}
                >
                  <CheckBoxInputHook
                    name="timeoptions"
                    options={TIME_OPTIONS}
                    width={{ xs: '120px', md: '140px' }}
                    gap={'12px'}
                    paddingX={{ xs: '0px' }}
                    paddingY={{ xs: '12px' }}
                    justifyContent={'start'}
                    fontSize="14px"
                  />
                </Box>
              </DateTimeWrapper>
            </ScheduleWrapper>
          </form>
        </Grid>
      </FormProvider>

      <ButtonsContainer>
        <SecondaryButton
          variant="outlined"
          onClick={handleBackToAppointment}
          sx={{ marginBottom: { xs: '12px', sm: 0 } }}
        >
          Create job later
        </SecondaryButton>
        <SecondaryButton variant="contained" onClick={handleSubmit(onSubmit)}>
          Create job
        </SecondaryButton>
      </ButtonsContainer>
    </>
  );
};

export default JobForm;
