import { TextField, styled } from '@mui/material';

const StyledStringInput = styled(TextField)<{
  width?: string | Record<string, string>;
  fullWidth?: boolean;
}>(({ theme, width, fullWidth }) => ({
  borderRadius: '4px',
  fontSize: '16px',
  lineHeight: '22.4px',
  backgroundColor: '#FFFFFF',

  '& input': {
    padding: '16px 24px',
    width: fullWidth ? '100%' : undefined,
    [theme.breakpoints.up('xs')]: {
      width: fullWidth ? '100%' : typeof width === 'object' ? width?.xs : width,
    },
    [theme.breakpoints.up('md')]: {
      width: fullWidth ? '100%' : typeof width === 'object' ? width?.md : width,
    },
    height: '55px',
    boxSizing: 'border-box',
    resize: 'none',
    '&:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 100px #fff inset', 
      WebkitTextFillColor: '#000', 
    },
    '&:-webkit-autofill:hover, &:-webkit-autofill:focus': {
      WebkitBoxShadow: '0 0 0 100px #fff inset', 
      WebkitTextFillColor: '#000', 
    },
  },
  '& textarea': {
    width: fullWidth ? '100%' : undefined,
    [theme.breakpoints.up('xs')]: {
      width: fullWidth ? '100%' : typeof width === 'object' ? width?.xs : width,
    },
    [theme.breakpoints.up('md')]: {
      width: fullWidth ? '100%' : typeof width === 'object' ? width?.md : width,
    },
    [theme.breakpoints.up('lg')]: {
      width: fullWidth ? '100%' : typeof width === 'object' ? width?.lg : width,
    },
  },
  '& .MuiOutlinedInput-root': {
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.grey[50],
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.secondary.main,
      boxShadow: `0px 0px 0px 5px rgba(39, 150, 96, 0.1)`,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: `2px solid ${theme.palette.grey[50]}`,
    },
  },
}));

export default StyledStringInput;
