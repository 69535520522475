export const customHeaderStyles = {
  container: {
    zIndex:'100',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '60px',
    overflow: 'hidden',
  },
  dayPart: {
    fontSize: '16px',
    color: 'gray',
  },
  datePart: {
    fontWeight: 'bold',
    fontSize: '20px',
  },
};
