
const UsersIcon = () => {
  return (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 6C9.85747 6 9.23622 5.71313 8.74997 5.1925C8.27715 4.68469 7.9884 4.0075 7.93747 3.28625C7.88309 2.51688 8.11778 1.80938 8.59809 1.29375C9.0784 0.778125 9.74997 0.5 10.5 0.5C11.2447 0.5 11.9181 0.783125 12.3968 1.2975C12.8803 1.81687 13.1156 2.52312 13.0612 3.28594C13.009 4.00812 12.7206 4.685 12.2487 5.19219C11.7637 5.71312 11.1428 6 10.5 6ZM14.6197 11.5H6.38059C6.24812 11.5007 6.11726 11.471 5.99807 11.4132C5.87889 11.3554 5.77455 11.271 5.69309 11.1666C5.60668 11.0533 5.54702 10.922 5.51855 10.7824C5.49008 10.6429 5.49356 10.4987 5.52871 10.3606C5.79184 9.30406 6.44372 8.42781 7.41372 7.82687C8.27465 7.29375 9.37059 7 10.5 7C11.6515 7 12.7187 7.28125 13.5847 7.81406C14.5568 8.41187 15.2097 9.29313 15.4715 10.3625C15.5063 10.5006 15.5093 10.6448 15.4806 10.7843C15.4518 10.9238 15.3919 11.055 15.3053 11.1681C15.2239 11.2721 15.1198 11.3561 15.001 11.4136C14.8821 11.4711 14.7517 11.5007 14.6197 11.5ZM4.59371 6.125C3.49403 6.125 2.52715 5.1025 2.43746 3.84594C2.39309 3.20219 2.59371 2.60687 2.99996 2.17031C3.40184 1.73812 3.96871 1.5 4.59371 1.5C5.21871 1.5 5.78121 1.73938 6.18528 2.17406C6.59465 2.61406 6.79465 3.20812 6.74778 3.84656C6.65809 5.10281 5.69153 6.125 4.59371 6.125ZM6.64559 7.10781C6.0959 6.83906 5.38246 6.70469 4.59403 6.70469C3.6734 6.70469 2.77934 6.94469 2.07621 7.38031C1.27903 7.875 0.742777 8.59531 0.526214 9.465C0.494522 9.59009 0.491525 9.72073 0.517446 9.84715C0.543367 9.97356 0.597537 10.0925 0.675902 10.195C0.750261 10.2905 0.845522 10.3676 0.95436 10.4205C1.0632 10.4733 1.18271 10.5006 1.30371 10.5H4.77246C4.83101 10.5 4.8877 10.4794 4.93264 10.4419C4.97759 10.4044 5.00794 10.3523 5.0184 10.2947C5.02184 10.275 5.02621 10.2553 5.03121 10.2359C5.29621 9.17156 5.91715 8.27219 6.83465 7.61656C6.8684 7.59224 6.89554 7.55988 6.91362 7.52241C6.9317 7.48495 6.94014 7.44356 6.93819 7.40201C6.93623 7.36046 6.92394 7.32005 6.90242 7.28445C6.88091 7.24884 6.85085 7.21917 6.81497 7.19812C6.7659 7.16937 6.70965 7.13906 6.64559 7.10781Z"
        fill="#BDBDBD"
      />
    </svg>
  );
}

export default UsersIcon
