import { FC } from 'react';

interface IconProps {
  isActive?: boolean;
}

const ReportingIcon: FC<IconProps> = ({ isActive = false }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 18C17.103 18 18 17.103 18 16V2C18 0.897 17.103 0 16 0H2C0.897 0 0 0.897 0 2V16C0 17.103 0.897 18 2 18H16ZM6.553 6.658L10.553 8.658L12.106 5.553L13.895 6.448L11.448 11.343L7.448 9.343L5.895 12.448L4.106 11.553L6.553 6.658Z"
        fill={isActive ? '#279660' : '#828F87'}
      />
    </svg>
  );
};

export default ReportingIcon;
