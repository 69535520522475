import { Box, Typography } from '@mui/material';
import { CircularGraph } from '../parts';
import { InvoicesChip } from '../parts/Chips';
import { InvoicesIcon, RightArrowIcon } from '@/assets/icons';

const InvoicesCard = () => {
  return (
    <Box
      sx={{
        padding: '24px',
        backgroundColor: 'white',
        borderRadius: '12px',
        boxShadow: '0px 1px 2px 0px #0000001A',
        // width:'300px',
        // minHeight: '500px',
        flexGrow: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingBottom: '16px',
          borderBottom: '1px solid #E0E0E0',
        }}
      >
        <Box sx={{ display: 'flex', gap: '8px' }}>
          <InvoicesIcon />
          <Typography sx={{ fontSize: '20px', fontWeight: '600px' }}>
            Invoices
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: '9px', color: 'grey.200' }}>
          <Typography>See all invoices</Typography>
          <Box>
            <RightArrowIcon />
          </Box>
        </Box>
      </Box>
      <Box>
        <CircularGraph />
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '13px' }}>
          <InvoicesChip
            heading="Paid"
            price="12000"
            backgroundColor="#9AE66E"
          />
          <InvoicesChip
            heading="Unpaid"
            price="12000"
            backgroundColor="#9667F6"
          />
          <InvoicesChip heading="Due" price="12000" backgroundColor="#FF5F00" />
        </Box>
      </Box>
    </Box>
  );
};

export default InvoicesCard;
