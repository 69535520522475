import {
  DashboardIcon,
  InboxIcon,
  CalenderIcon,
  CustomersIcon,
  JobsIcon,
  EstimatesIcon,
  InvoicesIcon,
  MoneyIcon,
  ReportingIcon,
} from '@/assets/icons';
import { DesignServices, Settings } from '@mui/icons-material';
import { SvgIcon } from '@mui/material';
import { EPermissions } from '@plenumpro/shared';

export interface SidebarItem {
  text: string;
  icon: JSX.Element;
  path?: string;
  permissions?: EPermissions[];
  handler?: () => void;
}

export const MUICustomIcon = ({
  isActive = false,
  children,
  ...props
}: {
  isActive?: boolean;
  children: React.ReactNode;
}) => {
  return (
    <SvgIcon {...props} sx={{ color: isActive ? '#279660' : '#828F87' }}>
      {children}
    </SvgIcon>
  );
};

export const SIDEBAR_ITEMS: SidebarItem[] = [
  {
    text: 'Dashboard',
    icon: <DashboardIcon />,
    path: '/dashboard',
    permissions: [EPermissions.VIEW_DASHBOARD],
  },
  {
    text: 'Inbox',
    icon: <InboxIcon />,
    path: '#',
  },
  {
    text: 'Calendar',
    icon: <CalenderIcon />,
    path: '/calendar',
    permissions: [EPermissions.VIEW_DASHBOARD],
  },
  {
    text: 'Customers',
    icon: <CustomersIcon />,
    path: '/customers',
    permissions: [EPermissions.VIEW_CUSTOMERS],
  },
  {
    text: 'Jobs',
    icon: <JobsIcon />,
    path: '/jobs',
  },
  {
    text: 'Estimates',
    icon: <EstimatesIcon />,
    path: '/estimates',
  },
  {
    text: 'Invoices',
    icon: <InvoicesIcon />,
    path: '#',
  },
  {
    text: 'My Money',
    icon: <MoneyIcon />,
    path: '#',
  },
  {
    text: 'Reporting',
    icon: <ReportingIcon />,
    path: '#',
  },
  {
    text: 'Services Catalog',
    icon: (
      <MUICustomIcon>
        <Settings />
      </MUICustomIcon>
    ),
    path: '/services-catalog',
    permissions: [EPermissions.READ_SERVICE_CATALOG],
  },
  {
    text: 'Customize',
    icon: (
      <MUICustomIcon>
        <DesignServices />
      </MUICustomIcon>
    ),
    path: '/customize',
    permissions: [EPermissions.VIEW_SETTINGS],
  },
  {
    text: 'Employes',
    icon: <CustomersIcon />,
    path: '/employes',
    permissions: [EPermissions.VIEW_EMPLOYEES],
  },
  {
    text: 'Scheduling',
    icon: <EstimatesIcon />,
    path: '/scheduling',
    permissions: [EPermissions.READ_SCHEDULING],
  },
];

export const SIDEBAR_SECONDARY_ITEMS: SidebarItem[] = [];
