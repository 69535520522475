import { ReactNode, forwardRef } from 'react';
import { Typography, FormControl, Box } from '@mui/material';
import {
  StyledCheckBoxWrapper,
  StyledOptionBox,
  StyledOptionsContainer,
} from './CheckBoxInput.styles';

interface IOption {
  label: string;
  value: string;
  tooltip?: string;
  image?: JSX.Element;
  day?: string;
  slot?: string;
  disabled?: boolean;
}

export interface CheckBoxInputProps {
  name: string;
  label?: string;
  id?: string;
  error?: ReactNode;
  required?: boolean;
  options: IOption[];
  value?: string[] | string | null;
  onChange?: (value: string[] | string) => void;
  paddingX?: string | Record<string, string>;
  paddingY?: string | Record<string, string>;
  width?: string | Record<string, string>;
  height?: string | Record<string, string>;
  borderRadius?: string | Record<string, string>;
  gap?: string | Record<string, string>;
  justifyContent?: string | Record<string, string>;
  fontSize?: string;
  multi?: boolean;
}

const CheckBoxInput = (
  {
    error,
    label,
    options,
    value = [],
    required,
    onChange,
    paddingX,
    paddingY,
    width,
    height,
    gap,
    justifyContent,
    fontSize,
    borderRadius,
    multi = false,
  }: CheckBoxInputProps,
  ref: any,
) => {
  const handleChange = (newValue: string) => {
    if (multi) {
      const updatedValues = value && Array.isArray(value) ? [...value] : [];
      if (updatedValues.includes(newValue)) {
        const index = updatedValues.indexOf(newValue);
        updatedValues.splice(index, 1);
      } else {
        updatedValues.push(newValue);
      }
      if (onChange) onChange(updatedValues);
    } else {
      if (onChange) onChange(newValue);
    }
  };

  return (
    <StyledCheckBoxWrapper>
      <FormControl
        sx={{
          color: '#4F4F4F',
          fontWeight: 700,
          fontSize: '16px',
          marginBottom: '8px',
        }}
      >
        {label && (
          <Typography variant="body1" fontWeight={600} gutterBottom>
            {label}
            {required && (
              <Typography component="span" color="error" fontWeight={700}>
                *
              </Typography>
            )}
          </Typography>
        )}
        <StyledOptionsContainer gap={gap} justifyContent={justifyContent}>
          {options.map(option => (
            <StyledOptionBox
              key={option.value}
              selected={
                multi
                  ? Array.isArray(value) && value.includes(option.value)
                  : value === option.value
              }
              disabled={option.disabled || false}
              onClick={() => !option.disabled && handleChange(option.value)}
              paddingX={paddingX}
              paddingY={paddingY}
              width={width}
              height={height}
              borderRadius={borderRadius}
            >
              {option.image && option.image}
              {option.day && (
                <Typography
                  fontSize={'14px'}
                  fontWeight={400}
                  color="black.main"
                >
                  {option.day}
                </Typography>
              )}
              <Typography
                fontSize={fontSize}
                textAlign="center"
                fontWeight={
                  multi
                    ? Array.isArray(value) && value.includes(option.value)
                      ? 700
                      : 400
                    : value === option.value
                      ? 700
                      : 400
                }
              >
                {option.label}
              </Typography>
              {option.slot && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '12px',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    fontSize={'12px'}
                    fontWeight={400}
                    color="grey.200"
                  >
                    {option.slot} slots
                  </Typography>
                </Box>
              )}
            </StyledOptionBox>
          ))}
        </StyledOptionsContainer>
        {error && (
          <Typography color="error" fontSize={14} paddingTop={0.2}>
            {error}
          </Typography>
        )}
      </FormControl>
    </StyledCheckBoxWrapper>
  );
};

export default forwardRef(CheckBoxInput);
