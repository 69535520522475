import { CustomGridColDef } from '@/hooks/useTable/types';

export enum ACTION_OPTIONS {
  EDIT = 'EDIT',
  DETAIL = 'DETAIL',
  PREVIEW = 'PREVIEW',
}

// interface IGenerateColumnProps {
// onAction: (option: ACTION_OPTIONS, row: CustomerInterface) => void;
// }

export const getTableColumns = (): CustomGridColDef[] => [
  {
    field: 'estimateId',
    headerName: 'Est. #',
    // minWidth: 150,
    flex: 1,
    sortable: true,
    hide: true,
  },
  {
    field: 'customerName',
    headerName: 'Customer Name',
    minWidth: 150,
    flex: 1,
    sortable: true,
  },
  {
    field: 'options',
    headerName: 'Options',
    minWidth: 100,
    flex: 1,
    sortable: false,
  },
  {
    field: 'dateCreated',
    headerName: 'Date Created',
    type: 'date',
    minWidth: 100,
    flex: 1,
    sortable: false,
    renderCell: params => {
      const date = params.row.dateCreated;
      return date ? new Date(date).toLocaleDateString() : 'N/A';
    },
  },
  {
    field: 'scheduledFor',
    headerName: 'Scheduled for',
    type: 'date',
    minWidth: 110,
    flex: 1,
    sortable: false,
    renderCell: params => {
      const date = params.row.scheduledFor;
      return date ? new Date(date).toLocaleDateString() : 'N/A';
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    // minWidth: 150,
    flex: 1,
    sortable: false,
  },
  {
    field: 'outcome',
    headerName: 'Outcome',
    // minWidth: 150,
    flex: 1,
    sortable: false,
  },
  {
    field: 'openValue',
    headerName: 'Open Value',
    // minWidth: 150,
    flex: 1,
    sortable: false,
  },
  {
    field: 'wonValue',
    headerName: 'Won Value',
    // minWidth: 150,
    flex: 1,
    sortable: false,
  },
];
