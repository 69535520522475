import { styled } from '@mui/material/styles';
import { Box, InputBase, Typography } from '@mui/material';

export const StyledAutoCompleteContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  gap: '4px',
  height: '55px',
  marginTop: '8px',
  marginBottom: '24px',
  width: '100%',
  paddingRight: '16px',
  paddingLeft: '16px',
  maxWidth: '440px',

  [theme.breakpoints.up('sm')]: {
    paddingRight: '12px',
    paddingLeft: '12px',
  },
  [theme.breakpoints.up('md')]: {
    paddingRight: '0px',
    paddingLeft: '0px',
  },
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  minWidth: '100%',
  maxWidth: '440px',
  backgroundColor: '#FFFFFF',
  color: theme.palette.text.primary,
  fontSize: '1rem',
  fontWeight: theme.typography.fontWeightRegular,
  lineHeight: '1.5',
  borderRadius: '4px',
  padding: '12px 12px 12px 40px',
  height: '55px',
  border: `2px solid ${theme.palette.grey[50]}`,
  '&::placeholder': {
    color: theme.palette.text.disabled,
  },
  '&:hover': {
    borderColor: theme.palette.secondary.main,
  },
  '&:focus-within': {
    borderColor: theme.palette.secondary.main,
    boxShadow: `0 0 0 5px rgba(39, 150, 96, 0.1)`,
  },
}));

export const ErrorText = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightLight,
  paddingLeft: '8px',
  color: theme.palette.error.main,
  fontSize: '0.75rem',
}));
