export const customEventStyles = {
  eventContainer: (eventBorder: string, eventColor: string) => ({
    border: `1px solid ${eventBorder}`,
    backgroundColor: eventColor,
    padding: '12px 8px',
    borderRadius: '12px',
    height: '100%',
    width:'100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  }),
  eventDetails: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: '4px',
  },
  eventTitle: {
    fontWeight: 500,
    fontSize: '14px',
    color: '#000000',
  },
  eventTime: {
    fontSize: '12px',
    fontWeight: 400,
    color: '#828282',
  },
  attendeeContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    rowGap: '2px',
    position: 'relative',
    marginLeft: '5px',
  },
  attendeeImageWrapper: (index: number) => ({
    width: '25px',
    height: '25px',
    borderRadius: '50%',
    padding: '1px',
    backgroundColor: 'white',
    position: 'relative',
    zIndex: index,
    marginLeft: '-5px',
  }),
  attendeeImage: {
    width: '100%',
    height: '100%',
    borderRadius: '50%',
  },
};
