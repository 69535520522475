import { useState, FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Toolbar, IconButton, useMediaQuery, useTheme } from '@mui/material';

import {
  SearchContainer,
  StyledAppBar,
  StyledBox,
  StyledCloseButton,
  StyledFlexGrow,
  StyledIconButton,
  StyledSearchBox,
  Title,
} from './TopBar.styles';
import MenuList from '../MenuList';

import { SearchBar } from '@/components';
import {
  CloseIcon,
  HamburgerIcon,
  LeftArrowIcon,
  SearchIcon,
} from '@/assets/icons';

interface AppBarProps {
  open: boolean;
  handleDrawerToggle: () => void;
}

const TopBar: FC<AppBarProps> = ({ open, handleDrawerToggle }) => {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));
  const [searchOpen, setSearchOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleSearchClick = () => {
    setSearchOpen(true);
  };
  const handleCloseSearch = () => {
    setSearchOpen(false);
  };

  const getPageTitle = () => {
    switch (location.pathname) {
      case '/customers':
        return 'Customers';
      case '/calendar':
        return 'Calendar';
      case '/jobs':
        return 'Jobs';
      case '/estimates':
        return 'Estimates';
      case '/services-catalog':
        return 'Services Catalog';
      case '/customize':
        return 'Customize';
      case '/employes':
        return 'Employes';
      case '/scheduling':
        return 'Scheduling';

      default:
        return 'Dashboard';
    }
  };

  const isCustomerDetailsPage = /^\/customers\/[a-zA-Z0-9-]+$/.test(
    location.pathname,
  );
  return (
    <StyledAppBar position="fixed" open={!isMedium && open} theme={undefined}>
      <Toolbar>
        {isMedium && !searchOpen && (
          <StyledIconButton
            color="inherit"
            edge="start"
            onClick={handleDrawerToggle}
          >
            <HamburgerIcon />
          </StyledIconButton>
        )}

        {isCustomerDetailsPage ? (
          <IconButton onClick={() => navigate('/customers')}>
            <LeftArrowIcon />
          </IconButton>
        ) : (
          <Title>{getPageTitle()}</Title>
        )}

        <StyledFlexGrow />

        {!isMedium && (
          <StyledBox>
            <SearchBar />
            <MenuList />
          </StyledBox>
        )}

        {isMedium && !searchOpen && (
          <>
            <IconButton color="inherit" onClick={handleSearchClick}>
              <SearchIcon />
            </IconButton>
            <MenuList />
          </>
        )}

        {searchOpen && (
          <SearchContainer open={searchOpen} theme={undefined}>
            <StyledSearchBox>
              <SearchBar />
              <StyledCloseButton color="inherit" onClick={handleCloseSearch}>
                <CloseIcon />
              </StyledCloseButton>
            </StyledSearchBox>
          </SearchContainer>
        )}
      </Toolbar>
    </StyledAppBar>
  );
};

export default TopBar;
