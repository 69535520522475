import styled from '@emotion/styled';
import { TableCell, TableHead, TableRow } from '@mui/material';

export const StyledTableHead = styled(TableHead)({
  borderRadius: '25px',
  backgroundColor: '#f5f5f5',
  color: '#6d6d6d',
  textAlign: 'left',
  fontWeight: 'bold',
  width: '100%',
});
export const StyledTableRow = styled(TableRow)({
  borderRadius: '20px',
  backgroundColor: '#EDEDED',
  height: '68px',
});
export const StyledTableCell = styled(TableCell)({
  padding: '12px 8px',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  color: '#828282',
  fontSize: '14px',
  fontWeight: 700,
  lineHeight: '19.6px',
});
