import { FC, useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import {
  Calendar as ReactBigCalendar,
  Views,
  momentLocalizer,
} from 'react-big-calendar';
import { Box, CircularProgress } from '@mui/material';

import { CustomHeader, CustomEvent, CustomToolbar } from './parts';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './CustomCalendar.style.css';
import { calendarContainer, calendarLoading } from './CustomCalendar.style'
import { CustomCalendarProps } from '@/utils';

const localizer = momentLocalizer(moment);

const CustomCalendar: FC<CustomCalendarProps> = ({ events, loading }) => {
  const [view, setView] = useState(Views.WEEK);
  const { defaultDate, formats } = useMemo(
    () => ({
      defaultDate: new Date(2024, 8, 1),
      formats: {
        dayFormat: (date: any, culture: any, localizer: any) =>
          localizer.format(date, 'ddd', culture) +
          '-' +
          localizer.format(date, 'DD', culture),
        weekdayFormat: (date: any, culture: any, localizer: any) =>
          localizer.format(date, 'ddd', culture),
        timeGutterFormat: (date: any, culture: any, localizer: any) =>
          localizer.format(date, 'h a', culture),
      },
    }),
    [],
  );
  const handleViewChange = useCallback((newView: any) => {
    setView(newView);
  }, []);

  return (
    <Box sx={calendarContainer}>
      <ReactBigCalendar
        popup
        selectable
        events={events}
        defaultView={Views.WEEK}
        defaultDate={defaultDate}
        dayLayoutAlgorithm="no-overlap"
        formats={formats}
        view={view}
        onView={handleViewChange}
        eventPropGetter={() => {
          return {
            style: {
              marginLeft: '5px',
            },
          };
        }}
        components={{
          header: CustomHeader,
          toolbar: CustomToolbar,
          event: ({ event }) => <CustomEvent event={event} view={view} />,
        }}
        localizer={localizer}
      />
         {loading && (
        <Box sx={calendarLoading}>
          <CircularProgress color='secondary'/>
        </Box>
      )}
    </Box>
  );
};

export default CustomCalendar;
