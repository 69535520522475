import { Theme, CSSObject } from '@mui/material/styles';

export const toolbarContainer = {
  zIndex: '101',
  display: 'flex',
  flexDirection: { xs: 'column', sm: 'row' },
  justifyContent: 'space-between',
  alignItems: { xs: 'start', sm: 'center' },
  gap: { xs: '10px', sm: '4px' },
  height: { sm: '70px' },
  padding: { xs: '10px', md: '10px 20px' },
  backgroundColor: '#ffffff',
  borderBottom: '1px solid #ddd',
};

export const navigationContainer = {
  display: 'flex',
  alignItems: 'center',
  gap: { md: '24px', xs: '12px' },
};

export const navigation = (minWidth?: object) => ({
  minWidth: minWidth,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const navMenuIcon = (theme: Theme): CSSObject => ({
  width: '36px',
  height: '36px',
  border: `1px solid ${theme.palette.grey['50']}`,
  borderRadius: '4px',
  '&:hover': {
    backgroundColor: theme.palette.grey['50'],
  },
});

export const navButton = (theme: Theme): CSSObject => ({
  width: '36px',
  height: '36px',
  border: `1px solid ${theme.palette.grey['50']}`,
  borderRadius: '4px',
  '&:hover': {
    backgroundColor: theme.palette.grey['50'],
  },
});

export const labelStyle = {
  fontSize: '16px',
  fontWeight: 500,
};

export const viewSelector = {
  display: 'flex',
  gap: { md: '16px', xs: '0px' },
  alignItems: 'center',
  marginLeft: { xs: '155px', md: 0 },
};

export const viewButton = (theme: Theme): CSSObject => ({
  border: `1px solid ${theme.palette.success.main}`,
  color: theme.palette.success.main,
  padding: '6px 12px',
  borderRadius: '32px',
  fontSize: '14px',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  height: '36px',
  textTransform: 'capitalize',
  [theme.breakpoints.up('md')]: {
    height: '48px',
    padding: '12px 24px',
  },
});
