import { FC } from 'react';
import { Box } from '@mui/material';
import { EmployeesListing } from './parts';

const Employees: FC = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '48px',
        padding: '30px',
        height: '100%',
      }}
    >
      <EmployeesListing rows={[]} searchText="" />
    </Box>
  );
};

export default Employees;
