import { styled, Box, Typography } from '@mui/material';

export const StyledChip = styled(Box)({
  height: '64px',
  width: '156px',
  paddingTop: '8px',
  paddingBottom: '8px',
  paddingLeft: '12px',
  marginTop: '8px',
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  background: '#F8F8F8',
  borderRadius: '8px',
});

export const StyledHeading = styled(Box)({
  display: 'flex',
  gap: '10px',
});

export const StyledIndicator = styled(Box)({
  width: '16px',
  borderRadius: '32px',
  height: '7px',
  marginTop: '6px',
});
export const StyledPrice = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 500,
  paddingLeft: '28px',
  color: theme.palette.primary.main,
}));
