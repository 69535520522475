import { FC } from 'react';

interface IconProps {
  isActive?: boolean;
}

const InvoicesIcon: FC<IconProps> = ({ isActive = false }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="2 2 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 22V3H21V22L18 20L15 22L12 20L9 22L6 20L3 22ZM17 9V7H15V9H17ZM13 9V7H7V9H13ZM13 11H7V13H13V11ZM15 13H17V11H15V13Z"
        fill={isActive ? '#279660' : '#828F87'}
      />
    </svg>
  );
};

export default InvoicesIcon;
