import { FC } from 'react';

import {
  StyledActionsContainer,
  StyledButtonGroup,
  StyledInnerButtonGroup,
  StyledMainContainer,
  StyledTableContainer,
  StyledTypography,
} from './CategoryListing.styles';
import './CustomStyle.css';
import { getTableColumns } from './tableColumns';

import { IUseTableActionTypes, useTableHook } from '@/hooks/useTable';

import useToggle from '@/hooks/useToggle';
import { PrimaryButton, SearchBar } from '@/components/common';
import CreateCategory from '../CreateCategory';
import { CategoryInterface } from '@plenumpro/shared';
import { useFetchAllCategories } from '@/services';

interface ICategoryListingProps {
  rows: CategoryInterface[];
  searchText: string;
}

const CategoryListing: FC<ICategoryListingProps> = ({ rows, searchText }) => {
  const createCategoryToggle = useToggle();
  const { data: categories, isLoading } = useFetchAllCategories();
  const categoriesData: CategoryInterface[] = categories || [];

  const columns = getTableColumns();

  const { state, tableProps, CustomTable, dispatch } = useTableHook<any>({
    initialState: {
      itemsPerPage: 50,
    },
  });

  const handleSearch = () => {
    const trimmedText = searchText.trim();

    dispatch({
      type: trimmedText
        ? IUseTableActionTypes.SET_FILTER
        : IUseTableActionTypes.REMOVE_FILTER,
      payload: {
        name: trimmedText || undefined,
      },
    });
  };

  // console.log({ state });

  const categoryCountLabel =
    categoriesData.length === 0
      ? '0 category'
      : `${categoriesData.length} categor${categoriesData.length > 1 ? 'ies' : 'y'}`;

  return (
    <StyledMainContainer>
      <StyledActionsContainer>
        <StyledTypography>{categoryCountLabel}</StyledTypography>
        <StyledButtonGroup>
          <SearchBar
            placeholder="Search Categories"
            onChange={e => handleSearch()}
          />
          <StyledInnerButtonGroup>
            <PrimaryButton onClick={createCategoryToggle.setToOpen}>
              Create Category
            </PrimaryButton>
          </StyledInnerButtonGroup>
        </StyledButtonGroup>
      </StyledActionsContainer>
      <StyledTableContainer>
        <CustomTable
          {...tableProps}
          rows={categoriesData}
          columns={columns}
          loading={isLoading}
          rowsPerPageOptions={[10, 15, 20, 50, 100]}
        />
      </StyledTableContainer>
      <CreateCategory
        open={createCategoryToggle.isOpen}
        onClose={createCategoryToggle.setToClose}
      />
    </StyledMainContainer>
  );
};

export default CategoryListing;
