import { FC } from 'react';

import {
  StyldDetailsWrapper,
  StyledGrid,
} from './parts/CustomerProfile.styles';
import DetailsCard from './parts/DetailsCard';
import ProfileHeader from './parts/DetailsHeader';
import {
  AddIcon,
  EditIcon,
  HomeIcon,
  MenuIcon,
  PhoneIcon,
} from '@/assets/icons';
import { Box } from '@mui/material';
import InvoiceTable from './parts/InvoicesTable';
import EstimateTable from './parts/EstimateTable';

interface customer {
  customerData: any;
}

const CustomerDetail: FC<customer> = ({ customerData }) => {
  const name = customerData?.firstName + ' ' + customerData?.lastName;

  return (
    <StyldDetailsWrapper>
      <ProfileHeader CustomerName={name} />
      <StyledGrid>
        <DetailsCard title="Summary" data={customerData} />
        <DetailsCard
          title="Contact Info"
          data={customerData}
          titleActionIcon={<EditIcon />}
          valueIcon={[<PhoneIcon />, <HomeIcon />]}
        />

        <DetailsCard
          title="Address"
          data={customerData}
          titleActionIcon={<AddIcon color="#828282" />}
          subTitleIcon={<MenuIcon />}
        />
        <DetailsCard
          title="Payment method"
          data={customerData}
          subTitleIcon={<MenuIcon />}
        />
        <DetailsCard title="Communication preferences" data={customerData} />
      </StyledGrid>
      <Box
        sx={{
          display: 'flex',
          gap: '24px',
          flexDirection: { sm: 'column', md: 'row' },
        }}
      >
        <EstimateTable />
        <InvoiceTable />
      </Box>
    </StyldDetailsWrapper>
  );
};

export default CustomerDetail;
