import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { SearchIcon } from '@/assets/icons';
import {
  SearchContainer,
  StyledInputBase,
  SearchIconWrapper,
} from './Searchbar.styles';

interface SearchBarProps {
  placeholder?: string;
  sx?: React.CSSProperties;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSearch?: () => void;
}

const SearchBar: React.FC<SearchBarProps> = ({
  placeholder = 'Search anything in Plenum',
  sx,
  onChange,
  value,
}) => {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <SearchContainer style={sx}>
      <StyledInputBase
        placeholder={isMedium ? 'Search' : placeholder}
        inputProps={{ 'aria-label': 'search' }}
        onChange={onChange}
        value={value}
      />
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
    </SearchContainer>
  );
};

export default SearchBar;
