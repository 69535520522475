import { request } from '@/utils';
import { CreateCompanyInterface } from '@plenumpro/shared';

const base = '/companies';

export const createCompanyApi = (data: CreateCompanyInterface) =>
  request({ url: base, method: 'post', data });

export const updateCompanySettingsApi = (data: any) => {
  const { settingId, settings } = data;
  return request({
    url: `${base}/setting/${settingId}`,
    method: 'put',
    data: settings,
  });
};

export const updateCompanyProfileApi = (data: any) => {
  const { companyProfileId, profile } = data;
  console.log({ first: companyProfileId, second: profile });
  return request({
    url: `${base}/profile/${companyProfileId}`,
    method: 'put',
    data: profile,
  });
};

export const getCompanySettingsApi = () =>
  request({ url: `${base}/setting`, method: 'get' });

export const getCompanyProfileApi = () =>
  request({ url: `${base}`, method: 'get' });
