import { useState, useCallback, FC, memo, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import { TableRow, TableCell } from '@mui/material';
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@mui/icons-material';
import { CustomGridColDef, CustomRenderCellParams, DataRow } from '../../types';
import {
  StyledCheckBox,
  StyledDetailPanelContent,
  StyledIconButton,
  StyledTableCell,
  StyledTableRow,
} from './TableRow.styles';

interface TableRowComponentProps {
  row: DataRow;
  columns: CustomGridColDef[];
  checkboxSelection?: boolean;
  isSelected?: boolean;
  onCheckboxChange?: (id: number | string) => void;
  onDetailPanelContent?: (row: any) => ReactNode;
  detailPanelMaxHeight?: () => number;
}

const TableRowComponent: FC<TableRowComponentProps> = ({
  row,
  columns,
  checkboxSelection = false,
  isSelected = false,
  onCheckboxChange = () => {},
  onDetailPanelContent,
  detailPanelMaxHeight,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleExpandClick = useCallback(
    () => setOpen((prev: boolean) => !prev),
    [],
  );

  const handleCheckboxChange = useCallback(() => {
    onCheckboxChange(row.id);
  }, [onCheckboxChange, row.id]);

  const handleLinkClick = useCallback(
    (link: any) => {
      if (link) {
        navigate(link);
      }
    },
    [navigate],
  );

  return (
    <>
      <StyledTableRow expanded={open}>
        {checkboxSelection && (
          <StyledTableCell padding="checkbox">
            <StyledCheckBox
              checked={isSelected}
              onChange={handleCheckboxChange}
              expanded={open}
            />
          </StyledTableCell>
        )}

        {columns.map(col => {
          const cellValue = col.valueGetter
            ? col.valueGetter({ row })
            : row[col.field];

          const formattedValue = col.valueFormatter
            ? col.valueFormatter({ value: cellValue, row })
            : cellValue;

          const cellClassName =
            typeof col.cellClassName === 'function'
              ? col.cellClassName({
                  row,
                  id: row.id,
                  field: col.field,
                  value: formattedValue,
                })
              : col.cellClassName;

          return (
            <StyledTableCell
              expanded={open}
              key={col.field}
              align={col.align || 'left'}
              className={cellClassName}
              colSpan={col.colSpan || 1}
              sx={{
                borderBottom: 'none',
                cursor: col.link ? 'pointer' : 'default',
              }}
              onClick={() =>
                col.link &&
                handleLinkClick(
                  col.link({
                    row,
                    id: row.id,
                    field: col.field,
                    value: formattedValue,
                  }),
                )
              }
            >
              {col.renderCell
                ? col.renderCell({
                    row,
                    value: formattedValue,
                  } as CustomRenderCellParams)
                : formattedValue}
            </StyledTableCell>
          );
        })}

        {onDetailPanelContent && (
          <StyledTableCell>
            <StyledIconButton
              aria-label="expand row"
              size="small"
              onClick={handleExpandClick}
              expanded={open}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </StyledIconButton>
          </StyledTableCell>
        )}
      </StyledTableRow>

      {open && onDetailPanelContent && (
        <TableRow sx={{ borderBottom: 'none' }}>
          <TableCell
            colSpan={columns.length + (checkboxSelection ? 2 : 1)}
            sx={{ padding: 0, borderBottom: 'none' }}
          >
            <StyledDetailPanelContent
              maxHeight={detailPanelMaxHeight ? detailPanelMaxHeight() : 300}
            >
              {onDetailPanelContent(row)}
            </StyledDetailPanelContent>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default memo(TableRowComponent);
