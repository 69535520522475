import { FC, useState, ReactNode, useEffect, useMemo } from 'react';
import { UserContext } from './userContext';
import { Auth0UserInterface } from '@plenumpro/shared';
import { useGetUser } from '@/services';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { Loader } from '@/components';

interface UserProviderProps {
  children: ReactNode;
}

const initialUserData: Auth0UserInterface = {
  email: '',
  emailVerified: false,
  name: '',
  picture: '',
  sub: '',
  createdAt: '',
  nickname: '',
  role: undefined,
  tenantId: '',
  userMetadata: null,
};

const UserProvider: FC<UserProviderProps> = ({ children }) => {
  const { data, isLoading, error } = useGetUser();
  const [user, setUser] = useState<Auth0UserInterface | null>(initialUserData);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [initialLoad, setInitialLoad] = useState<boolean>(false);
  const navigate = useNavigate();

  const resetUser = () => {
    setUser(initialUserData);
    setIsAuthenticated(false);
  };

  const returnTo = window.location.pathname;

  useEffect(() => {
    if (!initialLoad) {
      localStorage.setItem('returnTo', returnTo);
      setInitialLoad(true);
    }
  }, [initialLoad, returnTo]);

  useEffect(() => {
    if (!isLoading && data) {
      setUser(data.user);
      setIsAuthenticated(data.authenticated);
    }
  }, [data, isLoading]);

  useEffect(() => {
    const redirectTo = localStorage.getItem('returnTo');
    if (isAuthenticated && redirectTo && redirectTo !== '/') {
      navigate(redirectTo);
      if (redirectTo === returnTo) localStorage.removeItem('returnTo');
    }
  }, [isAuthenticated, returnTo, navigate]);

  const contextValue = useMemo(
    () => ({
      user,
      isAuthenticated,
      setUser,
      setIsAuthenticated,
      resetUser,
    }),
    [user, isAuthenticated],
  );

  if (isLoading) return <Loader />;

  if (error) {
    resetUser();
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Typography variant="h6" color="error">
          Failed to load user data. Please try again.
        </Typography>
      </Box>
    );
  }

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};

export { UserProvider };
