import { toast } from 'material-react-toastify';
import { useMutation, useQueryClient } from 'react-query';
import { createEmployee } from './api';

export const useCreateEmployee = () => {
  const queryClient = useQueryClient();
  return useMutation(createEmployee, {
    onMutate: async newEmployee => {
      return { newEmployee };
    },
    onSuccess: employeeData => {
      const { data }: any = employeeData;
      toast.success(data);
      queryClient.invalidateQueries('professionals');
    },
    onError: (error: any) => {
      const errorMessage = error?.data?.message?.message;
      toast.error(errorMessage);
    },
  });
};
