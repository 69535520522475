import { FC, useState } from 'react';
import moment from 'moment';
import { Box, Typography, Avatar } from '@mui/material';

import { customEventStyles } from './CustomEvent.styles';
import EventTooltip from './EventTooltip';
import { IEventProps } from '@/utils';

const CustomEvent: FC<IEventProps> = ({ event, view }) => {
  const [openTooltipId, setOpenTooltipId] = useState<number | null>(null);

  const handleTooltipToggle = (id: number) => {
    setOpenTooltipId(prevId => (prevId === id ? null : id));
  };

  const isOpen = openTooltipId === event.id;
  const onToggle: () => void = () => handleTooltipToggle(event.id);

  const eventType = event.eventType;
  const eventColor =
    eventType === 'meeting'
      ? '#FEF8F8'
      : eventType === 'appointment'
        ? '#FFFCF7'
        : '#EAF4FE';
  const eventBorder =
    eventType === 'meeting'
      ? '#FDDDE0'
      : eventType === 'appointment'
        ? '#FFEED2'
        : '#C0EBFE';

  return (
    <>
      <EventTooltip
        event={event}
        setOpenTooltipId={setOpenTooltipId}
        isOpen={isOpen}
        view={view}
      >
        <Box
          sx={customEventStyles.eventContainer(eventBorder, eventColor)}
          onClick={onToggle}
          title=""
        >
          <Box sx={customEventStyles.eventDetails}>
            <Typography sx={customEventStyles.eventTitle}>
              {event.title}
            </Typography>
            {view !== 'month' && (
              <Typography sx={customEventStyles.eventTime}>
                {moment(event.start).format('h:mm a')} -{' '}
                {moment(event.end).format('h:mm a')}
              </Typography>
            )}
          </Box>

          {view !== 'month' && (
            <Box sx={customEventStyles.attendeeContainer}>
              {event?.attendees?.length
                ? event.attendees.map((attendee: any, index: number) => (
                    <Box
                      key={index}
                      sx={customEventStyles.attendeeImageWrapper(index)}
                    >
                      <Avatar
                        src={attendee.attendee}
                        alt="Attendee"
                        sx={customEventStyles.attendeeImage}
                      />
                    </Box>
                  ))
                : null}
            </Box>
          )}
        </Box>
      </EventTooltip>
    </>
  );
};

export default CustomEvent;
