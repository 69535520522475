import { CustomGridColDef } from '@/hooks/useTable/types';
import { EAuth0Roles, EProfessionals } from '@plenumpro/shared';
import { Box } from '@mui/material';
import { dateFormat, formatTime, getHumanizeEnum } from '@/utils';

export enum ACTION_OPTIONS {
  EDIT = 'EDIT',
  DETAIL = 'DETAIL',
  PREVIEW = 'PREVIEW',
}

export const getTableColumns = (): CustomGridColDef[] => [
  {
    field: EProfessionals.Name,
    minWidth: 150,
    flex: 1,
    sortable: true,
    hide: true,
    renderHeader: () => <Box sx={{ paddingLeft: '45px' }}>Name</Box>,
  },
  {
    field: EProfessionals.Email,
    headerName: 'Email',
    minWidth: 150,
    flex: 1,
    sortable: true,
  },
  {
    field: EProfessionals.WorkingHours,
    headerName: 'Working Hours',
    minWidth: 150,
    flex: 1,
  },
  {
    field: EProfessionals.DayStartTime,
    headerName: 'Day Start Time',
    minWidth: 150,
    flex: 1,
    renderCell: params => formatTime(params.row.dayStartTime),
  },
  {
    field: EProfessionals.Role,
    headerName: 'Role',
    minWidth: 150,
    flex: 1,
    sortable: false,
    renderCell: params => {
      return getHumanizeEnum(EAuth0Roles, params.row.role);
    },
  },

  {
    field: EProfessionals.CreatedAt,
    headerName: 'Registration Date',
    type: 'date',
    minWidth: 50,
    flex: 1,
    sortable: false,
    renderCell: params => {
      const date = params.row.createdAt
        ? dateFormat(params.row.createdAt)
        : 'N/A';
      return date;
    },
  },
];
