import { FC } from 'react';

import { ModalDialog } from '@/components/common';
import EmployeeForm from './EmployeeForm';

interface CreateEmployeeProps {
  open: boolean;
  onClose: () => void;
}

const CreateEmployee: FC<CreateEmployeeProps> = ({ open, onClose }) => {
  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      title="Create Employee"
      width="500px"
    >
      <EmployeeForm onClose={onClose} />
    </ModalDialog>
  );
};

export default CreateEmployee;
