import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'material-react-toastify';
import { useNavigate } from 'react-router-dom';
import {
  createCompanyApi,
  updateCompanyProfileApi,
  updateCompanySettingsApi,
} from './api';

export const useCreateCompany = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  return useMutation(createCompanyApi, {
    onSuccess: async () => {
      toast.success('Company registered successfully!');
      await queryClient.invalidateQueries('user');
      await queryClient.invalidateQueries('companies');
      navigate('/dashboard');
    },
    onError: (error: any) => {
      const errorMessage = error?.data?.message?.message;
      toast.error(errorMessage || 'Error registering company');
    },
  });
};

export const useUpdateCompanySettings = () => {
  const queryClient = useQueryClient();

  return useMutation(updateCompanySettingsApi, {
    onSuccess: (data: any) => {
      toast.success('Company settings updated!');
      queryClient.invalidateQueries('company');
    },
    onError: (error: any) => {
      const errorMessage = error?.data?.message?.message;
      toast.error(errorMessage);
      console.error('Error updating company settings:', error.message);
    },
  });
};

export const useUpdateCompanyProfile = () => {
  const queryClient = useQueryClient();

  return useMutation(updateCompanyProfileApi, {
    onSuccess: (data: any) => {
      toast.success('Company profile updated!');
      queryClient.invalidateQueries('companyProfile');
    },
    onError: (error: any) => {
      const errorMessage = error?.data?.message?.message;
      toast.error(errorMessage);
      console.error('Error updating company profile:', error.message);
    },
  });
};
