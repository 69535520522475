import {
  StyledActionsContainer,
  StyledButtonGroup,
  StyledInnerButtonGroup,
  StyledMainContainer,
  StyledTableContainer,
  StyledTypography,
} from './NewCustomersListing.styles';
import { getTableColumns } from './tableColumns';
import { useTableHook } from '@/hooks/useTable';
import useToggle from '@/hooks/useToggle';
import { PrimaryButton, SearchBar } from '@/components/common';
import { useGetCustomers } from '@/services';
import NewCreateCustomer from '../CreateCustomer';
import { ReactNode, useCallback } from 'react';
import DetailPanelContent from './DetailPanelContent';
import { Box } from '@mui/material';

const NewCustomersListing = () => {
  const createCustomerToggle = useToggle();
  const { data: newData, isLoading } = useGetCustomers();
  const data = newData?.data;
  const columns = getTableColumns();

  const coustomersCountLabel =
    data?.length && data?.length > 0
      ? `${data.length} customer${data.length > 1 ? 's' : ''}`
      : '0 customer';
  const { tableProps, CustomTable } = useTableHook<any>({
    initialState: {
      itemsPerPage: 50,
    },
  });

  const getDetailPanelContent = useCallback(
    ({ row }: { row: any }): ReactNode => {
      return <DetailPanelContent row={row} />;
    },
    [],
  );
  const getDetailPanelMaxHeight = useCallback(() => 800, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '48px',
        padding: '30px',
        height: '100%',
      }}
    >
      <StyledMainContainer>
        <StyledActionsContainer>
          <StyledTypography>{coustomersCountLabel}</StyledTypography>
          <StyledButtonGroup>
            <SearchBar placeholder="Search Customers" />
            <StyledInnerButtonGroup>
              <PrimaryButton onClick={createCustomerToggle.setToOpen}>
                Create Customers
              </PrimaryButton>
            </StyledInnerButtonGroup>
          </StyledButtonGroup>
        </StyledActionsContainer>
        <StyledTableContainer>
          <CustomTable
            {...tableProps}
            rows={data}
            columns={columns}
            loading={isLoading}
            rowsPerPageOptions={[10, 15, 20, 50, 100]}
            checkboxSelection
            onDetailPanelContent={row => getDetailPanelContent({ row })}
            detailPanelMaxHeight={getDetailPanelMaxHeight}
          />
        </StyledTableContainer>
        <NewCreateCustomer
          open={createCustomerToggle.isOpen}
          onClose={createCustomerToggle.setToClose}
        />
      </StyledMainContainer>
    </Box>
  );
};

export default NewCustomersListing;
