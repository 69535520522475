import { FC } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { ModalDialog } from '@/components/common';
import CategoryForm from './CategoryForm';

interface CreateCategoryProps {
  open: boolean;
  onClose: () => void;
}

const CreateCategory: FC<CreateCategoryProps> = ({ open, onClose }) => {
  const formMethods = useForm();
  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      title="Create Category"
      width="500px"
    >
      <FormProvider {...formMethods}>
        <CategoryForm onClose={onClose} />
      </FormProvider>
    </ModalDialog>
  );
};

export default CreateCategory;
