import { JOBS_DATA, IJobParams, IJob } from '@/utils';

const getJobs = async (params?: IJobParams) => {
  const getData = async (params?: IJobParams) => {
    let filteredJobs = JOBS_DATA;
    const filters = params?.filters || [];

    if (filters.length) {
      filteredJobs = JOBS_DATA.filter(job =>
        filters.every(filter =>
          Object.entries(filter).every(([key, value]) => {
            const jobsValue = job[key as keyof IJob];
            return (
              typeof jobsValue === 'string' &&
              jobsValue.toLowerCase().includes(value.toLowerCase())
            );
          }),
        ),
      );
    }
    if (params?.searchText) {
      const searchTextLower = params.searchText.toLowerCase();
      filteredJobs = filteredJobs.filter(job =>
        Object.values(job).some(
          value =>
            typeof value === 'string' &&
            value.toLowerCase().includes(searchTextLower),
        ),
      );
    }
    return filteredJobs;
  };
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(getData(params));
    }, 1000);
  });
};

const getJob = async (id: number) => {
  const job = JOBS_DATA.find(job => job.id === id);
  return job;
};

export { getJobs, getJob };
