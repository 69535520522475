import { CustomGridColDef } from '@/hooks/useTable/types';
import { dateFormat, getHumanizeEnum } from '@/utils';
import { ECategoryFields, ECategoryStatus } from '@plenumpro/shared';

export enum ACTION_OPTIONS {
  EDIT = 'EDIT',
  DETAIL = 'DETAIL',
  PREVIEW = 'PREVIEW',
}

// interface IGenerateColumnProps {
// onAction: (option: ACTION_OPTIONS, row: CustomerInterface) => void;
// }

export const getTableColumns = (): CustomGridColDef[] => [
  {
    field: ECategoryFields.Name,
    headerName: 'Category Name',
    minWidth: 150,
    flex: 1,
    sortable: true,
    hide: true,
    // align: 'center',
    headerAlign: 'center',
  },
  {
    field: ECategoryFields.Description,
    headerName: 'Description',
    minWidth: 170,
    flex: 1,
    sortable: true,
  },

  {
    field: ECategoryFields.Status,
    headerName: 'Status',
    minWidth: 150,
    flex: 1,
    sortable: false,
    renderCell: params => getHumanizeEnum(ECategoryStatus, params.row.status),
  },
  {
    field: ECategoryFields.CreatedAt,
    headerName: 'Registration Date',
    type: 'date',
    minWidth: 50,
    flex: 1,
    sortable: false,
    renderCell: params => {
      const date = params.row.createdAt
        ? dateFormat(params.row.createdAt)
        : 'N/A';
      return date;
    },
  },
];
