import { useEffect, useState } from 'react';

import { CustomCalendar } from '@/components';
import { getEvents } from '@/services';
import { IEvent } from '@/utils';

const CalenderPage = () => {
  const [events, setEvents] = useState<IEvent[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    getEventsData();
  }, []);

  const getEventsData = async () => {
    try {
      setLoading(true);
      const newEvents: IEvent[] = (await getEvents()) as unknown as IEvent[];
      setEvents(newEvents);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  return <CustomCalendar events={events} loading={loading} />;
};

export default CalenderPage;
