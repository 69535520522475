import { Box } from '@mui/material';

const CircularGrapgh = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: { xs: 'column', md: 'row' },
        gap: '12px',
        // minHeight: '252px',
        justifyContent: { xs: 'center', md: 'space-between' },
        alignItems: {xs:'center', md:'start'},
        paddingY: '33px',
      }}
    >
      <Box>
        <img src="CircularBar.png" width={200} alt=''/>
      </Box>
      <Box>
        <img src="BarDetail.png" width={202} alt=''/>
      </Box>
    </Box>
  );
};

export default CircularGrapgh;
