export const getHumanizeEnum = <T extends Record<string, string>>(
  enumObject: T,
  value: string,
): string => {
  const entry = Object.entries(enumObject).find(([key, val]) => val === value);
  return entry ? humanizeString(entry[0]) : 'N/A';
};

export const humanizeString = (value: string | undefined) => {
  if (value === undefined) return '';
  return value
    .replace(/-+/g, ' ')
    .toLowerCase()
    .replace(/\b\w/g, c => c.toUpperCase());
};

interface SelectInputOption {
  label: string;
  value: string;
}

export const getEnumOptions = <T extends { [key: string]: string }>(
  enumObject: T,
): SelectInputOption[] => {
  return Object.keys(enumObject)
    .filter(key => isNaN(Number(key)))
    .map(key => ({
      label: enumObject[key as keyof T],
      value: key,
    }));
};
