import { SxProps, Theme } from '@mui/material';

export const tooltipStyles: SxProps<Theme> = theme => ({
  zIndex: '100',
  maxWidth: '333px',
  maxHeight: '375px',
  background: '#ffffff',
  padding: { xs: '12px', md: '24px 16px' },
  border: `1px solid ${theme.palette.grey[50]}`,
  borderRadius: '12px',
  '& .MuiTooltip-tooltip': {
    backgroundColor: '#ffffff',
  },
  '&[data-popper-placement*="right"] .css-1lz0n30-MuiTooltip-tooltip': {
    marginLeft: '0px !important',
  },
  '&[data-popper-placement*="left"] .css-1lz0n30-MuiTooltip-tooltip': {
    marginRight: '0px !important',
  },
  '&[data-popper-placement*="top"] .css-1lz0n30-MuiTooltip-tooltip ': {
    marginBottom: '0px !important',
  },
  '&[data-popper-placement*="bottom"] .css-1lz0n30-MuiTooltip-tooltip ': {
    marginTop: '0px !important',
  },
  '&[data-popper-placement*="right"] .css-1gsjsfy-MuiTooltip-tooltip ': {
    marginLeft: '0px !important',
  },
  '&[data-popper-placement*="left"] .css-1gsjsfy-MuiTooltip-tooltip ': {
    marginRight: '0px !important',
  },
  '&[data-popper-placement*="top"] .css-1gsjsfy-MuiTooltip-tooltip ': {
    marginBottom: '0px !important',
  },
  '&[data-popper-placement*="bottom"] .css-1gsjsfy-MuiTooltip-tooltip ': {
    marginTop: '0px !important',
  },
  '.css-1lz0n30-MuiTooltip-tooltip ': {
    padding: '0px 0px !important',
  },
  '.css-1gsjsfy-MuiTooltip-tooltip': {
    padding: '0px 0px !important',
  },
});

export const modifiers = (position: string[]) => [
  {
    name: 'flip',
    options: {
      fallbackPlacements: position,
    },
  },
  {
    name: 'preventOverflow',
    options: {
      boundary: document.querySelector('.rbc-time-content'),
    },
  },
];

export const commonTypography: SxProps<Theme> = theme => ({
  color: theme.palette.grey[200],
  fontWeight: 400,
  fontSize: '14px',
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
});
export const commonTextStyle: SxProps<Theme> = theme => ({
  color: theme.palette.black.main,
  fontWeight: 600,
  fontSize: '18px',
});

export const calenderIcon = {
  margin: '0px 2px 3px',
  width: '16px',
  height: '16px',
};

export const boxContainerStyles: SxProps<Theme> = {
  background: '#ffffff',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
};

export const innerBoxStyles: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
};

export const assignedBoxStyles: SxProps<Theme> = {
  display: 'flex',
  flexWrap: 'wrap',
  rowGap: '2px',
  position: 'relative',
  marginLeft: '5px',
};

export const attendeeBoxStyles = (index: number): SxProps<Theme> => ({
  width: '25px',
  height: '25px',
  borderRadius: '50%',
  padding: '1px',
  backgroundColor: '#ffffff',
  position: 'relative',
  zIndex: index,
  marginLeft: '-5px',
});

export const avatarInnerBox = {
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
};
export const avatarImage = {
  width: '100%',
  height: '100%',
  borderRadius: '50%',
};

export const avatarName: SxProps<Theme> = theme => ({
  color: theme.palette.black.main,
  fontWeight: 400,
  fontSize: '14px',
});
