import { TagContainerStyled, TagTextStyled } from './Chip.styles';

interface IChip {
  color: string;
  backgroundColor: string;
  text: string;
}

const Chip: React.FC<IChip> = ({ color, backgroundColor, text }) => {
  return (
    <TagContainerStyled>
      <TagTextStyled color={color} backgroundColor={backgroundColor}>
        {text}
      </TagTextStyled>
    </TagContainerStyled>
  );
};
export default Chip;
