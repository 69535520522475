import { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { useGetScheduling, useGetUser } from '@/services';
import {
  StyledActionsContainer,
  StyledMainContainer,
  StyledTableContainer,
  StyledTypography,
} from './SchedulingListing.styles';
import { getTableColumns } from './tableColumns';
import { useTableHook } from '@/hooks/useTable';
import { PrimaryButton } from '@/components/common';

const SchedulingListing = () => {
  const [openTooltip, setOpenTooltip] = useState(false);

  const { data, isLoading } = useGetScheduling();
  const { data: getUser } = useGetUser();

  const tenant_id = getUser?.user?.userMetadata?.tenant_id || 'N/A';
  const tenantUrl = `https://app.staging.plenumpro.com/${tenant_id}`;

  const columns = getTableColumns();
  const schedulingsData = (data || []).map((scheduling: any) => ({
    ...scheduling,
  }));

  const schedulingLabel =
    schedulingsData.length === 0
      ? '0 Schedule'
      : `${schedulingsData.length} Schedule${schedulingsData.length > 1 ? 's' : ''}`;

  const { tableProps, CustomTable } = useTableHook<any>({
    initialState: {
      itemsPerPage: 50,
    },
  });
  
  const handleCopy = () => {
    navigator.clipboard
      .writeText(tenantUrl)
      .then(() => {
        setOpenTooltip(true);
        setTimeout(() => {
          setOpenTooltip(false);
        }, 2000);
      })
      .catch(err => {
        console.error('Failed to copy tenant URL:', err);
      });
  };

  return (
    <StyledMainContainer>
      <StyledActionsContainer>
        <StyledTypography>{schedulingLabel}</StyledTypography>

        <Tooltip
          title="Copied URL!"
          open={openTooltip}
          onClose={() => setOpenTooltip(false)}
          placement="top"
        >
          <PrimaryButton onClick={handleCopy}>
            Copy Scheduling URL
          </PrimaryButton>
        </Tooltip>
      </StyledActionsContainer>

      <StyledTableContainer>
        <CustomTable
          {...tableProps}
          rows={schedulingsData}
          columns={columns}
          loading={isLoading}
          rowsPerPageOptions={[10, 15, 20, 50, 100]}
        />
      </StyledTableContainer>
    </StyledMainContainer>
  );
};

export default SchedulingListing;
