import { format, getHours } from 'date-fns';

export const formatPhoneNumber = (value: string) => {
  const cleaned = value.replace(/\D/g, '');
  let formatted = '';
  if (!cleaned.length) return '';
  formatted = `(${cleaned.substring(0, 3)}`;
  if (cleaned.length > 3) {
    formatted += `) ${cleaned.substring(3, 6)}`;
    if (cleaned.length > 6) {
      formatted += `-${cleaned.substring(6, 10)}`;
    }
  }

  return formatted;
};

export const dateFormat = (date: string) => {
  const value = format(new Date(date), 'dd MMM, yyyy');
  return value;
};

export const formatDate = (date: Date) => {
  const optionsDate: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    month: 'numeric',
    day: '2-digit',
    year: '2-digit',
  };

  const optionsTime: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  const formattedDate = date.toLocaleDateString('en-US', optionsDate);
  const formattedTime = date
    .toLocaleTimeString('en-US', optionsTime)
    .toLowerCase();

  return `${formattedDate}, ${formattedTime}`;
};

export const formatTime = (time: string) => {
  if (time) {
    const [hours, minutes] = time.split(':').map(Number);
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    return format(date, 'hh:mm a');
  } else {
    return 'N/A';
  }
};

const currentHour = getHours(new Date());
export const getGreeting = () => {
  if (currentHour >= 0 && currentHour < 5) return 'Hello, Night Owl!';
  if (currentHour >= 5 && currentHour < 12) return 'Good Morning';
  if (currentHour >= 12 && currentHour < 17) return 'Good Afternoon';
  if (currentHour >= 17 && currentHour < 21) return 'Good Evening';
  return 'Good Night';
};
