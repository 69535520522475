import { FC, memo } from 'react';
import { FieldPath, FieldValues, useController } from 'react-hook-form';
import { SelectInput, SelectInputProps } from './SelectInput';

export interface SelectHookInputProps<T extends FieldValues>
  extends Omit<SelectInputProps, 'onChange' | 'value'> {
  name: FieldPath<T>;
  onChangeSideEffect?: (val: any) => void;
}

const SelectHookInput = <T extends FieldValues = FieldValues>({
  name,
  onChangeSideEffect,
  ...props
}: SelectHookInputProps<T>): ReturnType<FC> => {
  const { field, fieldState } = useController({ name });
  const errorMessage = fieldState.error?.message as string;

  const handleChange = (val: unknown) => {
    field.onChange(val);
    onChangeSideEffect && onChangeSideEffect(val);
  };

  return (
    <SelectInput
      id={name}
      errorMessage={errorMessage}
      {...props}
      onChange={handleChange}
      value={field.value}
    />
  );
};

export default memo(SelectHookInput) as typeof SelectHookInput;
