import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Box,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';

import {
  CheckBoxInputHook,
  PlaceAutoComplete,
  SecondaryButton,
} from '@/components';
import {
  AddressIcon,
  BoxedLeftArrowIcon,
  BoxedRightArrowIcon,
} from '@/assets/icons';
import { ButtonsContainer } from '../CreateCustomer/CreateCustomer.styles';
import { useFetchSlotsAvailability } from '@/services';
import { UserContext } from '@/context';

export interface IOption {
  label: string;
  value: string;
  status?: boolean;
  image?: JSX.Element;
}

interface ScheduleAppointmentFormProps {
  onContinue: () => void;
  onBack: () => void;
  onScheduleAppointment: (data: any) => void;
}

const ScheduleAppointmentForm: FC<ScheduleAppointmentFormProps> = ({
  onContinue,
  onBack,
  onScheduleAppointment,
}) => {
  const [currentWeek, setCurrentWeek] = useState<Date[]>([]);
  const [currentMonth, setCurrentMonth] = useState<string>('');
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [availableSlots, setAvailableSlots] = useState<IOption[]>([]);
  const [firstDateOfCurrentWeek, setfirstDateOfCurrentWeek] = useState(
    new Date().toISOString().split('T')[0],
  );
  const { user } = useContext(UserContext);
  const tenantId = user?.tenantId ?? '';

  const formMethods = useForm({
    // resolver: zodResolver(ServiceScheduleSchema),
    // defaultValues: {
    //   dateoptions: form?.date || '',
    //   timeoptions: form?.time || '',
    // },
  });
  const handleContinueToCreateCustomer = formMethods.handleSubmit(
    (data: any) => {
      onContinue();
      console.log('AppointmentForm:', data);

      const selectedDate = new Date(data.dateoptions);
      const selectedTime = data.timeoptions;
      if (selectedDate && selectedTime) {
        console.log({ selectedDate, selectedTime });
        //   saveFormData({ date: selectedDate.toISOString(), time: selectedTime });
        //   navigate('/services/confirmation');
      }
    },
  );

  const handleSelectAppointmentLater = () => {
    onBack();
  };

  const onSubmit = (data: any) => {
    onScheduleAppointment(data);
  };

  const isSmall = useMediaQuery('(max-width: 400px)');

  const getCurrentMonthYear = useCallback(
    (startDate: Date, endDate: Date) => {
      const startMonth = startDate.toLocaleString('default', {
        month: isSmall ? 'short' : 'long',
      });
      const endMonth = endDate.toLocaleString('default', {
        month: isSmall ? 'short' : 'long',
      });
      setfirstDateOfCurrentWeek(startDate.toISOString().split('T')[0]);
      const endYear = endDate.getFullYear();
      if (startMonth !== endMonth) {
        return `${startMonth} - ${endMonth} ${endYear}`;
      } else {
        return `${startMonth} ${endYear}`;
      }
    },
    [isSmall],
  );

  const generateAvailableDates = (startDate: Date): Date[] => {
    const dates: Date[] = [];
    let date = new Date(startDate);
    date.setDate(
      date.getDate() - (date.getDay() === 0 ? 6 : date.getDay() - 1),
    );
    for (let i = 0; i < 7; i++) {
      dates.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return dates;
  };
  const handleNextWeek = () => {
    const nextDate = new Date(currentDate);
    nextDate.setDate(nextDate.getDate() + 7);
    setCurrentDate(nextDate);
  };

  const handlePreviousWeek = () => {
    const previousDate = new Date(currentDate);
    previousDate.setDate(previousDate.getDate() - 7);
    setCurrentDate(previousDate);
  };
  useEffect(() => {
    const newDates = generateAvailableDates(currentDate);
    setCurrentWeek(newDates);
    setCurrentMonth(getCurrentMonthYear(newDates[0], newDates[6]));
  }, [currentDate, getCurrentMonthYear]);

  const { data } = useFetchSlotsAvailability({
    tenantId,
    date: firstDateOfCurrentWeek,
  });

  const selectedDate = formMethods.watch('dateoptions');
  const selectedDateISO = selectedDate
    ? new Date(selectedDate).toISOString().split('T')[0]
    : '';

  useEffect(() => {
    if (data && selectedDateISO) {
      const filteredSlots = data?.data.find((slot: any) =>
        slot.date.startsWith(selectedDateISO),
      )?.slots;

      const slotOptions: IOption[] = (filteredSlots || []).map(
        (slot: string) => ({
          label: slot,
          value: slot,
        }),
      );

      if (JSON.stringify(slotOptions) !== JSON.stringify(availableSlots)) {
        setAvailableSlots(slotOptions);
      }
    }
  }, [selectedDateISO, data]);

  const options = currentWeek.map(date => {
    const isPast = date.getTime() < new Date().setHours(0, 0, 0, 0);
    const dayData = data?.data.find((day: any) => {
      const apiDate = new Date(day.date);
      return (
        apiDate.toISOString().split('T')[0] === date.toISOString().split('T')[0]
      );
    });
    return {
      label: date.getDate().toString(),
      value: date.toISOString(),
      day: date.toLocaleString('default', { weekday: 'short' }),
      slot: dayData ? dayData.slotsCount : '0',
      disabled: isPast || !dayData,
    };
  });

  useEffect(() => {
    const newDates = generateAvailableDates(currentDate);
    setCurrentWeek(newDates);
    setCurrentMonth(getCurrentMonthYear(newDates[0], newDates[6]));

    // Automatically go to the next week if today is Sunday and disabled
    const today = new Date();
    const lastDayOfWeek = newDates[newDates.length - 1];

    if (
      today.getDay() === 0 && // Today is Sunday
      lastDayOfWeek.toISOString().split('T')[0] ===
        today.toISOString().split('T')[0] && // Last day of week is today
      options.find(
        option =>
          option.value === lastDayOfWeek.toISOString() && option.disabled,
      ) // Today (Sunday) is disabled
    ) {
      handleNextWeek();
    }
  }, [currentDate, getCurrentMonthYear, options]);

  return (
    <>
      <FormProvider {...formMethods}>
        <PlaceAutoComplete
          label="Address"
          name="address"
          icon={<AddressIcon />}
          onChange={() => {}}
        />
        <Box
          sx={{
            maxWidth: '445px',
            marginTop: '24px',
          }}
        >
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              marginBottom="24px"
              paddingRight={{ xs: '12px', md: '0px' }}
              paddingLeft={{ xs: '12px', md: '0px' }}
            >
              <Grid item>
                <IconButton
                  onClick={handlePreviousWeek}
                  disabled={currentWeek[0] <= new Date()}
                >
                  <BoxedLeftArrowIcon />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography variant="h6">{currentMonth}</Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={handleNextWeek}>
                  <BoxedRightArrowIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '24px',
                paddingLeft: { xs: '16px', md: '0px' },
                paddingRight: { xs: '16px', md: '0px' },
              }}
            >
              <CheckBoxInputHook
                name="dateoptions"
                options={options}
                width={{ xs: '61px', md: '75px', lg: '83px' }}
                height={'106px'}
                gap={{ xs: '7px', lg: '11px' }}
                paddingX={{ xs: '0px' }}
              />

              <CheckBoxInputHook
                name="timeoptions"
                options={availableSlots || []}
                width={{ xs: '100%', sm: '190px', md: '120px', lg: '190px' }}
                gap={'10px'}
                paddingX={{ xs: '10px' }}
                paddingY={{ xs: '14px' }}
                justifyContent={'start'}
                fontSize="14px"
              />
            </Box>
          </form>
        </Box>

        <ButtonsContainer>
          <SecondaryButton
            variant="outlined"
            onClick={handleSelectAppointmentLater}
            sx={{ marginBottom: { xs: '12px', sm: 0 } }}
          >
            Select appointment later
          </SecondaryButton>
          <SecondaryButton
            variant="contained"
            onClick={handleContinueToCreateCustomer}
          >
            Continue to create customer
          </SecondaryButton>
        </ButtonsContainer>
      </FormProvider>
    </>
  );
};

export default ScheduleAppointmentForm;
