import { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';
import TopBar from './TopBar';
import Sidebar from './Sidebar';
import { StyledContainer, StyledMain } from './Layout.styles';

const Layout = () => {
  const theme = useTheme();

  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = useState(true);

  useEffect(() => {
    setOpen(!isMediumScreen);
  }, [isMediumScreen]);

  const handleDrawerToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  return (
    <StyledContainer>
      <TopBar open={open} handleDrawerToggle={handleDrawerToggle} />
      <Sidebar open={open} handleDrawerToggle={handleDrawerToggle} />
      <StyledMain open={open}>
        <Outlet />
      </StyledMain>
    </StyledContainer>
  );
};

export default Layout;
