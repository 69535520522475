import {
	IsEmail,
	IsEnum,
	IsNotEmpty,
	IsNumber,
	IsString,
	Matches,
	Max,
	Min,
	MinLength,
} from 'class-validator';
import { Type } from 'class-transformer';

import { CreateUserInterface, EAuth0Roles, UserInterface } from '../auth';

export enum ProfessionalStatus {
	Active = 'ACTIVE',
	Inactive = 'INACTIVE',
}

export const EProfessionals = {
	Name: 'name',
	Email: 'email',
	WorkingHours: 'workingHours',
	DayStartTime: 'dayStartTime',
	Role: 'role',
	CreatedAt: 'createdAt',
	UpdatedAt: 'updatedAt',
};

export interface ProfessionalInterface extends UserInterface {
	id: string;
	workingHours: number;
	dayStartTime: string;
}

export interface CreateProfessionalInterface extends CreateUserInterface {
	workingHours: number;
	dayStartTime: string;
	tenantId?: string;
}

export const PROFESSIONAL_DEFAULTS_VALUES: CreateProfessionalInterface = {
	name: '',
	email: '',
	workingHours: 8,
	dayStartTime: '09:00',
	password: '',
	role: EAuth0Roles.Professional,
};

export class CreateProfessionalValidation
	implements CreateProfessionalInterface
{
	@IsString({ message: 'Name must be a string!' })
	@IsNotEmpty({ message: 'Name is required!' })
	name: string;

	@IsEmail(undefined, { message: 'Email is not valid!' })
	@IsNotEmpty({ message: 'Email is required!' })
	email: string;

	@IsNumber()
	@Min(1, { message: 'Working hours must be at least 1' })
	@Max(24, { message: 'Working hours must be less than 24' })
	@Type(() => Number)
	workingHours: number;

	@IsString({ message: 'Work Start time must be a string!' })
	@IsNotEmpty({ message: 'Work start time is required!' })
	dayStartTime: string;

	@IsString({ message: 'Invalid password format!' })
	@MinLength(8, { message: 'Password must be at least 8 characters!' })
	@IsNotEmpty({ message: 'Password is required!' })
	@Matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).+$/, {
		message: 'Please create strong password!',
	})
	password: string;

	@IsEnum(EAuth0Roles, { message: 'Role must be a valid enum value!' })
	@IsNotEmpty({ message: 'Role is required!' })
	role: EAuth0Roles;
}
