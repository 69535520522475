import { FC, useState, useCallback, useMemo } from 'react';
import { ToolbarProps } from 'react-big-calendar';
import moment from 'moment';
import {
  Box,
  Button,
  Typography,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';

import {
  toolbarContainer,
  navigation,
  navMenuIcon,
  navButton,
  labelStyle,
  viewSelector,
  viewButton,
  navigationContainer,
} from './CustomToolbar.styles';
import { SelectInput } from '@/components/common';

type ViewType = 'day' | 'week' | 'month';

const CustomToolbar: FC<ToolbarProps> = ({
  label,
  onNavigate,
  onView,
  view,
}) => {
  const [currentView, setCurrentView] = useState<ViewType>(view as ViewType);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleViewChange = useCallback(
    (view: ViewType) => {
      onView(view);
      setCurrentView(view);
    },
    [onView],
  );

  const formattedLabel = useMemo(() => {
    const [startDate] = label.split(' – ').map(date => moment(date, 'MMMM DD'));
    const endDate = startDate.clone().add(6, 'days');

    const formattedWeek = isMobile
      ? `${startDate.format('MMM DD')} - ${endDate.format('MMM DD, YY')}`
      : `${startDate.format('MMMM DD')} - ${endDate.format('MMMM DD, YYYY')}`;

    switch (view) {
      case 'month':
        return startDate.format(isMobile ? 'MMM YYYY' : 'MMMM YYYY');
      case 'week':
        return formattedWeek;
      case 'day':
        return (
          startDate.format(isMobile ? 'MMM D, YY' : 'MMM DD, YYYY') && label
        );
      default:
        return label;
    }
  }, [label, view, isMobile]);

  const minWidth =
    currentView === 'week' ? { xs: '230px', md: '360px' } : { xs: '230px' };
  const newView = [{ text: 'Day' }, { text: 'Week' }, { text: 'Month' }];

  return (
    <Box sx={toolbarContainer}>
      <Box sx={navigationContainer}>
        <IconButton sx={navMenuIcon}>
          <MenuIcon />
        </IconButton>
        <Box sx={navigation(minWidth)}>
          <IconButton
            sx={navButton}
            onClick={() => onNavigate('PREV')}
            aria-label="Previous"
          >
            <ChevronLeftIcon color="primary" />
          </IconButton>

          <Typography sx={labelStyle}>{formattedLabel}</Typography>

          <IconButton
            sx={navButton}
            onClick={() => onNavigate('NEXT')}
            aria-label="Next"
          >
            <ChevronRightIcon color="primary" />
          </IconButton>
        </Box>
      </Box>
      <Box sx={viewSelector}>
        <SelectInput
          options={newView}
          onClick={(item: any) =>
            handleViewChange(item.text.toLowerCase() as ViewType)
          }
        >
          <Button sx={viewButton}>
            {currentView}
            <ArrowDropDownIcon />
          </Button>
        </SelectInput>
        <IconButton sx={{ width: '40px' }}>
          <SettingsIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default CustomToolbar;
