import { FC, MouseEvent } from 'react';
import {
  Box,
  ModalProps,
  IconButton,
  Breakpoint,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import {
  CloseButton,
  ContentWrapper,
  StyledTitleWrapper,
  StyledTitle,
  StyledDescription,
  StyledChildren,
  StyledDialog,
} from './ModalDialog.styles';

interface ModalDialogProps extends Omit<ModalProps, 'onClose'> {
  title?: string;
  description?: string;
  size?: Breakpoint | false;
  loading?: boolean;
  onCancel?: () => void;
  onClose?: (
    event: MouseEvent<Element, MouseEvent>,
    reason: 'backdropClick' | 'escapeKeyDown',
  ) => void;
  width?: string;
}

const ModalDialog: FC<ModalDialogProps> = ({
  title,
  description,
  size = 'xs',
  loading = false,
  onCancel,
  onClose,
  children,
  width,
  ...props
}) => {
  const handleClose = (event: any) => {
    if (!loading) {
      onClose?.(event, 'backdropClick');
    }
  };

  return (
    <StyledDialog
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      onClose={handleClose}
      {...props}
      width={width}
    >
      <ContentWrapper>
        {onClose && (
          <CloseButton>
            <IconButton
              className="closeIcon"
              disabled={loading}
              onClick={handleClose}
            >
              <CloseIcon sx={{ height: '18px', width: '18px' }} />
            </IconButton>
          </CloseButton>
        )}
        <Box
          sx={{
            width: '100%',
          }}
        >
          <StyledTitleWrapper>
            {title && (
              <StyledTitle variant="h5" id="modal-title">
                {title}
              </StyledTitle>
            )}

            {description && (
              <StyledDescription variant="body1">
                {description}
              </StyledDescription>
            )}
          </StyledTitleWrapper>
          <StyledChildren className="modal-body-content">
            {children}
          </StyledChildren>
        </Box>
      </ContentWrapper>
    </StyledDialog>
  );
};

export default ModalDialog;
