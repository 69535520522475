import { styled } from '@mui/material/styles';

import {
  Box,
  AppBar as MuiAppBar,
  Toolbar as MuiToolbar,
  Button,
  IconButton,
  Typography,
} from '@mui/material';

export const StyledAppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})<{ open: boolean }>(({ theme, open }) => ({
  width: open ? `calc(100% - 240px)` : `calc(100% - 65px)`,
  marginLeft: open ? '240px' : '72px',
  transition: 'width 0.3s, margin 0.3s',
  backgroundColor: 'white',
  height: '96px',
  justifyContent: 'center',
  zIndex: theme.zIndex.drawer + 1,
  boxShadow: 'none',
  borderBottom: `1px solid ${theme.palette.grey[50]}`,

  [theme.breakpoints.down('md')]: {
    width: '100%',
    marginLeft: 0,
    height: '60px',
  },
}));

export const Toolbar = styled(MuiToolbar)({
  display: 'flex',
  justifyContent: 'space-between',
});

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: '24px',
  fontWeight: 'bold',
  flexGrow: 1,
  textAlign: 'left',
  color: 'black',

  [theme.breakpoints.down('md')]: {
    fontSize: '20px',
  },
}));

export const SearchContainer = styled(Box)(
  ({ theme, open }: { theme: any; open: boolean }) => ({
    position: 'absolute',
    top: 0,
    right: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    transition: 'transform 0.3s ease-in-out',
    transform: open ? 'translateX(0)' : 'translateX(100%)',
    zIndex: theme.zIndex.drawer + 2,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(5),
  }),
);

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  zIndex: theme.zIndex.tooltip,
  marginLeft: '0.5px',
}));

export const StyledSearchBox = styled(Box)({
  padding: '2px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '100%',
  flexGrow: 1,
  position: 'relative',
});

export const StyledCloseButton = styled(Button)({
  position: 'absolute',
  top: '50%',
  right: -30,
  transform: 'translateY(-50%)',
  padding: 0,
  minWidth: 'auto',
});
export const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '24px',
  paddingRight: '8px',

  [theme.breakpoints.down('md')]: {
    paddingRight: '0px',
  },
}));

export const StyledFlexGrow = styled(Box)({
  flexGrow: 1,
});
