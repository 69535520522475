import { request } from '@/utils';

const base = '/customers';

export const createCustomer = (data: Partial<any> | null) =>
  request({ url: base, method: 'post', data });

export const getCustomers = async (): Promise<any> =>
  request({
    url: base,
    method: 'get',
  });

export const getCustomer = async (customerId: string): Promise<any> => {
  console.log({ customerId });
  return request({
    url: `${base}/${customerId}`,
    method: 'get',
  });
};
