import { EVENTS_DATA } from '@/utils';

const getEvents = async () => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(EVENTS_DATA);
    }, 1000);
  });
};

export { getEvents };
