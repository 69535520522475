import { FC, ReactNode, cloneElement, useState, MouseEvent } from 'react';
import { Box } from '@mui/material';
import {
  StyledList,
  StyledListItem,
  StyledListItemIcon,
  StyledListItemText,
  StyledMenu,
} from './SelectInput.styles';
import { useNavigate } from 'react-router-dom';

interface IOptions {
  text: string;
  icon?: JSX.Element;
  link?: string;
}

interface SelectInputProps {
  options: IOptions[];
  onClick: (item: IOptions) => void;
  children: ReactNode;
}

const SelectInput: FC<SelectInputProps> = ({ options, onClick, children }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemClick = (item: IOptions) => {
    if (item.link) navigate(item.link);
    onClick(item);
    handleClose();
  };
  return (
    <Box>
      <Box onClick={handleClick}>{children}</Box>
      <StyledMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <StyledList>
          {options.map((item: IOptions, index) => (
            <StyledListItem
              key={index}
              isFirst={index === 0}
              isLast={index === options.length - 1}
              onClick={() => handleItemClick(item)}
            >
              <StyledListItemIcon>
                {item.icon ? (
                  cloneElement(item.icon, { color: '#BDBDBD' })
                ) : (
                  <></>
                )}
              </StyledListItemIcon>
              <StyledListItemText primary={item.text} />
            </StyledListItem>
          ))}
        </StyledList>
      </StyledMenu>
    </Box>
  );
};

export default SelectInput;
