import {
  createTheme,
  PaletteColor,
  PaletteColorOptions,
} from '@mui/material/styles';
import colors from './colors';
import typographyVariants from './typography';

declare module '@mui/material/styles' {
  interface PaletteOptions {
    tertiary?: PaletteColorOptions;
    black?: PaletteColorOptions;
    backgroundCustom?: PaletteColorOptions;
  }
  interface Palette {
    tertiary: PaletteColor;
    black: PaletteColor;
    backgroundCustom: PaletteColor;
  }
}

const customTheme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    tertiary: {
      main: colors.tertiaryMain,
      light: colors.tertiaryLight,
    },
    info: {
      main: colors.info,
    },
    success: {
      main: colors.success,
    },
    warning: {
      main: colors.warning,
    },
    error: {
      main: colors.error,
    },
    black: {
      main: colors.black1,
      light: colors.black2,
      dark: colors.black3,
    },
    grey: {
      50: colors.grey50,
      100: colors.grey100,
      200: colors.grey200,
      300: colors.grey300,
      400: colors.grey400,
      500: colors.grey500,
    },
    backgroundCustom: {
      main: colors.darkGreen,
    },
    text: {
      primary: colors.primary,
      secondary: colors.grey200,
    },
  },
  breakpoints: {
    values: {
      xs: 320,
      sm: 600,
      md: 768,
      lg: 1024,
      xl: 1440,
    },
  },
  typography: {
    fontFamily: 'Inter, "Roboto", sans-serif',
    ...typographyVariants,
  },
});

export { customTheme };
