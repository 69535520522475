import { createContext } from 'react';
import { Auth0UserInterface } from '@plenumpro/shared';

interface IUserContext {
  user: Auth0UserInterface | null;
  isAuthenticated: boolean;
  setUser: (user: Auth0UserInterface | null) => void;
  setIsAuthenticated: (isLoading: boolean) => void;
  resetUser: () => void;
}

export const UserContext = createContext<IUserContext>({
  user: null,
  isAuthenticated: false,
  setUser: () => {},
  setIsAuthenticated: () => {},
  resetUser: () => {},
});
