const HamburgerIcon = () => {
  return (
    <svg
      enableBackground="new 0 0 48 48"
      height="20px"
      id="Layer_3"
      version="1.1"
      viewBox="0 0 48 48"
      width="20px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <polygon
          fill="#241F20"
          points="28.357,20.508 19.642,20.508 6.47,20.508 0.03,20.508 0.03,27.492 4.032,27.492 19.642,27.492    28.357,27.492 41.032,27.492 47.97,27.492 47.97,20.508 44.282,20.508  "
        />
        <polygon
          fill="#241F20"
          points="44.282,6.524 28.357,6.524 19.642,6.524 6.47,6.524 0.03,6.524 0.03,13.508 4.032,13.508    19.642,13.508 28.357,13.508 41.032,13.508 47.97,13.508 47.97,6.524  "
        />
        <polygon
          fill="#241F20"
          points="28.357,34.492 19.642,34.492 6.47,34.492 0.03,34.492 0.03,41.477 4.032,41.477 19.642,41.477    28.357,41.477 41.032,41.477 47.97,41.477 47.97,34.492 44.282,34.492  "
        />
      </g>
    </svg>
  );
};

export default HamburgerIcon;
