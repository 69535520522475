import { IsString, IsOptional, IsUrl } from 'class-validator';

export interface BrandingInterface {
  id?: string;
  logoUrl?: string;
  primaryColor?: string;
  secondaryColor?: string;
  emailTemplates?: string;
  invoicesTemplates?: string;
  customerDocumentsTemplates?: string;
  companyId: string;
}
export interface CreateBrandingInterface {
  logoUrl?: string;
  primaryColor?: string;
  secondaryColor?: string;
  emailTemplates?: string;
  invoicesTemplates?: string;
  customerDocumentsTemplates?: string;
}

export class CreateBrandingValidation implements CreateBrandingInterface {
  @IsUrl({}, { message: 'Logo url must be a valid URL!' })
  @IsOptional()
  logoUrl?: string;

  @IsString({ message: 'Primary color must be a string!' })
  @IsOptional()
  primaryColor: string;

  @IsString({ message: 'Secondary color must be a string!' })
  @IsOptional()
  secondaryColor: string;

  @IsString({ message: 'Email templates must be a string!' })
  @IsOptional()
  emailTemplates?: string;

  @IsString({ message: 'Invoice templates must be a string!' })
  @IsOptional()
  invoicesTemplates?: string;

  @IsString({ message: 'Customer documents templates must be a string!' })
  @IsOptional()
  customerDocumentsTemplates?: string;
}
