import { Typography } from '@mui/material';

import {
  StyledContainer,
  StyledSection,
  StyledTitleSection,
  StyledTitle,
  StyledDetailSection,
  StyledButton,
  StyledContentWrapper,
} from './DetailPanelContent.styles';

import { AddIcon } from '@/assets/icons';

const DetailPanelContent = ({ row }: { row: any }) => {
  return (
    <StyledContainer>
      <StyledContentWrapper>
        <StyledSection>
          <StyledTitleSection>
            <StyledTitle>Estimate</StyledTitle>
            {/* <Typography variant="subtitle1" color="secondary.main">
              #{row?.estimates[0].id}
            </Typography> */}
          </StyledTitleSection>
          <StyledDetailSection>
            <Typography variant="subtitle1">
              {/* {row?.estimates[0].serviceName} */}
            </Typography>
            <Typography variant="subtitle1">
              {/* ${row?.estimates[0].amount} */}
            </Typography>
          </StyledDetailSection>

          {/* <Chip
            color="#4D7C54"
            backgroundColor="#8DCE97"
            text={row?.estimates[0].status}
          /> */}
          <StyledButton>
            <AddIcon />
            New Estimate
          </StyledButton>
        </StyledSection>

        <StyledSection>
          <StyledTitleSection>
            <StyledTitle>Job</StyledTitle>
            <Typography variant="subtitle1" color="secondary.main">
              {/* #{row?.jobs[0].id} */}
            </Typography>
          </StyledTitleSection>
          <StyledDetailSection>
            <Typography variant="subtitle1">
              {/* {formatDate(new Date(row?.jobs[0].date))} */}
            </Typography>
            {/* <Typography variant="subtitle1">${row?.jobs[0].amount}</Typography> */}
          </StyledDetailSection>
          {/* <Chip
            color="#8C6918"
            backgroundColor="#FFB50A"
            text={row?.jobs[0].status}
          /> */}
          <StyledButton>
            <AddIcon />
            New Job
          </StyledButton>
        </StyledSection>
      </StyledContentWrapper>
    </StyledContainer>
  );
};

export default DetailPanelContent;
