import { CustomGridColDef } from '@/hooks/useTable/types';
import { dateFormat, getHumanizeEnum } from '@/utils';
import { ECategoryStatus, ESubCategoryFields } from '@plenumpro/shared';

export enum ACTION_OPTIONS {
  EDIT = 'EDIT',
  DETAIL = 'DETAIL',
  PREVIEW = 'PREVIEW',
}

// interface IGenerateColumnProps {
// onAction: (option: ACTION_OPTIONS, row: CustomerInterface) => void;
// }

export const getTableColumns = (): CustomGridColDef[] => [
  {
    field: ESubCategoryFields.Categories,
    headerName: 'Category',
    minWidth: 150,
    flex: 1,
    renderCell: cell => cell.row.category?.name,
    align: 'center',
    headerAlign: 'center',
  },
  {
    field: ESubCategoryFields.Name,
    headerName: 'Sub Category',
    minWidth: 150,
    flex: 1,
  },

  {
    field: ESubCategoryFields.Description,
    headerName: 'Description',
    minWidth: 150,
    flex: 1,
    sortable: true,
  },

  {
    field: ESubCategoryFields.Status,
    headerName: 'Status',
    minWidth: 150,
    flex: 1,
    sortable: false,
    renderCell: params => getHumanizeEnum(ECategoryStatus, params.row.status),
  },
  {
    field: ESubCategoryFields.CreatedAt,
    headerName: 'Registration Date',
    type: 'date',
    minWidth: 50,
    flex: 1,
    sortable: false,
    renderCell: params => {
      const date = params.row.createdAt
        ? dateFormat(params.row.createdAt)
        : 'N/A';
      return date;
    },
  },
];
