import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';
import {
  StyledChip,
  StyledHeading,
  StyledIndicator,
  StyledPrice,
} from './InvoicesChip.styles';

interface InvoicesChipProps {
  backgroundColor: string;
  heading: string;
  price: string;
}

const InvoicesChip: FC<InvoicesChipProps> = ({
  backgroundColor,
  heading,
  price,
}) => {
  return (
    <StyledChip>
      <StyledHeading>
        <StyledIndicator
          sx={{
            backgroundColor,
          }}
        />
        <Typography variant="subtitle1" sx={{ color: 'grey.200' }}>
          {heading}
        </Typography>
      </StyledHeading>
      <StyledPrice>${price}</StyledPrice>
    </StyledChip>
  );
};

export default InvoicesChip;
