import { useParams } from 'react-router-dom';

import { useGetCustomer } from '@/services';
import { Typography } from '@mui/material';
import { CustomerDetail, Loader } from '@/components';

const CustomerDetailPage = () => {
  const { slug } = useParams();
  const { data, isLoading } = useGetCustomer(slug ?? '');
  const customer = data?.data[0];
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : customer ? (
        <CustomerDetail customerData={customer} />
      ) : (
        <Typography>Invalid Customer Id</Typography>
      )}
    </>
  );
};

export default CustomerDetailPage;
