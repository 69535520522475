import { ICustomer, IEstimate, IEvent, IJob } from './schemas';

const TIME_OPTIONS = [
  {
    label: '8 AM - 10 AM',
    value: '8 AM - 10 AM',
  },
  {
    label: '10 AM - 12 PM',
    value: '10 AM - 12 PM',
  },
  {
    label: '12 PM - 2 PM',
    value: '12 PM - 2 PM',
  },
  {
    label: '2 PM - 4 PM',
    value: '2 PM - 4 PM',
  },
  {
    label: '4 PM - 6 PM',
    value: '4 PM - 6 PM',
  },
];

const EVENTS_DATA: IEvent[] = [
  {
    id: 1,
    title: 'All Day Conference',
    phone: '123-456-7890',
    jobId: '#3455',
    service: 'Business Meeting',
    price: '$200',
    start: new Date(2024, 8, 2, 1, 0, 0),
    end: new Date(2024, 8, 2, 6, 0, 0),
    desc: 'Big conference for important people',
    eventType: 'meeting',
    attendees: [
      {
        name: 'John',
        attendee: 'john.png',
      },
    ],
    data: {
      appointment: {
        id: 1,
        status: 'P',
        location: 'New York',
        resources: 'Dr. Alex',
        address: 'Building 1 \n 123 Main Street \n New York',
      },
    },
  },
  {
    id: 2,
    title: 'Client Meeting',
    phone: '987-654-3210',
    jobId: '#3456',
    service: 'Project Discussion',
    price: '$150',
    start: new Date(2024, 8, 3, 0, 30, 0),
    end: new Date(2024, 8, 3, 2, 30, 0),
    desc: 'Discussion with client on upcoming project',
    eventType: 'appointment',
    attendees: [
      {
        name: 'Jane',
        attendee: 'jane.png',
      },
    ],
    data: {
      appointment: {
        id: 2,
        status: 'C',
        location: 'Los Angeles',
        resources: 'Mr. Smith',
        address: 'Office 7 \n 789 Elm Street \n Los Angeles, CA',
      },
    },
  },
  {
    id: 3,
    title: 'Team Standup',
    phone: '456-789-1234',
    jobId: '#3457',
    service: 'Daily Sync',
    price: '$0',
    start: new Date(2024, 8, 4, 3, 0, 0),
    end: new Date(2024, 8, 4, 7, 30, 0),
    desc: 'Daily standup meeting with the team',
    eventType: 'default',
    attendees: [
      {
        name: 'Jon Son',
        attendee: 'team.png',
      },
    ],
    data: {
      appointment: {
        id: 2,
        status: 'C',
        location: 'Los Angeles',
        resources: 'Mr. Smith',
        address: 'Office 7 \n 789 Elm Street \n Los Angeles, CA',
      },
    },
  },
  {
    id: 4,
    title: 'Product Launch',
    phone: '321-654-9870',
    jobId: '#3458',
    service: 'Launch Event',
    price: '$500',
    start: new Date(2024, 8, 5, 2, 0, 0),
    end: new Date(2024, 8, 5, 5, 0, 0),
    desc: 'Launching the new product line',
    eventType: 'meeting',
    attendees: [
      {
        name: 'Alex',
        attendee: 'alex.png',
      },
    ],
    data: {
      appointment: {
        id: 2,
        status: 'C',
        location: 'Los Angeles',
        resources: 'Mr. Smith',
        address: 'Office 7 \n 789 Elm Street \n Los Angeles, CA',
      },
    },
  },
  {
    id: 5,
    title: 'Annual Review',
    phone: '654-321-0987',
    jobId: '#3459',
    service: 'Performance Review',
    price: '$300',
    start: new Date(2024, 8, 6, 1, 0, 0),
    end: new Date(2024, 8, 6, 5, 0, 0),
    desc: 'Review of the annual performance of employees',
    eventType: 'appointment',
    attendees: [
      {
        name: 'Sarah',
        attendee: 'sarah.png',
      },
    ],
    data: {
      appointment: {
        id: 2,
        status: 'C',
        location: 'Los Angeles',
        resources: 'Mr. Smith',
        address: 'Office 7 \n 789 Elm Street \n Los Angeles, CA',
      },
    },
  },
];

const CUSTOMERS_DATA: ICustomer[] = [
  {
    id: 1,
    name: 'Michael Johnson',
    address: ['12 Willow St, Boston, MA'],
    phone: ['+1-617-555-1234'],
    email: 'michael.johnson@example.com',
    date: new Date('2023-08-15'),
    estimates: [
      {
        id: 1,
        serviceName: 'Home Cleaning',
        amount: 120,
        date: new Date('2023-08-01'),
        status: 'Completed',
      },
    ],
    jobs: [
      {
        id: 1,
        serviceName: 'Window Cleaning',
        amount: 150,
        date: new Date('2023-08-10'),
        status: 'In Progress',
      },
    ],
    lastInteraction: { estimateId: '1', date: new Date('2023-08-12') },
    communication: [
      { type: 'Email', value: 'Yes' },
      { type: 'SMS', value: 'No' },
    ],
    paymentMethod: [
      {
        type: 'Credit Card',
        cardNumber: '4111-1111-1111-1111',
        cardHolderName: 'Michael Johnson',
        expiryDate: '11/24',
        cvv: '123',
      },
    ],
  },
  {
    id: 2,
    name: 'Sarah Lee',
    address: ['789 Maple Ave, Dallas, TX'],
    phone: ['+1-214-555-5678', '98765567788'],
    email: 'sarah.lee@example.com',
    date: new Date('2023-07-22'),
    estimates: [
      {
        id: 2,
        serviceName: 'AC Repair',
        amount: 300,
        date: new Date('2023-07-15'),
        status: 'Approved',
      },
    ],
    jobs: [
      {
        id: 2,
        serviceName: 'AC Installation',
        amount: 600,
        date: new Date('2023-07-20'),
        status: 'Completed',
      },
    ],
    lastInteraction: { estimateId: '2', date: new Date('2023-07-21') },
    communication: [{ type: 'Phone', value: 'Yes' }],
    paymentMethod: [
      {
        type: 'Debit Card',
        cardNumber: '5555-4444-3333-2222',
        cardHolderName: 'Sarah Lee',
        expiryDate: '10/25',
        cvv: '321',
      },
    ],
  },
  {
    id: 3,
    name: 'James Smith',
    address: ['123 Oak St, Seattle, WA'],
    phone: ['+1-206-555-7890'],
    email: 'james.smith@example.com',
    date: new Date('2023-09-01'),
    estimates: [
      {
        id: 3,
        serviceName: 'Roofing',
        amount: 1200,
        date: new Date('2023-08-25'),
        status: 'Pending',
      },
    ],
    jobs: [
      {
        id: 3,
        serviceName: 'Gutter Installation',
        amount: 400,
        date: new Date('2023-09-05'),
        status: 'Scheduled',
      },
    ],
    lastInteraction: { estimateId: '3', date: new Date('2023-08-28') },
    communication: [{ type: 'Email', value: 'No' }],
    paymentMethod: [
      {
        type: 'Credit Card',
        cardNumber: '4444-5555-6666-7777',
        cardHolderName: 'James Smith',
        expiryDate: '09/26',
        cvv: '456',
      },
    ],
  },
  {
    id: 4,
    name: 'Emma Wilson',
    address: ['456 Birch Blvd, Miami, FL'],
    phone: ['+1-305-555-6543'],
    email: 'emma.wilson@example.com',
    date: new Date('2023-08-10'),
    estimates: [
      {
        id: 4,
        serviceName: 'Plumbing',
        amount: 500,
        date: new Date('2023-08-05'),
        status: 'Completed',
      },
    ],
    jobs: [
      {
        id: 4,
        serviceName: 'Bathroom Renovation',
        amount: 1500,
        date: new Date('2023-08-12'),
        status: 'In Progress',
      },
    ],
    lastInteraction: { estimateId: '4', date: new Date('2023-08-13') },
    communication: [{ type: 'SMS', value: 'Yes' }],
    paymentMethod: [
      {
        type: 'Credit Card',
        cardNumber: '9999-8888-7777-6666',
        cardHolderName: 'Emma Wilson',
        expiryDate: '12/24',
        cvv: '789',
      },
    ],
  },
  {
    id: 5,
    name: 'Oliver Brown',
    address: ['135 Cedar Lane, San Francisco, CA'],
    phone: ['+1-415-555-4321'],
    email: 'oliver.brown@example.com',
    date: new Date('2023-07-30'),
    estimates: [
      {
        id: 5,
        serviceName: 'Painting',
        amount: 800,
        date: new Date('2023-07-20'),
        status: 'Approved',
      },
    ],
    jobs: [
      {
        id: 5,
        serviceName: 'Interior Painting',
        amount: 1000,
        date: new Date('2023-08-01'),
        status: 'Completed',
      },
    ],
    lastInteraction: { estimateId: '5', date: new Date('2023-08-02') },
    communication: [
      { type: 'Phone', value: 'No' },
      { type: 'Email', value: 'Yes' },
    ],
    paymentMethod: [
      {
        type: 'Debit Card',
        cardNumber: '3333-2222-1111-0000',
        cardHolderName: 'Oliver Brown',
        expiryDate: '06/25',
        cvv: '987',
      },
    ],
  },
];

const JOBS_DATA: IJob[] = [
  {
    id: 1,
    jobId: '1001',
    customerName: 'Michael Johnson',
    dateCreated: new Date('2023-08-10'),
    scheduledFor: new Date('2023-08-15'),
    status: 'In Progress',
    jobDescription: 'Window Cleaning for residential property.',
    amount: '$150',
    address: '12 Willow St, Boston, MA',
    dueAmount: '$50',
    employees: ['John Doe'],
  },
  {
    id: 2,
    jobId: '1002',
    customerName: 'Sarah Lee',
    dateCreated: new Date('2023-07-18'),
    scheduledFor: new Date('2023-07-20'),
    status: 'Completed',
    jobDescription: 'AC Installation for a 2-story house.',
    amount: '$600',
    address: '789 Maple Ave, Dallas, TX',
    dueAmount: '$0',
    employees: ['Tom Davis'],
  },
  {
    id: 3,
    jobId: '1003',
    customerName: 'James Smith',
    dateCreated: new Date('2023-08-25'),
    scheduledFor: new Date('2023-09-05'),
    status: 'Scheduled',
    jobDescription: 'Gutter Installation for a commercial building.',
    amount: '$400',
    address: '123 Oak St, Seattle, WA',
    dueAmount: '$200',
    employees: ['Mark Wilson'],
  },
  {
    id: 4,
    jobId: '1004',
    customerName: 'Emma Wilson',
    dateCreated: new Date('2023-08-08'),
    scheduledFor: new Date('2023-08-12'),
    status: 'In Progress',
    jobDescription: 'Bathroom Renovation with full plumbing work.',
    amount: '$1500',
    address: '456 Birch Blvd, Miami, FL',
    dueAmount: '$500',
    employees: ['Chris Johnson'],
  },
  {
    id: 5,
    jobId: '1005',
    customerName: 'Oliver Brown',
    dateCreated: new Date('2023-07-25'),
    scheduledFor: new Date('2023-08-01'),
    status: 'Completed',
    jobDescription: 'Interior Painting of a 3-bedroom apartment.',
    amount: '$1000',
    address: '135 Cedar Lane, San Francisco, CA',
    dueAmount: '$0',
    employees: ['James White'],
  },
];

const ESTIMATES_DATA: IEstimate[] = [
  {
    id: 1,
    estimateId: '1001',
    customerName: 'Michael Johnson',
    options: 4,
    dateCreated: new Date('2023-08-05'),
    scheduledFor: new Date('2023-08-10'),
    status: 'Awaiting approval',
    outcome: 'Open',
    openValue: '$200',
    wonValue: '$0',
    services: ['Window Cleaning', 'Roof Repair'],
    address: '12 Willow St, Boston, MA',
    employees: ['John Doe'],
  },
  {
    id: 2,
    estimateId: '1002',
    customerName: 'Sarah Lee',
    options: 3,
    dateCreated: new Date('2023-07-12'),
    scheduledFor: new Date('2023-07-14'),
    status: 'Awaiting approval',
    outcome: 'Open',
    openValue: '$0',
    wonValue: '$600',
    services: ['AC Installation', 'Duct Cleaning'],
    address: '789 Maple Ave, Dallas, TX',
    employees: ['Tom Davis'],
  },
  {
    id: 3,
    estimateId: '1003',
    customerName: 'James Smith',
    options: 4,
    dateCreated: new Date('2023-08-20'),
    scheduledFor: new Date('2023-09-01'),
    status: 'Completed',
    outcome: 'Open',
    openValue: '$300',
    wonValue: '$0',
    services: ['Gutter Installation', 'Window Cleaning'],
    address: '123 Oak St, Seattle, WA',
    employees: ['Mark Wilson'],
  },
  {
    id: 4,
    estimateId: '1004',
    customerName: 'Emma Wilson',
    options: 2,
    dateCreated: new Date('2023-08-03'),
    scheduledFor: new Date('2023-08-10'),
    status: 'Awaiting approval',
    outcome: 'Open',
    openValue: '$0',
    wonValue: '$0',
    services: ['Bathroom Renovation', 'Plumbing Work'],
    address: '456 Birch Blvd, Miami, FL',
    employees: ['Chris Johnson'],
  },
  {
    id: 5,
    estimateId: '1005',
    customerName: 'Oliver Brown',
    options: 4,
    dateCreated: new Date('2023-07-20'),
    scheduledFor: new Date('2023-07-25'),
    status: 'Closed',
    outcome: 'Open',
    openValue: '$0',
    wonValue: '$1200',
    services: ['Interior Painting', 'Exterior Painting'],
    address: '135 Cedar Lane, San Francisco, CA',
    employees: ['James White'],
  },
];

export { EVENTS_DATA, CUSTOMERS_DATA, TIME_OPTIONS, JOBS_DATA, ESTIMATES_DATA };
