const CloseIcon = () => {
  return (
    <svg
      enableBackground="new 0 0 512 512"
      height="30px"
      id="Layer_1"
      version="1.0"
      viewBox="0 0 512 512"
      width="30px"
    >
      <polygon points="445.2,109.2 402.8,66.8 256,213.6 109.2,66.8 66.8,109.2 213.6,256 66.8,402.8 109.2,445.2 256,298.4 402.8,445.2   445.2,402.8 298.4,256 " />
    </svg>
  );
};

export default CloseIcon;
