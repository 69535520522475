const BoxedLeftArrowIcon = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="-0.5"
        y="0.5"
        width="35"
        height="35"
        rx="3.5"
        transform="matrix(-1 0 0 1 35 0)"
        stroke="#E0E0E0"
      />
      <path
        d="M13.7246 18L21.2246 10.5L22.2746 11.55L15.8246 18L22.2746 24.45L21.2246 25.5L13.7246 18Z"
        fill="black"
      />
    </svg>
  );
};

export default BoxedLeftArrowIcon;
