const ClockIcon = () => {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 0C5.71442 0 4.45772 0.381218 3.3888 1.09545C2.31988 1.80968 1.48676 2.82484 0.994786 4.01256C0.502816 5.20028 0.374095 6.50721 0.624899 7.76809C0.875703 9.02896 1.49477 10.1872 2.40381 11.0962C3.31285 12.0052 4.47104 12.6243 5.73192 12.8751C6.99279 13.1259 8.29973 12.9972 9.48744 12.5052C10.6752 12.0132 11.6903 11.1801 12.4046 10.1112C13.1188 9.04229 13.5 7.78558 13.5 6.5C13.4982 4.77665 12.8128 3.12441 11.5942 1.90582C10.3756 0.687224 8.72335 0.00181989 7 0ZM10.5 7H7C6.86739 7 6.74022 6.94732 6.64645 6.85355C6.55268 6.75979 6.5 6.63261 6.5 6.5V3C6.5 2.86739 6.55268 2.74021 6.64645 2.64645C6.74022 2.55268 6.86739 2.5 7 2.5C7.13261 2.5 7.25979 2.55268 7.35356 2.64645C7.44732 2.74021 7.5 2.86739 7.5 3V6H10.5C10.6326 6 10.7598 6.05268 10.8536 6.14645C10.9473 6.24021 11 6.36739 11 6.5C11 6.63261 10.9473 6.75979 10.8536 6.85355C10.7598 6.94732 10.6326 7 10.5 7Z"
        fill="#BDBDBD"
      />
    </svg>
  );
};

export default ClockIcon;
