import { FC } from 'react';

interface IconProps {
  isActive?: boolean;
  style?: object;
  color?: string;
}

const CalenderIncon: FC<IconProps> = ({ isActive = false, style, color }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M0 17C0 18.7 1.3 20 3 20H17C18.7 20 20 18.7 20 17V9H0V17ZM17 2H15V1C15 0.4 14.6 0 14 0C13.4 0 13 0.4 13 1V2H7V1C7 0.4 6.6 0 6 0C5.4 0 5 0.4 5 1V2H3C1.3 2 0 3.3 0 5V7H20V5C20 3.3 18.7 2 17 2Z"
        fill={color || (isActive ? '#279660' : '#828F87')}
      />
    </svg>
  );
};

export default CalenderIncon;
