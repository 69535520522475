import { Typography } from '@mui/material';
import { FC, ReactNode } from 'react';
import {
  StyledChip,
  StyledValues,
  StyledHeading,
  StyledPrice,
  StyledIconWrapper,
} from './TopChip.styles';

interface InvoicesChipProps {
  icon: ReactNode;
  heading: string;
  price: string;
}

const InvoicesChip: FC<InvoicesChipProps> = ({ icon, heading, price }) => {
  return (
    <StyledChip>
      <StyledIconWrapper>{icon}</StyledIconWrapper>
      <StyledValues>
        <StyledHeading>{heading}</StyledHeading>
        <StyledPrice>${price}</StyledPrice>
      </StyledValues>
    </StyledChip>
  );
};

export default InvoicesChip;
