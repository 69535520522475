import { useQuery } from 'react-query';
import { getCompanySettingsApi, getCompanyProfileApi } from './api';
import { toast } from 'material-react-toastify';

export const useGetCompanySettings = () => {
  return useQuery('company', getCompanySettingsApi, {
    staleTime: 5 * 60 * 1000,
    onSuccess: (data: any) => {
      return data.data;
    },
    onError: (error: any) => {
      const errorMessage = error?.data?.message?.message;
      toast.error(errorMessage);
    },
  });
};

export const useGetCompanyPrfile = () => {
  return useQuery('companyProfile', getCompanyProfileApi, {
    staleTime: 5 * 60 * 1000,
    onSuccess: (data: any) => {
      return data.data;
    },
    onError: (error: any) => {
      const errorMessage = error?.data?.message?.message;
      toast.error(errorMessage);
    },
  });
};
