const LocationIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.50808 13.7567C5.50808 13.7567 0.666748 9.67932 0.666748 5.66732C0.666748 4.25283 1.22865 2.89628 2.22885 1.89608C3.22904 0.895888 4.58559 0.333984 6.00008 0.333984C7.41457 0.333984 8.77112 0.895888 9.77132 1.89608C10.7715 2.89628 11.3334 4.25283 11.3334 5.66732C11.3334 9.67932 6.49208 13.7567 6.49208 13.7567C6.22275 14.0047 5.77942 14.002 5.50808 13.7567ZM6.00008 8.00065C6.3065 8.00065 6.60992 7.9403 6.89301 7.82304C7.1761 7.70578 7.43333 7.5339 7.65 7.31723C7.86667 7.10056 8.03854 6.84334 8.1558 6.56025C8.27306 6.27715 8.33342 5.97374 8.33342 5.66732C8.33342 5.3609 8.27306 5.05748 8.1558 4.77439C8.03854 4.4913 7.86667 4.23407 7.65 4.0174C7.43333 3.80073 7.1761 3.62886 6.89301 3.5116C6.60992 3.39434 6.3065 3.33398 6.00008 3.33398C5.38124 3.33398 4.78775 3.57982 4.35017 4.0174C3.91258 4.45499 3.66675 5.04848 3.66675 5.66732C3.66675 6.28616 3.91258 6.87965 4.35017 7.31723C4.78775 7.75482 5.38124 8.00065 6.00008 8.00065Z"
        fill="#BDBDBD"
      />
    </svg>
  );
};

export default LocationIcon;
