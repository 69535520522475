import { Navigate } from 'react-router-dom';
import {
  CalenderPage,
  CustomerDetailPage,
  JobsListingPage,
  EstimatesListingPage,
  DashboardPage,
  ServicesCatalogPage,
  CustomizePage,
  EmployesPage,
  NewCustomersListingPage,
  SchedulingPage,
} from '@/pages';
import { Layout } from '@/components';

interface PrivateRoutesProps {
  isAuthenticated: boolean;
}
const PrivateRoutes = ({ isAuthenticated }: PrivateRoutesProps) => {
  return {
    path: '',
    element: isAuthenticated ? <Layout /> : <Navigate to="/signin" />,
    children: [
      { path: 'dashboard', element: <DashboardPage /> },
      { path: 'calendar', element: <CalenderPage /> },
      { path: 'customers', element: <NewCustomersListingPage /> },
      { path: 'jobs', element: <JobsListingPage /> },
      { path: 'estimates', element: <EstimatesListingPage /> },
      { path: 'customers/:slug', element: <CustomerDetailPage /> },
      { path: 'services-catalog', element: <ServicesCatalogPage /> },
      { path: 'customize', element: <CustomizePage /> },
      { path: 'employes', element: <EmployesPage /> },
      { path: 'scheduling', element: <SchedulingPage /> },
    ],
  };
};

export default PrivateRoutes;
