import { Box, styled } from '@mui/material';
import { Checkbox, IconButton, TableCell, TableRow } from '@mui/material';

export const StyledTableRow = styled(TableRow, {
  shouldForwardProp: prop => prop !== 'expanded',
})<{ expanded: boolean }>(({ theme, expanded = false }) => ({
  boxShadow: expanded ? 'none' : '0px 1px 2px 1px #0000001A',
  borderRadius: '4px',
  fontSize: '14px',
  backgroundColor: expanded ? theme.palette.secondary.main : '#FFFFFF',
  transition: 'margin-bottom 0.3s ease, box-shadow 0.3s ease',
  height: expanded ? 'auto' : '88px',
}));

export const StyledCheckBox = styled(Checkbox, {
  shouldForwardProp: prop => prop !== 'expanded',
})<{ expanded: boolean }>(({ theme, expanded = false }) => ({
  color: expanded ? '#fff' : theme.palette.grey[200],
  '&.Mui-checked': {
    color: expanded ? '#fff' : theme.palette.primary.main,
  },
}));

export const StyledIconButton = styled(IconButton, {
  shouldForwardProp: prop => prop !== 'expanded',
})<{ expanded: boolean }>(({ expanded = false }) => ({
  border: expanded ? '1px solid white' : '1px solid #262626',
  borderRadius: '4px',
  color: expanded ? 'white' : '#262626',
}));

export const StyledTableCell = styled(TableCell, {
  shouldForwardProp: prop => prop !== 'expanded',
})<{ expanded?: boolean }>(({ expanded = false }) => ({
  '&.MuiTableCell-root': {
    color: expanded ? 'white' : '#333',
    fontWeight: expanded ? 700 : 400,
    fontSize: 14,
    borderRadius: 0,
    transition: 'color 0.3s ease',
    borderBottom: 'none',
  },
  '&:first-of-type': {
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
  },
  '&:last-of-type': {
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
  },
}));

export const StyledDetailPanelContent = styled(Box)(({ theme }) => ({
  overflowY: 'auto',
  border: '2px solid',
  marginTop: '-16px',
  borderColor: theme.palette.secondary.main,
  borderRadius: '0 0 4px 4px',
  borderTop: 'none',
}));

export const StyledDetailPanelTableCell = styled(Box)(({ theme }) => ({
  overflowY: 'auto',
  border: '2px solid',
  transform: 'translateY(-16px)',
  borderColor: theme.palette.secondary.main,
  borderRadius: '0 0 4px 4px',
  borderTop: 'none',
}));
