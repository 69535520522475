import { ESTIMATES_DATA, IEstimate, IEstimateParams } from '@/utils';

const getEstimates = async (params?: IEstimateParams) => {
  const getData = async (params?: IEstimateParams) => {
    let filteredEstimate = ESTIMATES_DATA;
    const filters = params?.filters || [];

    if (filters.length) {
      filteredEstimate = ESTIMATES_DATA.filter(estimate =>
        filters.every(filter =>
          Object.entries(filter).every(([key, value]) => {
            const estimateValue = estimate[key as keyof IEstimate];
            return (
              typeof estimateValue === 'string' &&
              estimateValue.toLowerCase().includes(value.toLowerCase())
            );
          }),
        ),
      );
    }
    if (params?.searchText) {
      const searchTextLower = params.searchText.toLowerCase();
      filteredEstimate = filteredEstimate.filter(estimate =>
        Object.values(estimate).some(
          value =>
            typeof value === 'string' &&
            value.toLowerCase().includes(searchTextLower),
        ),
      );
    }
    return filteredEstimate;
  };
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(getData(params));
    }, 1000);
  });
};

const getEstimate = async (id: number) => {
  const estimate = ESTIMATES_DATA.find(estimate => estimate.id === id);
  return estimate;
};

export { getEstimates, getEstimate };
