import {
	IsEmail,
	IsNotEmpty,
	IsString,
	IsOptional,
	IsEnum,
} from 'class-validator';
import { IsEnumKey } from '../customValidators';

export enum ECustomerStatus {
	Active = 'ACTIVE',
	Inactive = 'INACTIVE',
}

export enum ECustomerType {
	Individual = 'Individual',
	Business = 'Business',
}

export enum ECustomerContactMethod {
	Phone = 'Phone',
	Email = 'Email',
	Text = 'Text',
	Any = 'Any',
}

export enum EActionOption {
	Edit = 'EDIT',
	Detail = 'DETAIL',
	Preview = 'PREVIEW',
}
export enum ECustomerFields {
	FirstName = 'firstName',
	LastName = 'lastName',
	Email = 'email',
	Phone = 'phone',
	Address = 'address',
	CustomerType = 'customerType',
	CreatedAt = 'createdAt',
}

export interface CustomerInterface {
	id: string;
	firstName: string;
	lastName?: string;
	email: string;
	phone: string;
	address?: string;
	status: ECustomerStatus;
	customerType: ECustomerType;
	tenantId: string;
	createdAt?: Date;
	updatedAt?: Date;
	contactMethod?: ECustomerContactMethod;
}

export interface CreateCustomerInterface {
	firstName: string;
	lastName?: string;
	email: string;
	phone: string;
	address?: string;
	tenantId?: string;
	customerType?: ECustomerType;
	contactMethod?: ECustomerContactMethod;
}

export const CUSTOMER_DEFAULTS_VALUES: Partial<CreateCustomerInterface> = {
	firstName: '',
	lastName: '',
	email: '',
	phone: '',
	address: '',
	customerType: ECustomerType.Individual,
};

export class CreateCustomerValidation
	implements Partial<CreateCustomerInterface>
{
	@IsNotEmpty({ message: 'Customer first name is required!' })
	@IsString()
	firstName: string;

	@IsOptional()
	@IsString()
	lastName: string;

	@IsNotEmpty({ message: 'Email is required!' })
	@IsEmail(undefined, { message: 'Email is not valid!' })
	email: string;

	@IsNotEmpty({ message: 'Phone number is required!' })
	@IsString()
	phone: string;

	@IsOptional()
	@IsString({ message: 'Address must be string!' })
	address: string;

	@IsOptional()
	@IsEnumKey(ECustomerType)
	customerType: ECustomerType;

	@IsOptional()
	@IsEnum(ECustomerContactMethod)
	contactMethod: ECustomerContactMethod;
}
