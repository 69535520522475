import {
  FC,
  // ReactNode, useCallback
} from 'react';

import {
  StyledActionsContainer,
  StyledButtonGroup,
  StyledInnerButtonGroup,
  StyledMainContainer,
  StyledTypography,
  StyledTableContainer,
} from './ServiceListing.styles';

import { getTableColumns } from './tableColumns';

import { IUseTableActionTypes, useTableHook } from '@/hooks/useTable';

import useToggle from '@/hooks/useToggle';
import { PrimaryButton, SearchBar } from '@/components/common';
import CreateService from '../CreateService';
import { ServiceInterface } from '@plenumpro/shared';
import { useFetchAllServices } from '@/services/servicesCatalog/qurey';
import { useGetCompanySettings } from '@/services';

interface IServiceListingProps {
  rows?: ServiceInterface[];
  searchText: string;
}

const ServiceListing: FC<IServiceListingProps> = ({ searchText }) => {
  const createServiceToggle = useToggle();
  const { data: services, isLoading } = useFetchAllServices();
  const servicesData = services || [];

  const { data } = useGetCompanySettings();

  const currency = data?.data?.currency;
  const columns = getTableColumns(currency);
  const { tableProps, CustomTable, dispatch } = useTableHook<any>({
    initialState: {
      itemsPerPage: 50,
    },
  });

  const handleSearch = () => {
    const trimmedText = searchText.trim();

    dispatch({
      type: trimmedText
        ? IUseTableActionTypes.SET_FILTER
        : IUseTableActionTypes.REMOVE_FILTER,
      payload: {
        name: trimmedText || undefined,
      },
    });
    // refetchCategories({
    //   filters: trimmedText ? [{ customerName: trimmedText }] : [],
    //   page: 1,
    //   pageSize: 50,
    // });
  };

  // const handleSearchChange = (text: string) => {
  //   onSearchChange(text);
  //   if (text.trim() === '') {
  //     refetchCategories({
  //       filters: [],
  //       page: 1,
  //       pageSize: 50,
  //     });
  //   }
  // };

  const serviceCountLabel =
    servicesData.length === 0
      ? '0 service'
      : `${servicesData.length} service${servicesData.length > 1 ? 's' : ''}`;

  return (
    <StyledMainContainer>
      <StyledActionsContainer>
        <StyledTypography>{serviceCountLabel}</StyledTypography>
        <StyledButtonGroup>
          <SearchBar
            placeholder="Search Services"
            onChange={e => handleSearch()}
          />
          <StyledInnerButtonGroup>
            <PrimaryButton onClick={createServiceToggle.setToOpen}>
              Create Service
            </PrimaryButton>
          </StyledInnerButtonGroup>
        </StyledButtonGroup>
      </StyledActionsContainer>
      <StyledTableContainer>
        <CustomTable
          {...tableProps}
          rows={servicesData}
          columns={columns}
          loading={isLoading}
          rowsPerPageOptions={[10, 15, 20, 50, 100]}
        />
      </StyledTableContainer>
      <CreateService
        open={createServiceToggle.isOpen}
        onClose={createServiceToggle.setToClose}
        payload={currency}
      />
    </StyledMainContainer>
  );
};

export default ServiceListing;
