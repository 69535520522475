import { FC } from 'react';

interface IconProps {
  isActive?: boolean;
}

const InboxIcon: FC<IconProps> = ({ isActive = false }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.656 18.479C3.51242 18.6038 3.3362 18.6851 3.14803 18.7133C2.95987 18.7415 2.76756 18.7155 2.59369 18.6382C2.41982 18.5609 2.2716 18.4356 2.16644 18.2771C2.06128 18.1185 2.00354 17.9332 2 17.743V15.5C1.46957 15.5 0.960859 15.2893 0.585786 14.9142C0.210714 14.5391 0 14.0304 0 13.5V6.5C0 5.96957 0.210714 5.46086 0.585786 5.08579C0.960859 4.71071 1.46957 4.5 2 4.5H13C13.5304 4.5 14.0391 4.71071 14.4142 5.08579C14.7893 5.46086 15 5.96957 15 6.5V13.5C15 14.0304 14.7893 14.5391 14.4142 14.9142C14.0391 15.2893 13.5304 15.5 13 15.5H8.003L3.656 18.479ZM16 10.517C15.9968 10.64 15.9968 10.763 16 10.886V10.517ZM16 10.517C16.005 10.41 16.006 9.07 16.004 6.498C16.0032 5.70287 15.6868 4.94058 15.1243 4.37862C14.5617 3.81666 13.7991 3.501 13.004 3.501H5V2.5C5 1.96957 5.21071 1.46086 5.58579 1.08579C5.96086 0.710714 6.46957 0.5 7 0.5L18 0.5C18.5304 0.5 19.0391 0.710714 19.4142 1.08579C19.7893 1.46086 20 1.96957 20 2.5V9.5C20 10.0304 19.7893 10.5391 19.4142 10.9142C19.0391 11.2893 18.5304 11.5 18 11.5V13.743C17.9965 13.9332 17.9387 14.1185 17.8336 14.2771C17.7284 14.4356 17.5802 14.5609 17.4063 14.6382C17.2324 14.7155 17.0401 14.7415 16.852 14.7133C16.6638 14.6851 16.4876 14.6038 16.344 14.479L16 14.243V10.517Z"
        fill={isActive ? '#279660' : '#828F87'}
      />
    </svg>
  );
};

export default InboxIcon;
