import { Navigate } from 'react-router-dom';

import PrivateRoutes from './PrivateRoutes';
import PublicRoutes from './PublicRoutes';
import { useContext } from 'react';
import { UserContext } from '@/context';
import { Loader } from '@/components';

const Routes = () => {
  const { isAuthenticated } = useContext(UserContext);
  if (typeof isAuthenticated === 'undefined') {
    return [
      {
        path: '*',
        element: <Loader />,
      },
    ];
  }

  return [
    {
      path: '',
      children: [
        { index: true, element: <Navigate to="signin" replace /> },
        PrivateRoutes({ isAuthenticated }),
        PublicRoutes({ isAuthenticated }),
      ],
    },
  ];
};

export default Routes;
