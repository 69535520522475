import { FC, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import NewCustomerForm from './CustomerForm';
import { ModalDialog } from '@/components/common';
// import { CreateCustomerValidation } from '@plenumpro/shared';
// import { useCreateCustomer } from '@/services';
import { toast } from 'material-react-toastify';
import { TAppointmentForm, TCustomerForm } from '@/utils';
import ScheduleAppointmentForm from '../AppointmentForm/AppointmentForm';

interface NewCustomerProps {
  open: boolean;
  onClose: () => void;
}

const NewCreateCustomer: FC<NewCustomerProps> = ({ open, onClose }) => {
  const formMethods = useForm();
  const [currentStep, setCurrentStep] = useState(0);

  // const { mutate: createCustomer } = useCreateCustomer();

  // const handleCreateCustomer = async (data: CreateCustomerValidation) => {
  //   try {
  //     const response = await createCustomer(data);
  //     console.log(response);
  //     toast.success('Customer Created!');
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const goToNewCustomerForm = () => setCurrentStep(1);
  const goToScheduleAppointmentForm = () => setCurrentStep(0);
  const selectAppointmentLater = () => onClose();

  const formTitle =
    currentStep === 0 ? 'Schedule Appointment' : 'Create new Customer';

  const formDescription =
    currentStep === 0
      ? 'Enter the customer address to find the suitable time slots available for appointment'
      : '';

  const handleCreateCustomer = (customerData: TCustomerForm) => {};
  const handleScheduleAppointment = (scheduleData: TAppointmentForm) => {};

  const handleFormError = (error: string) => {
    toast.error(`Error: ${error}`);
  };

  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      title={'Create new Customer'}
      width="600px"
    >
      <FormProvider {...formMethods}>
        {/* <NewCustomerForm onClose={onClose} onError={handleFormError} /> */}
        {currentStep === 0 ? (
          <ScheduleAppointmentForm
            onContinue={goToNewCustomerForm}
            onBack={selectAppointmentLater}
            onScheduleAppointment={handleScheduleAppointment}
          />
        ) : (
          <NewCustomerForm
            onBack={goToScheduleAppointmentForm}
            onCreateCustomer={handleCreateCustomer}
            onClose={onClose}
            onError={handleFormError}
            // onClose={onClose}
          />
        )}
      </FormProvider>
    </ModalDialog>
  );
};

export default NewCreateCustomer;
