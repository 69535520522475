import {
  Card,
  CardContent,
  Typography,
  Box,
  Divider,
  IconButton,
  Link,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { StyledCard, StyledCardHeader } from './Card.styles';
import useToggle from '@/hooks/useToggle';
import { CreateEstimate } from '@/components/Estimates';

const EstimatesCard = () => {
  const createEstimateToggle = useToggle();

  return (
    <StyledCard>
      <CardContent>
        <StyledCardHeader>
          <Box display="flex" alignItems="center">
            <InsertDriveFileIcon sx={{ color: '#BDBDBD', mr: 1 }} />
            <Typography variant="h6" sx={{ fontWeight: 600 }}>
              Estimates
            </Typography>
          </Box>
          <IconButton
            onClick={() => createEstimateToggle.setToOpen()}
            sx={{ padding: 0, margin: 0 }}
          >
            <AddIcon
              sx={{
                color: 'green',
                border: '1px solid green',
                cursor: 'pointer',
                borderRadius: 1,
              }}
            />
          </IconButton>
        </StyledCardHeader>

        <Divider />

        <Box mt={2}>
          <Typography variant="h4" sx={{ fontWeight: 600 }}>
            $36,543
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Total estimates open value
          </Typography>
        </Box>

        <Box mt={2}>
          <Typography variant="h4" sx={{ fontWeight: 600 }}>
            $26,284
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Total estimates won value
          </Typography>
        </Box>

        <Box mt={8}>
          <Link
            href="/estimates"
            underline="hover"
            sx={{ color: 'green', fontWeight: 400, fontSize: 14 }}
          >
            {`See all estimates >`}
          </Link>
        </Box>
      </CardContent>
      <CreateEstimate
        open={createEstimateToggle.isOpen}
        onClose={createEstimateToggle.setToClose}
        onCreateEstimate={() => {}}
      />
    </StyledCard>
  );
};

export default EstimatesCard;
