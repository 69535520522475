import { FC } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { ModalDialog } from '@/components/common';
import SubCategoryForm from './SubCategoryForm';
import { SubCategoryInterface } from '@plenumpro/shared';
import { useAddSubCategories } from '@/services';

interface CreateSubCategoryProps {
  open: boolean;
  onClose: () => void;
}

const CreateSubCategory: FC<CreateSubCategoryProps> = ({ open, onClose }) => {
  const formMethods = useForm<SubCategoryInterface>();

  const { mutate: addSubCategory } = useAddSubCategories();

  const handleCreateSubCategory = async (data: SubCategoryInterface) => {
    return new Promise<void>((resolve, reject) => {
      addSubCategory(data, {
        onSuccess: (response: any) => {
          onClose();
          resolve();
        },
        onError: (error: any) => {
          console.error('Error creating SubCategory:', error);
          reject(error);
        },
      });
    });
  };

  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      title="Create Sub Category"
      width="500px"
    >
      <FormProvider {...formMethods}>
        <SubCategoryForm
          onClose={onClose}
          onCreateSubCategory={handleCreateSubCategory}
        />
      </FormProvider>
    </ModalDialog>
  );
};

export default CreateSubCategory;
