import { useRef, useEffect, FC, ReactNode } from 'react';
import { get, useFormContext } from 'react-hook-form';

import { Box, FormLabel } from '@mui/material';

import {
  ErrorText,
  StyledAutoCompleteContainer,
  StyledInputBase,
} from './PlaceAutoComplete.styles';

interface PlaceAutoCompleteProps {
  name: string;
  label?: string;
  icon?: ReactNode;
  onChange: (place: any) => void;
}

declare global {
  interface Window {
    google: {
      maps: {
        places: {
          Autocomplete: any;
        };
      };
    };
  }
}

const PlaceAutoComplete: FC<PlaceAutoCompleteProps> = ({
  name,
  label,
  icon,
  onChange,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const autoCompleteRef = useRef<any>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const options = {
    componentRestrictions: { country: ['us'] },
    fields: ['address_components', 'geometry', 'name'],
    types: ['address'],
  };

  useEffect(() => {
    if (window.google && window.google.maps && inputRef.current) {
      autoCompleteRef.current = new window.google.maps.places.Autocomplete(
        inputRef.current,
        options,
      );
      autoCompleteRef.current.addListener('place_changed', async function () {
        const place = await autoCompleteRef.current.getPlace();
        onChange(place);
      });
    }
  }, [onChange]);

  const { ref: registerRef, ...registerProps } = register(name);

  const error = get(errors, name);

  return (
    <>
      {label && (
        <Box
          marginTop={'24px'}
          textAlign={'left'}
          width={'100%'}
          maxWidth={'440px'}
        >
          <FormLabel
            sx={{
              color: '#4F4F4F',
              fontWeight: 700,
              fontSize: '16px',
              paddingLeft: { xs: '16px', sm: '12px', md: '0px' },
            }}
          >
            {label}
          </FormLabel>
        </Box>
      )}
      <StyledAutoCompleteContainer>
        <Box>
          {icon && (
            <Box
              sx={{
                position: 'absolute',
                left: { xs: '24px', md: '12px' },
                top: '50%',
                transform: 'translateY(-50%)',
                zIndex: '2',
              }}
            >
              {icon}
            </Box>
          )}
          <StyledInputBase
            placeholder="Enter zip or street address"
            inputRef={e => {
              inputRef.current = e;
              registerRef(e);
            }}
            {...registerProps}
          />
        </Box>
        <Box>
          {error && <ErrorText fontSize={14}>{error.message}</ErrorText>}
        </Box>
      </StyledAutoCompleteContainer>

      <Box
        height={'264px'}
        width={'100%'}
        bgcolor={'#279660'}
        alignContent={'center'}
        textAlign={'center'}
      >
        Map
      </Box>
    </>
  );
};

export default PlaceAutoComplete;
