import {
	IsEnum,
	IsNotEmpty,
	IsNumber,
	IsOptional,
	IsString,
	IsUUID,
	Min,
} from 'class-validator';
import { Type } from 'class-transformer';

export enum EServiceFields {
	Id = ' id',
	Name = 'name',
	Description = 'description',
	Price = 'price',
	Duration = 'duration',
	PriceType = 'priceType',
	ServiceType = 'serviceType',
	Status = 'status',
	CreatedAt = 'createdAt',
	UpdatedAt = 'updatedAt',
}

export enum EServiceStatus {
	Active = 'ACTIVE',
	Inactive = 'INACTIVE',
}

export enum EServiceType {
	OneTime = 'ONE_TIME',
	Recurring = 'RECURRING',
	Package = 'PACKAGE',
}

export enum EPriceType {
	Hourly = 'HOURLY',
	Flat = 'FLAT',
	Package = 'PACKAGE',
}
export interface ServiceInterface {
	id: string;
	name: string;
	description?: string;
	price: number;
	priceType: EPriceType;
	serviceType: EServiceType;
	status: EServiceStatus;
	subCategoryId: string;
	duration: number;
	createdAt: Date;
	updatedAt: Date;
}

export const SERVICES_DEFAULT_VALUES: Partial<ServiceInterface> = {
	name: '',
	description: '',
	price: 1,
	priceType: EPriceType.Flat,
	serviceType: EServiceType.OneTime,
	status: EServiceStatus.Active,
	subCategoryId: '',
	duration: 60,
};

export class CreateServiceValidation implements Partial<ServiceInterface> {
	@IsNotEmpty({ message: 'Service name is required!' })
	@IsString({ message: 'Service name is required!' })
	name: string;

	@IsOptional()
	@IsString()
	description?: string;

	@IsNumber()
	@Min(1, { message: 'Price must be at least 1!' })
	@Type(() => Number)
	price: number;

	@IsNotEmpty({ message: 'Price type is required!' })
	@IsEnum(EPriceType, { message: 'Invalid price type!' })
	priceType: EPriceType;

	@IsNotEmpty({ message: 'Service type is required!' })
	@IsEnum(EServiceType, { message: 'Invalid service type!' })
	serviceType: EServiceType;

	@IsNotEmpty({ message: 'Category is required!' })
	@IsUUID('4', { message: 'Category is required!' })
	categoryId: string;

	@IsNotEmpty({ message: 'Sub-Category is required!' })
	@IsUUID('4', { message: 'Sub-Category is required!' })
	subCategoryId: string;

	@IsNotEmpty({ message: 'Duration is required!' })
	@IsNumber({}, { message: 'Duration must be a valid number!' })
	@Min(60, { message: 'Minimum duration 60 minutes!' })
	@Type(() => Number)
	duration: number;
}
