import axios, { AxiosResponse } from 'axios';
import { request } from '@/utils';

const base = '/scheduling';

export const getScheduling = async (): Promise<any> => {
  try {
    const response = await request({
      url: base,
      method: 'get',
    });
    return (response as AxiosResponse).data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error;
    } else {
      throw new Error('An unexpected error occurred');
    }
  }
};
