import { FC, ReactNode } from 'react';
import { Box, IconButton, Typography } from '@mui/material';

import {
  StyledCard,
  StyledHeaderBox,
  StyledTitleTypography,
  StyledContentColumn,
  StyledItemBox,
  StyledLabelTypography,
  StyledInfoIconBox,
  StyldContentWrapper,
  StyledMap,
  StyledPaymentActionBox,
  StyledIconButton,
  StyledId,
} from './CustomerProfile.styles';
import { PrimaryButton } from '@/components/common';
import { IPaymentMethod } from '@/utils/schemas/types/customers';
import { dateFormat } from '@/utils';

interface IDetalilsCardProps {
  data: any;
  title: string;
  titleActionIcon?: ReactNode;
  subTitleIcon?: ReactNode;
  valueIcon?: ReactNode[];
}
const DetailsCard: FC<IDetalilsCardProps> = ({
  data,
  title,
  titleActionIcon,
  subTitleIcon,
  valueIcon,
}) => {
  return (
    <StyledCard>
      {title === 'Address' && <StyledMap></StyledMap>}
      <StyldContentWrapper>
        <StyledHeaderBox>
          <StyledTitleTypography>{title}</StyledTitleTypography>
          {titleActionIcon && <IconButton>{titleActionIcon}</IconButton>}
        </StyledHeaderBox>

        {title === 'Summary' && data && (
          <>
            <StyledContentColumn>
              <StyledItemBox>
                <StyledLabelTypography>Created</StyledLabelTypography>
                <Typography variant="body2">
                  {dateFormat(data.createdAt)}
                </Typography>
              </StyledItemBox>
            </StyledContentColumn>
            <StyledContentColumn>
              {/* <StyledItemBox>
                <StyledLabelTypography>Last Service</StyledLabelTypography>
                {data.estimates.length > 0 && (
                  <Typography variant="body2">
                    {data.estimates[
                      data.estimates.length - 1
                    ].date.toLocaleDateString()}
                  </Typography>
                )}
              </StyledItemBox> */}
            </StyledContentColumn>
            <StyledContentColumn>
              <StyledItemBox>
                <StyledLabelTypography>Last Interaction</StyledLabelTypography>
                {/* <Typography variant="body2">
                  {data.lastInteraction.date.toLocaleDateString()} | Opened
                  Estimate <StyledId>#{data.estimates[0]?.id}</StyledId>
                </Typography> */}
              </StyledItemBox>
            </StyledContentColumn>
            <StyledContentColumn>
              <StyledItemBox>
                <StyledLabelTypography>Lifetime value</StyledLabelTypography>
                {/* <Typography variant="body2">
                  ${' '}
                  {data.jobs.reduce(
                    (total: number, job: { amount: number }) =>
                      total + job.amount,
                    0,
                  )}
                </Typography> */}
              </StyledItemBox>
            </StyledContentColumn>
            <StyledContentColumn>
              <StyledItemBox>
                <StyledLabelTypography>
                  Outstanding Balance
                </StyledLabelTypography>
                {/* <Typography variant="body2">$500</Typography> */}
              </StyledItemBox>
            </StyledContentColumn>
          </>
        )}

        {title === 'Communication preferences' && (
          <StyledContentColumn>
            <StyledItemBox>
              <StyledLabelTypography>{data.type}</StyledLabelTypography>
              <Typography variant="body2">{data.value}</Typography>
            </StyledItemBox>
          </StyledContentColumn>
        )}

        {title === 'Payment method' && data && data.length > 0
          ? data.map((item: IPaymentMethod, index: number) => (
              <StyledContentColumn key={index}>
                <StyledHeaderBox>
                  <StyledItemBox>
                    <StyledLabelTypography>{item.type}</StyledLabelTypography>
                    <Typography variant="body2">
                      {item.type} ending {item.cardNumber.slice(-4)}
                    </Typography>
                  </StyledItemBox>
                  <Box>
                    {subTitleIcon && (
                      <StyledIconButton>{subTitleIcon}</StyledIconButton>
                    )}
                  </Box>
                </StyledHeaderBox>
                <StyledPaymentActionBox>
                  <PrimaryButton>Add Credit Card</PrimaryButton>
                  <PrimaryButton>Request Card on File</PrimaryButton>
                </StyledPaymentActionBox>
              </StyledContentColumn>
            ))
          : null}

        {data && title === 'Address' && (
          <StyledHeaderBox>
            <StyledItemBox>
              <Typography variant="body2">{data.address}</Typography>
            </StyledItemBox>
            <Box>
              {subTitleIcon && (
                <StyledIconButton>{subTitleIcon}</StyledIconButton>
              )}
            </Box>
          </StyledHeaderBox>
        )}

        {title === 'Contact Info' && data ? (
          <>
            <StyledContentColumn>
              <StyledItemBox>
                <StyledLabelTypography>Name</StyledLabelTypography>
                <Typography variant="body2">{`${data.firstName} ${data.lastName}`}</Typography>
              </StyledItemBox>
            </StyledContentColumn>

            <StyledContentColumn>
              <StyledItemBox>
                <StyledLabelTypography>Phone</StyledLabelTypography>
                <StyledInfoIconBox>
                  <Typography variant="body2">{data.phone}</Typography>
                </StyledInfoIconBox>
              </StyledItemBox>
            </StyledContentColumn>

            <StyledContentColumn>
              <StyledItemBox>
                <StyledLabelTypography>Email</StyledLabelTypography>
                <Typography variant="body2">{data.email}</Typography>
              </StyledItemBox>
            </StyledContentColumn>
          </>
        ) : null}
      </StyldContentWrapper>
    </StyledCard>
  );
};

export default DetailsCard;
