import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const StyledCheckBoxWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));

export const StyledOptionBox = styled(Box)<{
  selected: boolean;
  paddingX?: string | Record<string, string>;
  paddingY?: string | Record<string, string>;
  width?: string | Record<string, string>;
  fontSize?: string;
  disabled?: boolean;
}>(
  ({
    theme,
    selected,
    paddingX,
    paddingY,
    width,
    disabled,
    fontSize,
    height,
    borderRadius,
  }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    width: typeof width === 'string' ? width : width?.xs || '190px',
    height: typeof height === 'string' ? height : '44px',
    borderRadius: typeof borderRadius === 'string' ? borderRadius : '8px',
    padding: `${typeof paddingY === 'string' ? paddingY : paddingY?.xs || '20px'} ${
      typeof paddingX === 'string' ? paddingX : paddingX?.xs || '20px'
    }`,
    opacity: disabled ? 0.4 : 1,
    cursor: disabled ? 'not-allowed' : 'pointer',
    backgroundColor: selected ? theme.palette.common.white : 'transparent',
    color: selected ? theme.palette.primary.main : theme.palette.text.primary,
    fontSize: fontSize || '16px',
    fontWeight: selected ? 700 : 400,
    border: selected
      ? `2px solid ${theme.palette.secondary.main}`
      : `2px solid ${theme.palette.grey[50]}`,
    boxShadow: selected ? '0px 0px 0px 5px rgba(39, 150, 96, 0.1)' : 'none',
    '&:hover': {
      border: disabled
        ? `2px solid ${theme.palette.grey[50]}`
        : `2px solid ${theme.palette.secondary.main}`,
    },
    [theme.breakpoints.up('xs')]: {
      width: typeof width === 'object' ? width?.xs : width,
      padding: `${typeof paddingY === 'object' ? paddingY?.xs : paddingY} ${
        typeof paddingX === 'object' ? paddingX?.xs : paddingX
      }`,
    },
    [theme.breakpoints.up('sm')]: {
      width: typeof width === 'object' ? width?.sm : width,
      padding: `${typeof paddingY === 'object' ? paddingY?.sm : paddingY} ${
        typeof paddingX === 'object' ? paddingX?.sm : paddingX
      }`,
    },
    [theme.breakpoints.up('md')]: {
      width: typeof width === 'object' ? width?.md : width,
      padding: `${typeof paddingY === 'object' ? paddingY?.md : paddingY} ${
        typeof paddingX === 'object' ? paddingX?.md : paddingX
      }`,
    },
    [theme.breakpoints.up('lg')]: {
      width: typeof width === 'object' ? width?.lg : width,
      padding: `${typeof paddingY === 'object' ? paddingY?.lg : paddingY} ${
        typeof paddingX === 'object' ? paddingX?.lg : paddingX
      }`,
    },
    [theme.breakpoints.up('xl')]: {
      width: typeof width === 'object' ? width?.xl : width,
      padding: `${typeof paddingY === 'object' ? paddingY?.xl : paddingY} ${
        typeof paddingX === 'object' ? paddingX?.xl : paddingX
      }`,
    },
  }),
);

export const StyledOptionsContainer = styled(Box)<{
  gap?: string | Record<string, string>;
  justifyContent?: string | Record<string, string>;
}>(({ gap, theme, justifyContent }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  margin: 'auto',
  [theme.breakpoints.up('xs')]: {
    justifyContent:
      typeof justifyContent === 'object' ? justifyContent?.xs : justifyContent,
  },
  [theme.breakpoints.up('lg')]: {
    justifyContent:
      typeof justifyContent === 'object' ? justifyContent?.lg : justifyContent,
  },
  [theme.breakpoints.up('xs')]: {
    gap: typeof gap === 'object' ? gap?.xs : gap,
  },
  [theme.breakpoints.up('sm')]: {
    gap: typeof gap === 'object' ? gap?.sm : gap,
  },
  [theme.breakpoints.up('md')]: {
    gap: typeof gap === 'object' ? gap?.md : gap,
  },
  [theme.breakpoints.up('lg')]: {
    gap: typeof gap === 'object' ? gap?.lg : gap,
  },
  [theme.breakpoints.up('xl')]: {
    gap: typeof gap === 'object' ? gap?.xl : gap,
  },
}));

export const OptionImage = styled('img')({
  maxWidth: '100%',
  maxHeight: '50px',
  marginBottom: '8px',
});
