export const calendarContainer = {
    minWidth: { xl: '1200px', xs: '550px' },
    height: '100vh',
}

export const calendarLoading = {
    position: 'absolute',
    top: 100,
    left: 120,
    width: '100%',
    height: '100%',
    bgcolor: 'rgba(255, 255, 255, 0.7)',
    zIndex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}