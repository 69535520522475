import { CustomGridColDef } from '@/hooks/useTable/types';
import { dateFormat, getHumanizeEnum } from '@/utils';
import { Box } from '@mui/material';
import { ECustomerFields, ECustomerType } from '@plenumpro/shared';

export const getTableColumns = (): CustomGridColDef[] => [
  {
    field: ECustomerFields.FirstName,
    headerName: 'First Name',
    minWidth: 150,
    flex: 1,
    sortable: true,
    hide: true,
    renderHeader: () => <Box sx={{ paddingLeft: '45px' }}>First Name</Box>,
    link: params => `/customers/${params.row.id}`,
  },
  {
    field: ECustomerFields.LastName,
    headerName: 'Last Name',
    minWidth: 150,
    flex: 1,
    sortable: true,
  },
  {
    field: ECustomerFields.Email,
    headerName: 'Email',
    minWidth: 150,
    flex: 1,
    sortable: false,
  },
  {
    field: ECustomerFields.Phone,
    headerName: 'Phone',
    minWidth: 150,
    flex: 1,
    sortable: false,
  },
  {
    field: ECustomerFields.Address,
    headerName: 'Address',
    minWidth: 150,
    flex: 1,
    sortable: false,
  },
  {
    field: ECustomerFields.CustomerType,
    headerName: 'Customer Type',
    minWidth: 150,
    flex: 1,
    sortable: false,
    renderCell: params =>
      getHumanizeEnum(ECustomerType, params.row.customerType),
  },
  {
    field: ECustomerFields.CreatedAt,
    headerName: 'Registration Date',
    type: 'date',
    minWidth: 50,
    flex: 1,
    sortable: false,
    renderCell: params => {
      const date = params.row.createdAt
        ? dateFormat(params.row.createdAt)
        : 'N/A';
      return date;
    },
  },
];
