import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";

export const TagContainerStyled = styled(Box)(() => ({
    display: 'inline-flex',
}));
export const TagTextStyled = styled(Typography)(({ color, backgroundColor }: { color: string; backgroundColor: string }) => ({
    fontWeight: 500,
    fontSize: '12px',
    color: color,
    backgroundColor: backgroundColor,
    padding: '4px 8px',
    borderRadius: '15px',
    display: 'inline-flex',
}));