import { useEffect, useState } from 'react';

import { IEstimate, IEstimateParams } from '@/utils';
import { getEstimates } from '@/services';
import { EstimateListing } from '@/components';

const EstimatesListingPage = () => {
  const [estimates, setestimates] = useState<IEstimate[]>([]);
  const [laoding, setLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');

  useEffect(() => {
    getEstimatesData();
  }, []);

  const getEstimatesData = async (params?: IEstimateParams) => {
    try {
      setLoading(true);
      const newEstimates = (await getEstimates(
        params,
      )) as unknown as IEstimate[];
      setestimates(newEstimates);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const handleSearchChange = (text: string) => {
    setSearchText(text);
  };

  return (
    <EstimateListing
      loading={laoding}
      rows={estimates}
      refetchEstimates={getEstimatesData}
      searchText={searchText}
      onSearchChange={handleSearchChange}
    />
  );
};

export default EstimatesListingPage;
