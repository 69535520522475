const welocomMessage = 'Welcome to the shared package';

console.log({ welocomMessage });

export { welocomMessage };
export * from './companies';
export * from './auth';
export * from './scheduling';
export * from './services';
export * from './categories';
export * from './branding';
export * from './customers';
export * from './common';
export * from './professionals';
export * from './appointments';
