import { z } from 'zod';

export const EstimateFormSchema = z.object({
  customerName: z
    .string({ message: 'Customer name is required' })
    .min(1, 'Customer name is required'),
  dateoptions: z
    .string({ message: 'Please select your date slot' })
    .min(1, 'Please select your date slot'),
  timeoptions: z
    .string({ message: 'Please select your time slot' })
    .min(1, 'Please select your time slot'),
  status: z
    .string({ message: 'Status is required' })
    .min(1, 'Status is required'),
  options: z
    .string({ message: 'Options is required' })
    .min(1, 'Options is required'),
  outcome: z
    .string({ message: 'Outcome is required' })
    .min(1, 'Outcome is required'),
  openValue: z
    .string({ message: 'Open value is required' })
    .min(1, 'Open value is required'),
  wonValue: z
    .string({ message: 'Won value is required' })
    .min(1, 'Won value is required'),
});
export type TEstimateForm = z.infer<typeof EstimateFormSchema>;
