import {
  CustomersIcon,
  EstimatesIcon,
  JobsIcon,
  ProposalIcon,
} from '@/assets/icons';
import { Delete, Edit, Logout } from '@mui/icons-material';
interface IMenuItem {
  text: string;
  icon: JSX.Element;
  link?: string;
}
export const HEADER_MENU_ITEMS: IMenuItem[] = [
  { text: 'Customer', icon: <CustomersIcon /> },
  // { text: 'Estimate', icon: <EstimatesIcon /> },
  // { text: 'Proposal', icon: <ProposalIcon /> },
  // { text: 'Job', icon: <JobsIcon /> },
];

export const CUSTOMER_MENU_ITEMS = [
  { text: 'Edit', icon: <Edit /> },
  { text: 'Delete', icon: <Delete /> },
];

export const PROFILE_LINK_ITEMS = [
  { text: 'Logout', icon: <Logout />, link: '/signout' },
];
