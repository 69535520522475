import { styled } from '@mui/system';
import { Box, Typography } from '@mui/material';
import { PrimaryButton } from '@/components/common';

export const StyledContainer = styled(Box)<{ height?: number }>(
  ({ height }) => ({
    maxHeight: height,
    transition: 'max-height 0.5s ease',
    backgroundColor: '#fff',
    overflow: 'hidden',
    padding: 0,
    margin: 0,
  }),
);

export const StyledContentWrapper = styled(Box)(() => ({
  padding: '25px',
  display: 'flex',
  gap: '32px',
}));

export const StyledSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  borderRight: '1px solid',
  borderColor: theme.palette.grey[50],
  width: '305px',
  maxWidth: '100%',
}));

export const StyledTitleSection = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '2px',
});

export const StyledTitle = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: 700,
}));

export const StyledDetailSection = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
});

export const StyledButton = styled(PrimaryButton)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
}));
