const LeftArrowIcon = () => {
  return (
    <svg
      width="10"
      height="18"
      viewBox="0 0 10 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.6716 8.99965L9.7426 16.0706L8.3286 17.4846L0.550599 9.70665C0.363128 9.51912 0.257812 9.26481 0.257812 8.99965C0.257812 8.73448 0.363128 8.48018 0.550599 8.29265L8.3286 0.514648L9.7426 1.92865L2.6716 8.99965Z"
        fill="black"
      />
    </svg>
  );
};

export default LeftArrowIcon;
