import React, { FC, useState, useCallback, memo } from 'react';
import { TableCell, Checkbox, TableSortLabel, Tooltip } from '@mui/material';
import { CustomGridColDef, ISortModel } from '../../types';
import {
  StyledTableCell,
  StyledTableHead,
  StyledTableRow,
} from './TableHeader.styles';

interface TableHeaderProps {
  columns: CustomGridColDef[];
  checkboxSelection?: boolean;
  expand?: boolean;
  numSelected?: number;
  onSelectAllClick?: (
    event: React.ChangeEvent<HTMLInputElement>,
    deselectAll: boolean,
  ) => void;
  rowCount: number;
  onSortModelChange: (sortModel: ISortModel[]) => void;
}

const TableHeader: FC<TableHeaderProps> = ({
  columns,
  checkboxSelection = false,
  expand = false,
  numSelected = 0,
  rowCount,
  onSelectAllClick,
  onSortModelChange,
}) => {
  const [sortModel, setSortModel] = useState<ISortModel[]>([]);

  const handleSortClick = useCallback(
    (col: CustomGridColDef) => {
      setSortModel(prevSortModel => {
        const existingSortIndex = prevSortModel.findIndex(
          sort => sort.field === col.field,
        );
        const newSortModel = [...prevSortModel];

        if (existingSortIndex !== -1) {
          if (newSortModel[existingSortIndex].sort === 'asc') {
            newSortModel[existingSortIndex].sort = 'desc';
          } else {
            newSortModel.splice(existingSortIndex, 1);
          }
        } else {
          newSortModel.push({ field: col.field, sort: 'asc' });
        }

        onSortModelChange(newSortModel);
        return newSortModel;
      });
    },
    [onSortModelChange],
  );

  const renderTableCellContent = (col: CustomGridColDef) => {
    const sortInfo = sortModel.find(sort => sort.field === col.field);
    const isActive = !!sortInfo;
    const headerContent = col.renderHeader
      ? col.renderHeader()
      : col.headerName;

    return col.sortable ? (
      <TableSortLabel
        active={isActive}
        direction={isActive ? sortInfo.sort : 'asc'}
        onClick={() => handleSortClick(col)}
        hideSortIcon={col.hideSortIcons}
        disabled={col.disableReorder}
      >
        {headerContent}
      </TableSortLabel>
    ) : (
      headerContent
    );
  };

  const handleSelectAllClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (onSelectAllClick) {
        onSelectAllClick(event, numSelected > 0 && numSelected < rowCount);
      }
    },
    [numSelected, onSelectAllClick, rowCount],
  );

  return (
    <StyledTableHead>
      <StyledTableRow>
        {checkboxSelection && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected > 0 && numSelected === rowCount}
              onChange={handleSelectAllClick}
              inputProps={{ 'aria-label': 'select all items' }}
            />
          </TableCell>
        )}

        {columns.map(col => (
          <StyledTableCell
            key={col.field}
            align={col.align || 'left'}
            sx={{
              width: col.width || 'auto',
              minWidth: col.minWidth || 50,
              maxWidth: col.maxWidth || 'auto',
              textAlign: col.headerAlign || 'left',
              backgroundColor: col.headerClassName || 'transparent',
            }}
            title={col.description || col.headerTooltip}
            colSpan={col.colSpan || 1}
            data-resizable={col.resizable}
            data-pinned={col.pinned}
          >
            {col.description || col.headerTooltip ? (
              <Tooltip title={col.description || col.headerTooltip}>
                <>{renderTableCellContent(col)}</>
              </Tooltip>
            ) : (
              renderTableCellContent(col)
            )}
          </StyledTableCell>
        ))}

        {expand && <TableCell />}
      </StyledTableRow>
    </StyledTableHead>
  );
};

export default memo(TableHeader);
