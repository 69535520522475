import { CustomGridColDef } from '@/hooks/useTable/types';
import { Box } from '@mui/material';
import { EScheduling } from '@plenumpro/shared';

export const getTableColumns = (): CustomGridColDef[] => [
  {
    field: EScheduling.Customer,
    headerName: 'Customer',
    minWidth: 150,
    flex: 1,
    sortable: true,
    renderCell: param => param.row.customer.firstName,
    renderHeader: () => <Box sx={{ paddingLeft: '45px' }}>Customer</Box>,
  },
  {
    field: EScheduling.CustomerEmail,
    headerName: 'Email',
    minWidth: 150,
    flex: 1,
    sortable: true,
    renderCell: param => param.row.customer.email,
  },
  {
    field: EScheduling.Address,
    headerName: 'Address',
    minWidth: 150,
    flex: 1,
  },
  {
    field: EScheduling.Category,
    headerName: 'Category',
    minWidth: 150,
    flex: 1,
    renderCell: param => param.row.category.name,
  },
  {
    field: EScheduling.SubCategory,
    headerName: 'Sub Category',
    minWidth: 150,
    flex: 1,
    renderCell: param => param.row.subCategory.name,
  },
  {
    field: EScheduling.Service,
    headerName: 'Service',
    minWidth: 150,
    flex: 1,
    renderCell: param =>
      param.row.service.name ? param.row.service.name : 'N/A',
  },
  {
    field: EScheduling.ServiceCost,
    headerName: 'Service Cost',
    minWidth: 150,
    flex: 1,
  },
  {
    field: EScheduling.Emergency,
    headerName: 'Emergency',
    minWidth: 100,
    flex: 1,
    renderCell: params => (params.row.emergency ? 'Yes' : 'No'),
  },
  {
    field: EScheduling.Date,
    headerName: 'Date',
    type: 'date',
    minWidth: 150,
    flex: 1,
    sortable: true,
    renderCell: params => {
      const date = params.row.date;
      return date ? new Date(date).toLocaleDateString() : 'N/A';
    },
  },
  {
    field: EScheduling.Time,
    headerName: 'Time Slot',
    minWidth: 150,
    flex: 1,
    sortable: true,
  },

  {
    field: EScheduling.IssuesExplain,
    headerName: 'Issues Explained',
    minWidth: 200,
    flex: 2,
    renderCell: param =>
      param.row.issuesExplain ? param.row.issuesExplain : 'N/A',
  },
];
