import { FC } from 'react';
import { ButtonProps } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import StyledSecondaryButton from './SecondaryButton.styles';

interface SecondaryButtonProps extends ButtonProps {
  loading?: boolean;
  action?: () => void;
}

const SecondaryButton: FC<SecondaryButtonProps> = ({
  disabled = false,
  loading = false,
  children,
  variant = 'contained',
  sx = {},
  action,
  ...buttonProps
}) => {
  const variantStyles =
    variant === 'contained'
      ? StyledSecondaryButton.containedPrimary
      : StyledSecondaryButton.outlinedPrimary;

  return (
    <LoadingButton
      variant={variant}
      disabled={disabled || loading}
      loading={loading}
      onClick={() => (action ? action() : '')}
      sx={{
        ...StyledSecondaryButton.root,
        ...(variantStyles as any),
        width: {
          xs: '100%',
          md: '180px',
          lg: '275px',
        },
        ...sx,
      }}
      {...buttonProps}
    >
      {!loading && children}
    </LoadingButton>
  );
};

export default SecondaryButton;
