import { toast } from 'material-react-toastify';
import { useMutation, useQueryClient } from 'react-query';
import { addCategoriesApi, addsubcategoriesApi } from './api';

export const useAddCategories = () => {
  const queryClient = useQueryClient();
  return useMutation(addCategoriesApi, {
    onMutate: async newCategories => {
      return { newCategories };
    },
    onSuccess: () => {
      toast.success('Category created successful!');
      queryClient.invalidateQueries('allCategories');
    },
    onError: (error: any) => {
      const errorMessage = error?.data?.message?.message;
      toast.error(errorMessage);
    },
  });
};

export const useAddSubCategories = () => {
  const queryClient = useQueryClient();
  return useMutation(addsubcategoriesApi, {
    onMutate: async newSubCategories => {
      return { newSubCategories };
    },

    onSuccess: () => {
      toast.success('Sub category created successful!');
      queryClient.invalidateQueries('subCategories');
    },
    onError: (error: any) => {
      const errorMessage = error?.data?.message?.message;
      toast.error(errorMessage);
    },
  });
};
