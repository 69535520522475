import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';

import { Box, Paper, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import {
  SignInInterface,
  SignInValidation,
} from '@plenumpro/shared';
import { StringInputHook, SecondaryButton } from '@/components';
import { PlenumLogo } from '@/assets/icons';

import { useSignIn } from '@/services';

const SignIn: React.FC = () => {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { mutate: signIn, isLoading } = useSignIn();

  const methods = useForm<SignInInterface>({
    resolver: classValidatorResolver(SignInValidation),
    mode: 'all',
    defaultValues: {
      username: '',
      password: '',
    },
  });

  const { handleSubmit } = methods;

  const onSubmit = async (data: SignInInterface) => {
    signIn({
      username: data.username,
      password: data.password,
    });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
      }}
    >
      <Paper
        sx={{
          borderRadius: '25px',
        }}
      >
        <Box
          sx={{
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            height: '100px',
            backgroundColor: theme => theme.palette.backgroundCustom?.main,
            borderRadius: '25px 25px 0 25px',
            '::before': {
              content: '""',
              position: 'absolute',
              backgroundColor: 'transparent',
              bottom: '-50px',
              right: 0,
              height: '50px',
              width: '25px',
              borderTopRightRadius: '25px',
              boxShadow: theme =>
                `0 -25px 0 0 ${theme.palette.backgroundCustom?.main}`,
            },
          }}
        >
          <PlenumLogo />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 5,
            pb: 6,
          }}
        >
          <FormProvider {...methods}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              noValidate
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '20px',
              }}
            >
              <StringInputHook
                name="username"
                type="text"
                placeholder="Email"
                required
                autoComplete="email"
                label="Email"
                width={'300px'}
              />

              <StringInputHook
                name="password"
                required
                type={showPassword ? 'text' : 'password'}
                autoComplete="current-password"
                label="Password"
                placeholder="Password"
                width={'300px'}
                endAdornment={
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                }
              />
              <SecondaryButton
                type="submit"
                loading={isLoading}
                sx={{
                  width: '300px',
                }}
              >
                LOGIN
              </SecondaryButton>

              <Box
                sx={{
                  display: 'flex',
                  direction: 'row',
                  width: '100%',
                  gap: 1,
                  color: theme => theme.palette.backgroundCustom?.main,
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                  }}
                >
                  <hr />
                </Box>
                <Box> OR</Box>
                <Box
                  sx={{
                    width: '100%',
                  }}
                >
                  <hr />
                </Box>
              </Box>
              <SecondaryButton
                sx={{
                  width: '300px',
                }}
                onClick={() => navigate('/create-company')}
              >
                REGISTER COMPANY
              </SecondaryButton>
            </form>
          </FormProvider>
        </Box>
      </Paper>
    </Box>
  );
};

export default SignIn;
