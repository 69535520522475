import { FormControl, styled } from '@mui/material';

const StylesWrapper = styled(FormControl)(({ theme }) => ({
  '.MuiTextField-root': {
    marginBottom: 'unset',
  },
  '& .MuiOutlinedInput-root': {
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.grey[50],
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.secondary.main,
      boxShadow: `0px 0px 0px 5px rgba(39, 150, 96, 0.1)`,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: `2px solid ${theme.palette.grey[50]}`,
    },
    height: '55px',
    boxSizing: 'border-box',
    resize: 'none',
  },
}));

export default StylesWrapper;
