import { IsEmail, IsEnum, IsNotEmpty, IsString } from 'class-validator';
import { EAuth0Roles } from './auth0';

export enum EUserRoles {
  SUPER_ADMIN = 'SUPER_ADMIN',
  ADMIN = 'ADMIN',
  PROFESSIONAL = 'PROFESSIONAL',
  CUSTOMER = 'CUSTOMER',
}

export interface SignInInterface {
  username: string;
  password: string;
}

export class SignInValidation implements SignInInterface {
  @IsEmail(undefined, { message: 'Email is not valid!' })
  @IsNotEmpty({ message: 'Email is required!' })
  username: string;

  @IsString({ message: 'Invalid password!' })
  @IsNotEmpty({ message: 'Password is required!' })
  password: string;
}

export interface UserInterface {
  tenantId: string;
  name: string;
  email: string;
  password: string;
  role: EAuth0Roles;
}

export interface CreateUserInterface {
  name: string;
  email: string;
  password: string;
  role: EAuth0Roles;
}

export class CreateUserValidation implements CreateUserInterface {
  @IsString({ message: 'Name must be a string!' })
  @IsNotEmpty({ message: 'Name is required!' })
  name: string;

  @IsEmail(undefined, { message: 'Email is not valid!' })
  @IsNotEmpty({ message: 'Email is required!' })
  email: string;

  @IsString({ message: 'Password must be a string!' })
  @IsNotEmpty({ message: 'Password is required!' })
  password: string;

  @IsEnum(EAuth0Roles, { message: 'Role must be a valid enum value!' })
  @IsNotEmpty({ message: 'Role is required!' })
  role: EAuth0Roles;
}
