import { FC } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import EstimateForm from './parts/EstimateForm';
import { TEstimateForm } from '@/utils';
import { ModalDialog } from '@/components/common';

interface CreateEstimateProps {
  open: boolean;
  onClose: () => void;
  onCreateEstimate: () => void;
}

const CreateEstimate: FC<CreateEstimateProps> = ({ open, onClose }) => {
  const formMethods = useForm();

  const selectEstimateLater = () => onClose();

  const handleCreateEstimate = (estimateData: TEstimateForm) => {};

  return (
    <ModalDialog open={open} onClose={onClose} title="Create New Estimate">
      <FormProvider {...formMethods}>
        <EstimateForm
          onBack={selectEstimateLater}
          onCreateCustomer={handleCreateEstimate}
          onClose={onClose}
        />
      </FormProvider>
    </ModalDialog>
  );
};

export default CreateEstimate;
