import {
  Box,
  Divider,
  IconButton,
  TableCell,
  Typography,
  Table,
  TableBody,
  TableRow,
} from '@mui/material';
import { styled } from '@mui/system';

import {
  CalenderIcon,
  ClockIcon,
  LocationIcon,
  RightArrowIcon,
  UsersIcon,
} from '@/assets/icons';
import Chip from '@/components/Customers/parts/Chip';

const CalenderData = [
  {
    date: 'Monday, Aug 5',
    time: '3 - 5 PM',
    customerName: 'Aydin Mehr',
    serviceName: 'Furnace Service Call',
    location: '12009 W Burgundy Ave Littleton, CO 80127',
    teamMembers: 'Mike & Jassica Garner',
  },
  {
    date: 'Monday, Aug 5',
    time: '3 - 5 PM',
    customerName: 'Aydin Mehr',
    serviceName: 'Furnace Service Call',
    location: '12009 W Burgundy Ave Littleton, CO 80127',
    teamMembers: 'Mike & Jassica Garner',
  },
  {
    date: 'Monday, Aug 5',
    time: '3 - 5 PM',
    customerName: 'Aydin Mehr',
    serviceName: 'Furnace Service Call',
    location: '12009 W Burgundy Ave Littleton, CO 80127',
    teamMembers: 'Mike & Jassica Garner',
  },
  {
    date: 'Monday, Aug 5',
    time: '3 - 5 PM',
    customerName: 'Aydin Mehr',
    serviceName: 'Furnace Service Call',
    location: '12009 W Burgundy Ave Littleton, CO 80127',
    teamMembers: 'Mike & Jassica Garner',
  },
];

const BoxStyled = styled(Box)(() => ({
  padding: '24px',
  backgroundColor: 'white',
  borderRadius: '12px',
  boxShadow: '0px 1px 2px 0px #0000001A',
  flexGrow: 1,
}));

const HeaderStyled = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const HeaderLeftStyled = styled(Box)(() => ({
  display: 'flex',
  columnGap: '8px',
}));

const HeaderRightStyled = styled(Box)(() => ({
  display: 'flex',
  columnGap: '9px',
  alignItems: 'center',
}));

const ScrollableBoxStyled = styled(Box)(() => ({
  maxHeight: '350px',
  overflowY: 'scroll',
}));

const TableRowStyled = styled(TableRow)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
  backgroundColor: '#F8F8F8',
  marginTop: '16px',
  borderRadius: '16px',
  columnGap: '18px',
  alignItems: 'center',
}));

const TableCellStyled = styled(TableCell)(() => ({
  padding: '16px',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  borderBottom: 'none',
}));

const FlexBoxStyled = styled(Box)(() => ({
  display: 'flex',
  columnGap: '10px',
  alignItems: 'center',
}));
const LocationBoxStyled = styled(Box)(() => ({
  display: 'flex',
  columnGap: '10px',
}));
const CustomerInitialStyled = styled(Box)(() => ({
  height: '25px',
  width: '25px',
  backgroundColor: '#000000',
  textAlign: 'center',
  color: '#fff',
  borderRadius: '50%',
}));
const CellDividerStyled = styled(TableCellStyled)(({ theme }) => ({
  borderRight: '0.5px solid',
  height: '100px',
  width: '1px',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
  borderColor: '#ccc',
}));
const DetailsCard = () => {
  return (
    <BoxStyled>
      <HeaderStyled>
        <HeaderLeftStyled>
          <CalenderIcon color="#BDBDBD" />
          <Typography variant="h6">Calender</Typography>
        </HeaderLeftStyled>
        <HeaderRightStyled>
          <Typography variant="body2">15 jobs this week </Typography>
          <Chip text="+10%" color="#186942" backgroundColor="#4CC374" />
        </HeaderRightStyled>
      </HeaderStyled>

      <Divider sx={{ paddingY: '5px' }} />

      <ScrollableBoxStyled>
        <Table sx={{ width: '100%' }}>
          <TableBody>
            {CalenderData.map((item, index) => (
              <TableRowStyled key={index}>
                <TableCellStyled>
                  <Typography sx={{ fontWeight: 600, fontSize: '16px' }}>
                    {item.date}
                  </Typography>
                  <FlexBoxStyled>
                    <ClockIcon />
                    <Typography>{item.time}</Typography>
                  </FlexBoxStyled>
                  <FlexBoxStyled sx={{ marginTop: '8px' }}>
                    <CustomerInitialStyled>A</CustomerInitialStyled>
                    <Typography sx={{ fontWeight: 600, fontSize: '14px' }}>
                      {item.customerName}
                    </Typography>
                  </FlexBoxStyled>
                </TableCellStyled>
                <CellDividerStyled />
                <TableCellStyled>
                  <Typography sx={{ fontWeight: 600, fontSize: '16px' }}>
                    {item.serviceName}
                  </Typography>
                  <LocationBoxStyled sx={{ width: 'auto' }}>
                    <LocationIcon />
                    <Typography sx={{ marginTop: 0, lineHeight: '1.5' }}>
                      {item.location}
                    </Typography>
                  </LocationBoxStyled>
                  <FlexBoxStyled>
                    <UsersIcon />
                    <Typography>{item.teamMembers}</Typography>
                  </FlexBoxStyled>
                </TableCellStyled>
                <TableCellStyled>
                  <IconButton>
                    <RightArrowIcon />
                  </IconButton>
                </TableCellStyled>
              </TableRowStyled>
            ))}
          </TableBody>
        </Table>
      </ScrollableBoxStyled>
    </BoxStyled>
  );
};

export default DetailsCard;
