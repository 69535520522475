import { toast } from 'material-react-toastify';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { logoutApi, signInApi } from './api';
import { UserContext } from '@/context';
import { useContext } from 'react';

export const useSignIn = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation(signInApi, {
    onSuccess: async () => {
      await queryClient.invalidateQueries('user');
      toast.success('Login successful!');
      navigate('/dashboard');
    },
    onError: (error: any) => {
      const errorMessage =
        error?.response?.data?.message || 'Incorrect email or password!';
      toast.error(errorMessage);
    },
  });
};

export const useLogoutUser = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { resetUser } = useContext(UserContext);

  return useMutation(logoutApi, {
    onSuccess: (data: any) => {
      if (data.status === 200 && !data.isAuthenticated) {
        queryClient.invalidateQueries('user');
        resetUser();
        navigate('/signin', { replace: true });
        toast.success('Logged out!');
      }
    },
    onError: (error: any) => {
      const errorMessage =
        error?.response?.data?.message || 'Error while logging out!';
      toast.error(errorMessage);
    },
  });
};
