import { FC, useContext } from 'react';
import { Box, Typography } from '@mui/material';
import { format } from 'date-fns';
import {
  CalenderCard,
  CheckInsCard,
  EstimatesCard,
  InvoicesCard,
  JobsCard,
} from './cards';
import { PrimaryButton } from '../common';
import {
  CalenderIcon,
  DownArrowIcon,
  InvoicesIcon,
  JobsIcon,
  MoneyIcon,
  SettingIcon,
} from '@/assets/icons';
import { Chip, TopChip } from './parts/Chips';
import { useNavigate } from 'react-router-dom';
import { getGreeting, humanizeString } from '@/utils';
import { UserContext } from '@/context';

const Dashboard: FC = () => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  if (!user) return null;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '48px',
        padding: '30px',
        height: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          gap: '40px',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography
            sx={{ fontSize: '32px', fontWeight: 500, lineHeight: '35px' }}
          >
            {getGreeting()}, {humanizeString(user.name)}
          </Typography>
          <Typography sx={{ color: 'grey.200' }}>
            {format(new Date(), 'EEEE, MMM d, yyyy')}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: '12px' }}>
          <PrimaryButton sx={{ background: 'white' }}>
            <CalenderIcon />
            Last 6 months
            <DownArrowIcon />
          </PrimaryButton>
          <PrimaryButton
            sx={{
              height: '48px',
            }}
            color="primary"
            variant="contained"
            borderWidth="0"
            onClick={() => navigate('/customize')}
          >
            <SettingIcon />
            Customize
          </PrimaryButton>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          minHeight: '90px',
          alignItems: 'end',
          flexWrap: 'wrap',
          gap: '12px',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
          <Typography sx={{ color: 'Primary' }}>Money earned</Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'end',
              height: '66px',
            }}
          >
            <Typography variant="h5">$</Typography>
            <Typography
              sx={{
                fontSize: { xs: '40px', md: '64px' },
                fontWeight: 700,
                marginLeft: '10px',
                marginBottom: { xs: '6px', md: '14px' },
              }}
            >
              285,000
            </Typography>
            <Typography variant="h5" sx={{ marginLeft: '10px' }}>
              .00
            </Typography>

            <Box marginLeft={'10px'}>
              <Chip text="+7.5%" />
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', gap: '12px', flexWrap: 'wrap' }}>
          <TopChip icon={<MoneyIcon />} heading="Profit" price="210,000.00" />
          <TopChip icon={<JobsIcon />} heading="Jobs Completed" price="95" />
          <TopChip icon={<InvoicesIcon />} heading="Win rate" price="44%" />
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
          gap: '10px',
          flexWrap: 'wrap',
        }}
      >
        <Box sx={{ width: { lg: '49%' } }}>
          <CalenderCard />
        </Box>
        <Box sx={{ width: { lg: '49%' } }}>
          <InvoicesCard />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '20px',
        }}
      >
        <CheckInsCard />
        <EstimatesCard />
        <JobsCard />
      </Box>
    </Box>
  );
};

export default Dashboard;
