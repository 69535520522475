import { SxProps, Theme } from '@mui/material/styles';
import colors from '../../../theme/colors';

const StyledSecondaryButton: { [key: string]: SxProps<Theme> } = {
  root: {
    width: '275px',
    height: '55px',
    borderRadius: '8px',
    textTransform: 'none',
    fontWeight: 700,
    fontSize: '16px',
    paddingTop: '16px',
    paddingBottom: '16px',
    lineHeight: '22.4px',
  },
  containedPrimary: {
    backgroundColor: colors.secondary,
    color: colors.white,
    '&:hover': {
      backgroundColor: colors.secondary,
    },
    '&:active': {
      backgroundColor: colors.secondary,
    },
    '&.Mui-disabled': {
      cursor: 'not-allowed',
      backgroundColor: colors.secondaryLight,
      color: colors.white,
    },
  },
  outlinedPrimary: {
    border: `2px solid ${colors.primary}`,
    color: colors.primary,
    backgroundColor: colors.white,
    '&:hover': {
      backgroundColor: colors.grey50,
      border: `2px solid ${colors.primary}`,
    },
    '&:active': {
      backgroundColor: colors.grey100,
    },
    '&.Mui-disabled': {
      cursor: 'not-allowed',
      backgroundColor: colors.grey200,
      color: colors.grey400,
    },
  },
};

export default StyledSecondaryButton;
