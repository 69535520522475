const BoxedRightArrowIcon = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="35" height="35" rx="3.5" stroke="#E0E0E0" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5418 17.2809C21.7293 17.4685 21.8346 17.7228 21.8346 17.9879C21.8346 18.2531 21.7293 18.5074 21.5418 18.6949L15.8848 24.3519C15.7926 24.4474 15.6822 24.5236 15.5602 24.576C15.4382 24.6284 15.307 24.656 15.1742 24.6572C15.0414 24.6583 14.9098 24.633 14.7869 24.5828C14.664 24.5325 14.5523 24.4582 14.4584 24.3643C14.3645 24.2704 14.2903 24.1588 14.24 24.0359C14.1897 23.913 14.1644 23.7813 14.1656 23.6485C14.1667 23.5158 14.1943 23.3845 14.2467 23.2625C14.2991 23.1405 14.3753 23.0302 14.4708 22.9379L19.4208 17.9879L14.4708 13.0379C14.2887 12.8493 14.1879 12.5967 14.1901 12.3345C14.1924 12.0723 14.2976 11.8215 14.483 11.6361C14.6684 11.4507 14.9192 11.3455 15.1814 11.3433C15.4436 11.341 15.6962 11.4418 15.8848 11.6239L21.5418 17.2809Z"
        fill="#262626"
      />
    </svg>
  );
};

export default BoxedRightArrowIcon;
