import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const BoxStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  columnGap: '8px',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '19.6px',
  textTransform: 'none',
}));
