import React from 'react';
import { Box, Button, ButtonProps } from '@mui/material';
import { BoxStyled } from './PrimaryButton.styles';

interface PrimaryButtonProps extends ButtonProps {
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  color?: 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  variant?: 'text' | 'outlined' | 'contained';
  borderWidth?: string;
}

const PrimaryButton = React.forwardRef<HTMLButtonElement, PrimaryButtonProps>(
  (
    {
      onClick,
      color = 'success',
      variant = 'outlined',
      borderWidth = '1px',
      children,
      ...props
    },
    ref,
  ) => {
    return (
      <Box>
        <Button
          ref={ref}
          onClick={onClick}
          color={color}
          variant={variant}
          {...props}
          sx={{
            border: `${borderWidth} solid`,
            borderRadius: '32px',
            boxShadow: 'none',
            padding: {
              xs: '9px 20px',
              md: '12px 24px',
            },
            fontSize: '16px',
            ':hover': {
              boxShadow: 'none',
            },
          }}
        >
          <BoxStyled>{children}</BoxStyled>
        </Button>
      </Box>
    );
  },
);

PrimaryButton.displayName = 'PrimaryButton';

export default PrimaryButton;
