import { FC } from 'react';
import { Box } from '@mui/material';

import { customHeaderStyles } from './CustomHeader.styles';
interface CustomHeaderProps {
  label: string;
}

const CustomHeader: FC<CustomHeaderProps> = ({ label }) => {
  const [day, date] = label.split('-');

  return (
    <Box sx={customHeaderStyles.container}>
      <Box sx={customHeaderStyles.dayPart}>{day}</Box>
      <Box sx={customHeaderStyles.datePart}>{date}</Box>
    </Box>
  );
};

export default CustomHeader;
