import {
	registerDecorator,
	ValidationOptions,
	ValidationArguments,
} from 'class-validator';

const IsEnumKey = (enumType: object, validationOptions?: ValidationOptions) => {
	return function (object: Object, propertyName: string) {
		registerDecorator({
			name: 'isEnumKey',
			target: object.constructor,
			propertyName: propertyName,
			options: validationOptions,
			validator: {
				validate(value: any, args: ValidationArguments) {
					if (Array.isArray(value)) {
						return value.every((val) => Object.keys(enumType).includes(val));
					}
					return Object.keys(enumType).includes(value);
				},
				defaultMessage(args: ValidationArguments) {
					return `${args.property} must be a valid key of enum ${args.constraints[0].name}`;
				},
			},
		});
	};
};

export { IsEnumKey };
