import { IsNotEmpty, IsString, IsOptional, IsEnum } from 'class-validator';

export enum EAppointmentStatus {
	Pending = 'PENDING',
	Completed = 'COMPLETED',
	Cancelled = 'CANCELED',
}

export interface AppointmentInterface {
	professionalId: string;
	customerId: string;
	date: string;
	startTime: string;
	endTime: string;
	status?: EAppointmentStatus;
	createdAt?: Date;
	updatedAt?: Date;
}

export interface CreateAppointmentInterface {
	professionalId: string;
	customerId: string;
	date: string;
	startTime: string;
	endTime: string;
	status?: EAppointmentStatus;
}

export class CreateAppointmentValidation
	implements Partial<CreateAppointmentInterface>
{
	@IsNotEmpty({ message: 'Prfessional is required!' })
	@IsString()
	professionalId: string;

	@IsNotEmpty({ message: 'Customer is required!' })
	@IsString()
	customerId: string;

	@IsNotEmpty({ message: 'Customer is required!' })
	@IsString()
	date: string;

	@IsNotEmpty({ message: 'Customer is required!' })
	@IsString()
	startTime: string;

	@IsNotEmpty({ message: 'Customer is required!' })
	@IsString()
	endTime: string;

	@IsOptional()
	@IsEnum(EAppointmentStatus)
	status: EAppointmentStatus;
}
