import { Table, TableBody, TableRow, styled } from '@mui/material';

export const StyledTable = styled(Table)({
  width: '100%',
  borderCollapse: 'separate',
  borderSpacing: '0px 12px',
  paddingRight: '42px',
});

export const StyledTableBody = styled(TableBody)({
  position: 'relative',
});

export const StyledLoadingRow = styled(TableRow)({
  position: 'absolute',
  top: 14,
  left: 0,
  width: '100%',
  height: '100%',
  bgcolor: 'rgba(255, 255, 255, 0.7)',
  zIndex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
