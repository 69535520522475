import { AxiosResponse } from 'axios';
import { ServiceInterface } from '@plenumpro/shared';
import { request } from '@/utils';

const base = '/services';

export const addServices = (data: Partial<ServiceInterface> | null) =>
  request({ url: base, method: 'post', data });

export const fetchAllServices = async (): Promise<ServiceInterface[]> => {
  const response = await request({
    url: base,
    method: 'get',
  });
  return (response as AxiosResponse<ServiceInterface[]>).data;
};
