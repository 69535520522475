import {
  FC,
  // ReactNode, useCallback
} from 'react';

import {
  StyledActionsContainer,
  StyledButtonGroup,
  StyledInnerButtonGroup,
  StyledMainContainer,
  StyledTypography,
  StyledTableContainer,
} from './SubCategoryListing.styles';

import { getTableColumns } from './tableColumns';

import { IUseTableActionTypes, useTableHook } from '@/hooks/useTable';

import useToggle from '@/hooks/useToggle';
import { PrimaryButton, SearchBar } from '@/components/common';
import CreateSubCategory from '../CreateSubCategory';
import { SubCategoryInterface } from '@plenumpro/shared';
import { useGetSubCategories } from '@/services';

interface ISubCategoriesListingProps {
  rows: SubCategoryInterface[];
  searchText: string;
}

const SubCategoriesListing: FC<ISubCategoriesListingProps> = ({
  searchText,
}) => {
  const createSubCategoryToggle = useToggle();

  const { data: categories = [], isLoading } = useGetSubCategories();

  const columns = getTableColumns();
  const { tableProps, CustomTable, dispatch } = useTableHook<any>({
    initialState: {
      itemsPerPage: 50,
    },
  });

  const handleSearch = () => {
    const trimmedText = searchText.trim();

    dispatch({
      type: trimmedText
        ? IUseTableActionTypes.SET_FILTER
        : IUseTableActionTypes.REMOVE_FILTER,
      payload: {
        name: trimmedText || undefined,
      },
    });
    // refetchCategories({
    //   filters: trimmedText ? [{ customerName: trimmedText }] : [],
    //   page: 1,
    //   pageSize: 50,
    // });
  };

  // const handleSearchChange = (text: string) => {
  //   onSearchChange(text);
  //   if (text.trim() === '') {
  //     refetchCategories({
  //       filters: [],
  //       page: 1,
  //       pageSize: 50,
  //     });
  //   }
  // };

  const subcategoryCountLabel =
    categories.length === 0
      ? '0 sub category'
      : `${categories.length} sub categor${categories.length > 1 ? 'ies' : 'y'}`;

  return (
    <StyledMainContainer>
      <StyledActionsContainer>
        <StyledTypography>{subcategoryCountLabel}</StyledTypography>
        <StyledButtonGroup>
          <SearchBar
            placeholder="Search Sub Categories"
            onChange={e => handleSearch()}
          />
          <StyledInnerButtonGroup>
            <PrimaryButton onClick={createSubCategoryToggle.setToOpen}>
              Create Sub Category
            </PrimaryButton>
          </StyledInnerButtonGroup>
        </StyledButtonGroup>
      </StyledActionsContainer>
      <StyledTableContainer>
        <CustomTable
          {...tableProps}
          rows={categories}
          columns={columns}
          loading={isLoading}
          rowsPerPageOptions={[10, 15, 20, 50, 100]}
        />
      </StyledTableContainer>
      <CreateSubCategory
        open={createSubCategoryToggle.isOpen}
        onClose={createSubCategoryToggle.setToClose}
      />
    </StyledMainContainer>
  );
};

export default SubCategoriesListing;
