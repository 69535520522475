import { FC, cloneElement, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Divider,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import {
  DrawerHeader,
  StyledBox,
  StyledDrawer,
  StyledListItem,
  StyledListItemButton,
  StyledListItemIcon,
  StyledListItemText,
  StyledTypography,
  StyledLogo,
} from './Sidebar.styles';
import { PlenumLogo, PlenumIcon, SidebarClickIcon } from '@/assets/icons';
import { SIDEBAR_ITEMS } from '@/utils/constants';
import {
  MUICustomIcon,
  SIDEBAR_SECONDARY_ITEMS,
  SidebarItem,
} from '@/utils/constants/sidebarItems';
import { UserContext } from '@/context';
import { canAccessSidebarItems } from '@/hooks/useCanAccess';
import { Logout } from '@mui/icons-material';
import { useLogoutUser } from '@/services';

interface SidebarProps {
  open: boolean;
  handleDrawerToggle: () => void;
}

const Sidebar: FC<SidebarProps> = ({ open, handleDrawerToggle }) => {
  const { mutate: logoutUser } = useLogoutUser();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const ismediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { user } = useContext(UserContext);

  const handleNavigation = (path: string) => {
    navigate(path);
    if (ismediumScreen) handleDrawerToggle();
  };

  const filteredSidebarItems = canAccessSidebarItems(
    SIDEBAR_ITEMS,
    user?.permissions,
  );

  const secondaryItems: SidebarItem[] = [
    ...SIDEBAR_SECONDARY_ITEMS,
    {
      text: 'Logout',
      icon: (
        <MUICustomIcon>
          <Logout />
        </MUICustomIcon>
      ),
      handler: logoutUser,
    },
  ];

  return (
    <StyledDrawer
      variant={ismediumScreen ? 'temporary' : 'permanent'}
      open={open}
      onClose={handleDrawerToggle}
    >
      <DrawerHeader>
        <StyledLogo onClick={handleDrawerToggle}>
          {open ? <PlenumLogo /> : <PlenumIcon />}
        </StyledLogo>
        {open && (
          <IconButton onClick={handleDrawerToggle}>
            <SidebarClickIcon />
          </IconButton>
        )}
      </DrawerHeader>
      <Divider />

      <StyledBox open={open}>
        <Box>
          {filteredSidebarItems.map((item, index) => {
            const route = location.pathname;
            const activePath =
              route === item.path || route.includes(item.path ?? '');

            return (
              <StyledListItem key={index} disablePadding>
                <StyledListItemButton
                  onClick={() => handleNavigation(item?.path ?? '')}
                  open={open}
                  path={activePath}
                >
                  <StyledListItemIcon open={open}>
                    {cloneElement(item.icon, {
                      isActive: activePath,
                    })}
                  </StyledListItemIcon>
                  <StyledListItemText open={open} path={activePath}>
                    <StyledTypography path={activePath}>
                      {item.text}
                    </StyledTypography>
                  </StyledListItemText>
                </StyledListItemButton>
              </StyledListItem>
            );
          })}
        </Box>
        <Box>
          {secondaryItems.map((item, index) => {
            const route = location.pathname;
            const activePath =
              route === item?.path || route.includes(item?.path ?? '');

            return (
              <StyledListItem key={index} disablePadding>
                <StyledListItemButton
                  onClick={() =>
                    item.path
                      ? handleNavigation(item?.path ?? '')
                      : item.handler && item.handler()
                  }
                  open={open}
                  path={activePath}
                >
                  <StyledListItemIcon open={open}>
                    {cloneElement(item.icon, {
                      isActive: activePath,
                    })}
                  </StyledListItemIcon>
                  <StyledListItemText open={open} path={activePath}>
                    <StyledTypography path={activePath}>
                      {item.text}
                    </StyledTypography>
                  </StyledListItemText>
                </StyledListItemButton>
              </StyledListItem>
            );
          })}
        </Box>
      </StyledBox>
      <Divider />
    </StyledDrawer>
  );
};

export default Sidebar;
