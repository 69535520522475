import { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Box, CircularProgress } from '@mui/material';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';

import {
  StringInputHook,
  SecondaryButton,
  ResponsiveFieldWrapper,
} from '@/components';
import { useGetCompanyPrfile } from '@/services';
import {
  UpdateCompanyInterface,
  COMPANY_PROFILLE_DEFAULT_VALUES,
  UpdateCompanyProfileValidation,
} from '@plenumpro/shared';
import { useUpdateCompanyProfile } from '@/services/company/mutation';

const CompanyProfileForm = () => {
  const { data: companyProfileData, isLoading } = useGetCompanyPrfile();
  const data = companyProfileData?.data;
  const { mutate: updateProfile, isLoading: isUpdatLoading } =
    useUpdateCompanyProfile();

  const formMethods = useForm<UpdateCompanyInterface>({
    resolver: classValidatorResolver(UpdateCompanyProfileValidation),
    defaultValues: COMPANY_PROFILLE_DEFAULT_VALUES,
    mode: 'all',
  });
  const { handleSubmit, reset } = formMethods;

  useEffect(() => {
    if (data) {
      reset({
        name: data.name,
        contactPerson: data.contactPerson,
        address: data.address,
        contactPhone: data.contactPhone,
        contactEmail: data.contactEmail,
      });
    }
  }, [data, reset]);

  const onSubmit: SubmitHandler<UpdateCompanyInterface> = async formData => {
    const companyProfileId = data?.id;
    const profile = {
      name: formData.name,
      address: formData.address,
      contactPerson: formData.contactPerson,
      contactPhone: formData.contactPhone,
    };
    console.log({ companyProfileId, profile });
    await updateProfile({
      companyProfileId,
      profile,
    });
  };

  return (
    <>
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="400px"
        >
          <CircularProgress color="secondary" />
        </Box>
      ) : (
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box
              display="flex"
              flexDirection={{ xs: 'column', md: 'row' }}
              flexWrap="wrap"
              gap={3}
              width="100%"
            >
              <Box
                display="flex"
                flexDirection={{ xs: 'column', md: 'row' }}
                flexWrap="wrap"
                gap={3}
                width="100%"
              >
                <ResponsiveFieldWrapper>
                  <StringInputHook
                    label="Company Name"
                    name="name"
                    placeholder="Company Name"
                    formControlProps={{ fullWidth: true }}
                  />
                </ResponsiveFieldWrapper>
                <ResponsiveFieldWrapper>
                  <StringInputHook
                    label="Address"
                    name="address"
                    placeholder="Address"
                    formControlProps={{ fullWidth: true }}
                  />
                </ResponsiveFieldWrapper>
              </Box>
              <Box
                display="flex"
                flexDirection={{ xs: 'column', md: 'row' }}
                flexWrap="wrap"
                gap={3}
                width="100%"
              >
                <ResponsiveFieldWrapper>
                  <StringInputHook
                    label="Manager Name"
                    name="contactPerson"
                    placeholder="Manager Name"
                    formControlProps={{ fullWidth: true }}
                  />
                </ResponsiveFieldWrapper>
                <ResponsiveFieldWrapper>
                  <StringInputHook
                    label="Email"
                    name="contactEmail"
                    placeholder="Email"
                    formControlProps={{ fullWidth: true }}
                    disabled={true}
                  />
                </ResponsiveFieldWrapper>

                <ResponsiveFieldWrapper>
                  <StringInputHook
                    label="Phone"
                    name="contactPhone"
                    placeholder="Phone"
                    formControlProps={{ fullWidth: true }}
                  />
                </ResponsiveFieldWrapper>
              </Box>
            </Box>
            <Box mt={5} display="flex" justifyContent="flex-end">
              <SecondaryButton
                type="submit"
                variant="contained"
                sx={{
                  width: '200px',
                }}
                loading={isUpdatLoading}
              >
                Update Profile
              </SecondaryButton>
            </Box>
          </form>
        </FormProvider>
      )}
    </>
  );
};

export default CompanyProfileForm;
