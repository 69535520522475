import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';

import { ButtonsContainer, ContactInfoWrapper } from './CreateCustomer.styles';
import {
  StringInputHook,
  SecondaryButton,
  SelectHookInput,
} from '@/components';
import {
  CreateCustomerInterface,
  CreateCustomerValidation,
  CUSTOMER_DEFAULTS_VALUES,
  ECustomerType,
} from '@plenumpro/shared';
import { useCreateCustomer } from '@/services';
import { TCustomerForm, formatPhoneNumber, getEnumOptions } from '@/utils';

interface CustomerFormProps {
  onBack: () => void;
  onClose: () => void;
  onError: (error: string) => void;
  onCreateCustomer: (data: TCustomerForm) => void;
}

const CustomerTypeOptions = getEnumOptions(ECustomerType);
const CustomerForm: FC<CustomerFormProps> = ({
  onClose,
  onBack,
  onCreateCustomer,
}) => {
  const formMethods = useForm<CreateCustomerInterface>({
    resolver: classValidatorResolver(CreateCustomerValidation),
    defaultValues: CUSTOMER_DEFAULTS_VALUES,
    mode: 'all',
  });

  const { handleSubmit, reset } = formMethods;
  const { mutate: createCustomer, isLoading } = useCreateCustomer();

  const onSubmit = async (data: any) => {
    onCreateCustomer(data);
    console.log('onCreateCustomer', data);
    createCustomer(data, {
      onSuccess: () => {
        reset();
        onClose();
      },
    });
  };
  const handleBackToAppointment = () => {
    onBack();
  };

  return (
    <>
      <Grid alignSelf={'center'}>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ContactInfoWrapper>
              <Grid
                item
                xs={6}
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' },
                  gap: '12px',
                }}
              >
                <StringInputHook
                  label="First Name"
                  name="firstName"
                  placeholder="First Name"
                />
                <StringInputHook
                  label="Last Name"
                  name="lastName"
                  placeholder="Last Name"
                />
              </Grid>
              <StringInputHook
                label="Email"
                name="email"
                placeholder="Email"
                fullWidth
              />
              <Grid
                item
                xs={6}
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' },
                  gap: '12px',
                }}
              >
                <StringInputHook
                  label="Phone Number"
                  name="phone"
                  format={formatPhoneNumber}
                  placeholder="(303) 210-0000"
                />
                <StringInputHook
                  label="Address"
                  name="address"
                  placeholder="Address"
                />
              </Grid>
              <SelectHookInput
                label="Customer Type"
                name="customerType"
                options={CustomerTypeOptions}
                getValueAsObject={false}
              />
            </ContactInfoWrapper>
          </form>
        </FormProvider>
      </Grid>
      <ButtonsContainer>
        <SecondaryButton
          variant="outlined"
          onClick={handleBackToAppointment}
          sx={{ marginBottom: { xs: '12px', sm: 0 } }}
        >
          Back to appointment
        </SecondaryButton>
        <SecondaryButton
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          loading={isLoading}
        >
          Create Customer
        </SecondaryButton>
      </ButtonsContainer>
    </>
  );
};

export default CustomerForm;
