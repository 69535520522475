import { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Box, CircularProgress } from '@mui/material';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';

import {
  StringInputHook,
  SecondaryButton,
  SelectHookInput,
  CheckBoxInputHook,
  ResponsiveFieldWrapper,
} from '@/components';
import { useGetCompanySettings, useUpdateCompanySettings } from '@/services';
import {
  CompanySettingsValidation,
  ETimeZones,
  ECurrency,
  ELanguage,
  ENotificationPreference,
  WeekDays,
  CompanySettingsInterface,
  COMPANY_SETTINGS_DEFAULT_VALUES,
} from '@plenumpro/shared';
import { toast } from 'material-react-toastify';
import { getEnumOptions } from '@/utils';

export const getOptions = <T extends { [key: string]: string }>(
  enumObject: T,
) => {
  return Object.keys(enumObject).map(key => ({
    label: key,
    value: enumObject[key as keyof T],
  }));
};

const TimeZoneOptions = getEnumOptions(ETimeZones);
const CurrencyOptions = getEnumOptions(ECurrency);
const LanguageOptions = getEnumOptions(ELanguage);
const WeekDaysOptions = getOptions(WeekDays);
const NotificationOptions = getEnumOptions(ENotificationPreference);

const CompanySettingForm = () => {
  const { data: companySettingsData, isLoading } = useGetCompanySettings();
  const data = companySettingsData?.data;
  const { mutate: updateSettings, isLoading: isUpdatLoading } =
    useUpdateCompanySettings();

  const formMethods = useForm<CompanySettingsInterface>({
    resolver: classValidatorResolver(CompanySettingsValidation),
    defaultValues: COMPANY_SETTINGS_DEFAULT_VALUES,
    mode: 'all',
  });
  const { handleSubmit, reset } = formMethods;

  useEffect(() => {
    if (data) {
      reset({
        timeZone: data.timeZone,
        currency: data.currency,
        language: data.language,
        businessHours: data.businessHours,
        serviceAreas: data.serviceAreas,
        notificationPreferences: data.notificationPreferences,
        workingDays: data.workingDays,
      });
    }
  }, [data, reset]);

  const onSubmit: SubmitHandler<CompanySettingsInterface> = async formData => {
    updateSettings({
      settingId: data?.id,
      settings: {
        ...formData,
        businessHours: Number(formData.businessHours),
      },
    });
  };

  return (
    <>
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="400px"
        >
          <CircularProgress color="secondary" />
        </Box>
      ) : (
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box
              display="flex"
              flexDirection={{ xs: 'column', md: 'row' }}
              flexWrap="wrap"
              gap={3}
            >
              <ResponsiveFieldWrapper>
                <SelectHookInput
                  label="Time Zone"
                  name="timeZone"
                  options={TimeZoneOptions}
                  getValueAsObject={false}
                  formControlProps={{ fullWidth: true }}
                />
              </ResponsiveFieldWrapper>
              <ResponsiveFieldWrapper>
                <SelectHookInput
                  label="Currency"
                  name="currency"
                  options={CurrencyOptions}
                  getValueAsObject={false}
                  formControlProps={{ fullWidth: true }}
                />
              </ResponsiveFieldWrapper>
              <ResponsiveFieldWrapper>
                <SelectHookInput
                  label="Language"
                  name="language"
                  options={LanguageOptions}
                  getValueAsObject={false}
                  formControlProps={{ fullWidth: true }}
                />
              </ResponsiveFieldWrapper>
              <ResponsiveFieldWrapper>
                <StringInputHook
                  label="Business Hours"
                  name="businessHours"
                  type="number"
                  placeholder="Business Hours"
                  formControlProps={{ fullWidth: true }}
                />
              </ResponsiveFieldWrapper>
              <ResponsiveFieldWrapper>
                <StringInputHook
                  label="Service Areas"
                  name="serviceAreas"
                  placeholder="Service Areas"
                  formControlProps={{ fullWidth: true }}
                />
              </ResponsiveFieldWrapper>
              <ResponsiveFieldWrapper>
                <SelectHookInput
                  label="Notification Preferences"
                  name="notificationPreferences"
                  options={NotificationOptions}
                  getValueAsObject={false}
                  formControlProps={{ fullWidth: true }}
                />
              </ResponsiveFieldWrapper>
              <ResponsiveFieldWrapper>
                <CheckBoxInputHook
                  name="workingDays"
                  label="Working Days"
                  options={WeekDaysOptions}
                  gap="15px"
                  width="140px"
                  multi={true}
                />
              </ResponsiveFieldWrapper>
            </Box>
            <Box mt={5} display="flex" justifyContent="flex-end">
              <SecondaryButton
                type="submit"
                variant="contained"
                sx={{
                  width: '200px',
                }}
                disabled={isLoading}
                loading={isUpdatLoading}
              >
                Update
              </SecondaryButton>
            </Box>
          </form>
        </FormProvider>
      )}
    </>
  );
};

export default CompanySettingForm;
