import { z } from 'zod';

export const CustomerFormSchema = z.object({
  firstName: z
    .string({ message: 'First Name is required' })
    .min(1, 'First Name is required'),
  lastName: z
    .string({ message: 'Last Name is required' })
    .min(1, 'Last Name is required'),
  email: z
    .string({ message: 'Invalid email address' })
    .email('Invalid email address'),
  phoneNumber: z
    .string({ message: 'Phone number is required' })
    .min(1, 'Phone number is required'),
  homePhone: z
    .string({ message: 'Home phone is required' })
    .min(1, 'Home phone is required'),
  street: z
    .string({ message: 'Street is required' })
    .min(1, 'Street is required'),
  city: z.string({ message: 'City is required' }).min(1, 'City is required'),
  zip: z
    .string({ message: 'Zip is required' })
    .min(1, 'Zip is required')
    .max(6),
  unit: z.string({ message: 'Unit is required' }).min(1, 'Unit is required'),
});
export type TCustomerForm = z.infer<typeof CustomerFormSchema>;

export const AppointmentFormSchema = z.object({
  address: z
    .string({ message: 'Address is required' })
    .min(1, 'Address is required'),
  dateoptions: z
    .string({ message: 'Please select your date slot' })
    .min(1, 'Please select your date slot'),
  timeoptions: z
    .string({ message: 'Please select your time slot' })
    .min(1, 'Please select your time slot'),
});

export type TAppointmentForm = z.infer<typeof AppointmentFormSchema>;
