import { CustomGridColDef } from '@/hooks/useTable/types';
import { Box } from '@mui/material';
import { ECustomerFields } from '@plenumpro/shared';

export const getTableColumns = (): CustomGridColDef[] => [
  {
    field: ECustomerFields.FirstName,
    headerName: 'Invoice. #',
    minWidth: 50,
    flex: 1,
    sortable: false,
    hide: true,
    renderHeader: () => <Box sx={{ paddingLeft: '45px' }}>First Name</Box>,
    link: params => `/customers/${params.row.id}`,
  },
  {
    field: ECustomerFields.LastName,
    headerName: 'Date Sent',
    minWidth: 70,
    flex: 1,
    sortable: false,
  },
  {
    field: ECustomerFields.LastName,
    headerName: 'Date Paid',
    minWidth: 70,
    flex: 1,
    sortable: false,
  },
  {
    field: ECustomerFields.Email,
    headerName: 'Status',
    minWidth: 70,
    flex: 1,
    sortable: false,
  },
  {
    field: ECustomerFields.Phone,
    headerName: 'Amount',
    minWidth: 70,
    flex: 1,
    sortable: false,
  },
];
