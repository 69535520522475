import React from 'react';
import { Controller, FieldPath, FieldValues } from 'react-hook-form';
import StringInput, { StringInputProps } from './StringInput';

export interface StringInputHookProps<T extends FieldValues>
  extends Omit<StringInputProps, 'name'> {
  name: FieldPath<T> | any;
  format?: (value: string) => string;
  disabled?: boolean;
  multiline?: boolean;
  endAdornment?: React.ReactNode;
  rows?: number;
}

function StringInputHook<T extends FieldValues = FieldValues>({
  name,
  format,
  disabled,
  multiline = false,
  rows = 4,
  width,
  fullWidth,
  endAdornment,
  ...props
}: StringInputHookProps<T>) {
  return (
    <Controller
      name={name}
      render={({ field, fieldState }) => {
        const error = fieldState.error?.message;
        const { onChange, onBlur, value, ...rest } = field;
        const handleChange = (
          e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        ) => {
          let val = e.target.value;
          if (format) {
            val = format(val);
          }
          onChange(val);
        };

        return (
          <StringInput
            {...rest}
            {...props}
            value={value}
            onChange={handleChange}
            onBlur={onBlur}
            disabled={disabled}
            multiline={multiline}
            rows={multiline ? rows : undefined}
            width={width}
            fullWidth={fullWidth}
            endAdornment={endAdornment}
            error={error}
          />
        );
      }}
    />
  );
}

export default StringInputHook;
