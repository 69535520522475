import { memo } from 'react';
import {
  FieldValues,
  FieldPath,
  useController,
  useFormContext,
  get,
} from 'react-hook-form';

import CheckBoxInput, { CheckBoxInputProps } from './CheckBoxInput';

interface CheckBoxInputHookProps<T extends FieldValues>
  extends CheckBoxInputProps {
  name: FieldPath<T>;
}

function CheckBoxInputHook<T extends FieldValues = FieldValues>({
  name,
  paddingX,
  paddingY,
  width,
  gap,
  height,
  borderRadius,
  justifyContent,
  ...props
}: CheckBoxInputHookProps<T>) {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const { field } = useController({ name });
  const { onChange, value } = field;
  const error = get(errors, name);

  return (
    <CheckBoxInput
      id={name}
      name={name}
      value={value}
      onChange={onChange}
      error={error?.message}
      paddingX={paddingX}
      paddingY={paddingY}
      width={width}
      height={height}
      borderRadius={borderRadius}
      gap={gap}
      justifyContent={justifyContent}
      {...props}
      ref={register(name).ref}
    />
  );
}

export default memo(CheckBoxInputHook) as typeof CheckBoxInputHook;
