import { CustomGridColDef } from '@/hooks/useTable/types';

export enum ACTION_OPTIONS {
  EDIT = 'EDIT',
  DETAIL = 'DETAIL',
  PREVIEW = 'PREVIEW',
}

// interface IGenerateColumnProps {
// onAction: (option: ACTION_OPTIONS, row: CustomerInterface) => void;
// }

export const getTableColumns = (): CustomGridColDef[] => [
  {
    field: 'jobId',
    headerName: 'Job #',
    // minWidth: 60,
    flex: 1,
    sortable: true,
    hide: true,
    // link: params => `/jobs/${params.row.id}`,
  },
  {
    field: 'customerName',
    headerName: 'Customer Name',
    minWidth: 200,
    flex: 1,
    sortable: true,
    // link: params => `/jobs/${params.row.id}`,
  },
  {
    field: 'dateCreated',
    headerName: 'Date Created',
    type: 'date',
    minWidth: 110,
    flex: 1,
    sortable: false,
    renderCell: params => {
      const date = params.row.dateCreated;
      return date ? new Date(date).toLocaleDateString() : 'N/A';
    },
    // link: params => `/jobs/${params.row.id}`,
  },
  {
    field: 'scheduledFor',
    headerName: 'Scheduled for',
    type: 'date',
    minWidth: 110,
    flex: 1,
    sortable: false,
    renderCell: params => {
      const date = params.row.scheduledFor;
      return date ? new Date(date).toLocaleDateString() : 'N/A';
    },
    // link: params => `/jobs/${params.row.id}`,
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 120,
    flex: 1,
    sortable: false,
    // link: params => `/jobs/${params.row.id}`,
  },
  {
    field: 'jobDescription',
    headerName: 'Job Description',
    minWidth: 200,
    flex: 1,
    sortable: false,
    // link: params => `/jobs/${params.row.id}`,
  },
  {
    field: 'amount',
    headerName: 'Amount',
    minWidth: 60,
    flex: 1,
    sortable: false,
    // link: params => `/jobs/${params.row.id}`,
  },
];
