const SidebarClickIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <rect x="0.5" y="0.5" width="19" height="19" rx="3.5" stroke="#809088" />
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H8V20H4C1.79086 20 0 18.2091 0 16V4Z"
        fill="#809088"
      />
    </svg>
  );
};
export default SidebarClickIcon;
