import axios from 'axios';
import { useQuery } from 'react-query';
import { getUserApi } from './api';

export const useGetUser = () => {
  return useQuery(
    'user',
    async (): Promise<any> => {
      const response: any = await getUserApi();
      return response.data;
    },
    {
      onError: (error: any) => {
        if (axios.isAxiosError(error)) {
          console.error(
            'User fetch error:',
            error.response?.data || error.message,
          );
        } else {
          console.error('Unexpected error occurred');
        }
      },
    },
  );
};
