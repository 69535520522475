import React, { InputHTMLAttributes, ReactNode, forwardRef } from 'react';
import {
  Typography,
  FormControl,
  FormControlProps,
  FormLabelProps,
  TextFieldProps,
  FormLabel,
} from '@mui/material';
import StyledStringInput from './StringInput.styles';

export interface StringInputProps
  extends Omit<
    InputHTMLAttributes<HTMLInputElement>,
    'size' | 'color' | 'width'
  > {
  name: string;
  label?: string;
  error?: ReactNode;
  color?: TextFieldProps['color'];
  formControlProps?: FormControlProps;
  formLabelProps?: FormLabelProps;
  multiline?: boolean;
  endAdornment?: React.ReactNode;
  rows?: number;
  width?: string | Record<string, string>;
  fullWidth?: boolean;
}

const StringInput = forwardRef<HTMLInputElement, StringInputProps>(
  (
    {
      name,
      label,
      type = 'text',
      error,
      required,
      placeholder,
      color = 'primary',
      formControlProps,
      formLabelProps,
      multiline = false,
      rows = 4,
      width,
      endAdornment,
      fullWidth,
      ...props
    }: StringInputProps,
    ref,
  ) => {
    return (
      <FormControl {...formControlProps} required={required}>
        {label && (
          <FormLabel
            {...formLabelProps}
            sx={{
              color: '#4F4F4F',
              fontWeight: 700,
              fontSize: '16px',
              marginBottom: '8px',
            }}
          >
            {label}
          </FormLabel>
        )}
        <StyledStringInput
          name={name}
          type={type}
          variant="outlined"
          inputRef={ref}
          placeholder={placeholder}
          error={Boolean(error)}
          color={color}
          multiline={multiline}
          rows={multiline ? rows : undefined}
          width={width}
          fullWidth={fullWidth}
           sx={{
            width: width,
          }}
          InputProps={{
            endAdornment: endAdornment,
          }}
          {...props}
        />
        {error && (
          <Typography color="error" fontSize={14} paddingTop={0.2}>
            {error}
          </Typography>
        )}
      </FormControl>
    );
  },
);

export default StringInput;
