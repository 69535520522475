import { styled } from '@mui/system';
import { Box, Typography } from '@mui/material';

export const StyledMainContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '18px',

  paddingBottom: '28px',
});

export const StyledTypography = styled(Typography)({
  fontSize: '14px',
  paddingBottom: '16px',
});

export const StyledActionsContainer = styled(Box)({
  display: 'flex',
  flexDirection:'column',
  gap: '12px',
});

export const StyledInnerButtonGroup = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '12px',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  alignItems: 'flex-end',

  [theme.breakpoints.down('md')]: {
    paddingRight: '40px',
  },
  [theme.breakpoints.down('sm')]: {
    paddingRight: '0px',
  },
}));

export const StyledButtonGroup = styled(Box)({
  display: 'flex',
  justifyContent:'space-between',
  gap: '12px',
});
export const StyledTableContainer = styled(Box)({
  overflowX: 'auto',
  width: '100%',
  whiteSpace: 'nowrap',
  scrollbarWidth: 'none',
  display: 'block',
  // border: '1px solid #E0E0E0',
  boxSizing: 'border-box',
});
