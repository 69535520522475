import {
	IsEmail,
	IsNotEmpty,
	IsString,
	IsOptional,
	MinLength,
	Matches,
} from 'class-validator';

export enum ECompanyFields {
	Id = 'id',
	TenantId = 'tenantId',
	Name = 'name',
	ContactPerson = 'contactPerson',
	ContactEmail = 'contactEmail',
	Address = 'address',
	ContactPhone = 'contactPhone',
	Status = 'status',
	CreatedAt = 'createdAt',
	UpdatedAt = 'updatedAt',
}

export enum ECompanyStatus {
	Active = 'ACTIVE',
	Inactive = 'INACTIVE',
}

export interface CompanyInterface {
	id: string;
	tenantId: string;
	name: string;
	contactPerson: string;
	contactEmail: string;
	address?: string;
	contactPhone?: string;
	status: ECompanyStatus;
	createdAt?: Date;
	updatedAt?: Date;
}

export interface CreateCompanyInterface {
	name: string;
	contactPerson: string;
	contactEmail: string;
	address?: string;
	contactPhone?: string;
	password: string;
}

export const COMPANY_PROFILLE_DEFAULT_VALUES = {
	name: '',
	contactPerson: '',
	contactEmail: '',
	address: '',
	contactPhone: '',
};

export class CreateCompanyValidation implements CreateCompanyInterface {
	@IsString({ message: 'Company name is required!' })
	@IsNotEmpty({ message: 'Company name is required!' })
	name: string;

	@IsString({ message: 'Manager name is required!' })
	@IsNotEmpty({ message: 'Manager name is required!' })
	contactPerson: string;

	@IsEmail(undefined, { message: 'Manager email is not valid!' })
	@IsNotEmpty({ message: 'Manager email is required!' })
	contactEmail: string;

	@IsOptional()
	@IsString({ message: 'Address is required!' })
	address: string;

	@IsString({ message: 'Phone number is required!' })
	@IsNotEmpty({ message: 'Phone number is required!' })
	@Matches(/^\(\d{3}\) \d{3}-\d{4}$/, {
		message: 'Phone number format must be (xxx) xxx-xxxx',
	})
	contactPhone: string;

	@IsString({ message: 'Invalid password format!' })
	@MinLength(8, { message: 'Password must be at least 8 characters!' })
	@IsNotEmpty({ message: 'Password is required!' })
	@Matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).+$/, {
		message: 'Please create strong password!',
	})
	password: string;
}

export interface UpdateCompanyInterface
	extends Omit<CreateCompanyInterface, 'password' | 'contactEmail'> {
	contactEmail?: string;
}

export class UpdateCompanyProfileValidation implements UpdateCompanyInterface {
	@IsString({ message: 'Company name is required!' })
	@IsNotEmpty({ message: 'Company name is required!' })
	name: string;

	@IsString({ message: 'Manager name is required!' })
	@IsNotEmpty({ message: 'Manager name is required!' })
	contactPerson: string;

	@IsOptional()
	@IsString({ message: 'Address is required!' })
	address: string;

	@IsString({ message: 'Phone number is required!' })
	@IsNotEmpty({ message: 'Phone number is required!' })
	@Matches(/^\(\d{3}\) \d{3}-\d{4}$/, {
		message: 'Phone number format must be (xxx) xxx-xxxx',
	})
	contactPhone: string;
}
