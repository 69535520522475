import { Box } from '@mui/material';

import { SchedulingListing } from './parts';

const Scheduling = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '48px',
        padding: '30px',
        height: '100%',
      }}
    >
      <SchedulingListing />
    </Box>
  );
};

export default Scheduling;
