const PlenumIcon = () => {
  return (
    <svg
      width="28"
      height="33"
      viewBox="0 0 28 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6739_6558)">
        <path
          d="M18.0789 12.4159V6.20801H3.5V20.5843H9.79645V12.4159H18.0789Z"
          fill="white"
        />
        <path
          d="M3.5 32.9999H9.79645V29.4725V26.792H3.5V32.9999Z"
          fill="white"
        />
        <path
          d="M18.0789 6.20792H24.3753V2.68083V0H18.0789V6.20792Z"
          fill="white"
        />
        <path
          d="M18.0788 12.416V20.5843H9.79639V26.7923H24.3753V12.416H18.0788Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_6739_6558">
          <rect
            width="21"
            height="33"
            fill="white"
            transform="translate(3.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PlenumIcon;
