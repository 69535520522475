import { FC } from 'react';
import {
  StyledButtonGroup,
  StyledButtonSubGroup,
  StyledContainer,
  StyledTitle,
} from './CustomerProfile.styles';
import { AddIcon, ShareIcon } from '@/assets/icons';
import { PrimaryButton } from '@/components';

interface CustomerDetailProps{
  CustomerName:string,
}
const ProfileHeader:FC<CustomerDetailProps> = ({CustomerName}) => {
  return (
    <StyledContainer>
      <StyledTitle>{CustomerName}&apos;s Profile</StyledTitle>
      <StyledButtonGroup>
        <StyledButtonSubGroup>
          <PrimaryButton>
            <ShareIcon /> Share
          </PrimaryButton>
          <PrimaryButton>
            <AddIcon /> Job
          </PrimaryButton>
        </StyledButtonSubGroup>
        <StyledButtonSubGroup>
          <PrimaryButton>
            <AddIcon /> Estimate
          </PrimaryButton>
          <PrimaryButton>
            <AddIcon /> Service plan
          </PrimaryButton>
        </StyledButtonSubGroup>
      </StyledButtonGroup>
    </StyledContainer>
  );
};

export default ProfileHeader;
