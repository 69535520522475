import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import {
  StringInputHook,
  SecondaryButton,
  SelectHookInput,
} from '@/components';
import {
  CreateSubCategoryValidation,
  SubCategoryInterface,
} from '@plenumpro/shared';
import { useAddSubCategories, useFetchCategoriesOptions } from '@/services';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';

interface CategoryFormProps {
  onClose: () => void;
  onCreateSubCategory: (data: SubCategoryInterface) => Promise<void>;
}

const SubCategoryForm: FC<CategoryFormProps> = ({ onClose }) => {
  const formMethods = useForm<SubCategoryInterface>({
    resolver: classValidatorResolver(CreateSubCategoryValidation),
    defaultValues: { name: '', categoryId: '', description: '' },
    mode: 'all',
  });

  const { mutate: addSubCategory, isLoading } = useAddSubCategories();

  const { handleSubmit, reset } = formMethods;

  const { data: categories = [] } = useFetchCategoriesOptions();
  const onSubmit = async (data: SubCategoryInterface) => {
    addSubCategory(data, {
      onSuccess: () => {
        reset();
        onClose();
      },
    });
  };

  return (
    <FormProvider {...formMethods}>
      <Grid alignSelf={'center'} mt="32px" width="80%">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              gap: '16px',
            }}
          >
            <StringInputHook
              label="Name"
              name="name"
              placeholder="Name"
              formControlProps={{ fullWidth: true }}
            />
            <SelectHookInput
              label="Select Category"
              name="categoryId"
              options={categories}
              getValueAsObject={false}
              formControlProps={{ fullWidth: true }}
            />
            <StringInputHook
              label="Description"
              name="description"
              placeholder="Description"
              formControlProps={{ fullWidth: true }}
              multiline
            />
          </div>
          <SecondaryButton
            type="submit"
            variant="contained"
            loading={isLoading}
            sx={{ width: '100%', marginTop: '20px', marginBottom: '50px' }}
          >
            Create SubCategory
          </SecondaryButton>
        </form>
      </Grid>
    </FormProvider>
  );
};

export default SubCategoryForm;
