import { Navigate, Outlet } from 'react-router-dom';

import { SignInPage, NotFoundPage, CreateCompany } from '@/pages';

interface PublicRoutesProps {
  isAuthenticated: boolean;
}
const PublicRoutes = ({ isAuthenticated }: PublicRoutesProps) => {
  return {
    element: !isAuthenticated ? <Outlet /> : <Navigate to="/dashboard" />,
    children: [
      { path: 'signin', element: <SignInPage /> },
      { path: 'create-company', element: <CreateCompany /> },
      { path: '*', element: <NotFoundPage /> },
    ],
  };
};

export default PublicRoutes;
