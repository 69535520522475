import { useReducer, Reducer } from 'react';
import { ESortBy, IPaginationModel, ISortModel, ITableProps } from './types';
import CustomTable from './CustomTable';

type RecordKey = string | number;

export { ESortBy };

export interface IUseTableState<T extends RecordKey> {
  filter?: Partial<Record<T, unknown>>;
  sort?: Partial<Record<T, ESortBy>>;
  page?: number;
  itemsPerPage?: number;
  selectedIds?: RecordKey[];
}

export enum IUseTableActionTypes {
  SET_FILTER = 'SET_FILTER',
  REMOVE_FILTER = 'REMOVE_FILTER',
  SET_SORT = 'SET_SORT',
  SET_PAGE = 'SET_PAGE',
  SET_ITEMS_PER_PAGE = 'SET_ITEMS_PER_PAGE',
  SET_SELECTED_IDS = 'SET_SELECTED_IDS',
}

export type IUseTableActionPayload<T extends RecordKey> =
  | IUseTableState<T>['filter']
  | IUseTableState<T>['page']
  | IUseTableState<T>['sort']
  | IUseTableState<T>['itemsPerPage']
  | IUseTableState<T>['selectedIds'];

export interface IUseTableAction<T extends RecordKey> {
  type: IUseTableActionTypes;
  payload: IUseTableActionPayload<T>;
}

function useTableReducer<T extends RecordKey>(
  state: IUseTableState<T>,
  action: IUseTableAction<T>,
): IUseTableState<T> {
  switch (action.type) {
    case IUseTableActionTypes.SET_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...(action.payload as IUseTableState<T>['filter']),
        } as IUseTableState<T>['filter'],
      };
    case IUseTableActionTypes.REMOVE_FILTER:
      const filteredState = { ...state.filter } as Record<string, any>;
      const keys: string[] = Object.keys(filteredState);
      delete filteredState[keys[0]];
      return {
        ...state,
        filter: {
          ...filteredState,
        } as IUseTableState<T>['filter'],
      };
    case IUseTableActionTypes.SET_SORT:
      return {
        ...state,
        sort: {
          ...(action.payload as IUseTableState<T>['sort']),
        },
      };
    case IUseTableActionTypes.SET_PAGE:
      return {
        ...state,
        page: action.payload as IUseTableState<T>['page'],
      };
    case IUseTableActionTypes.SET_ITEMS_PER_PAGE:
      return {
        ...state,
        itemsPerPage: action.payload as IUseTableState<T>['itemsPerPage'],
      };
    case IUseTableActionTypes.SET_SELECTED_IDS:
      return {
        ...state,
        selectedIds: action.payload as IUseTableState<T>['selectedIds'],
      };
    default:
      return state;
  }
}

interface IUseTableProps<T extends RecordKey> {
  initialState?: IUseTableState<T>;
}

export function useTableHook<T extends RecordKey>(props?: IUseTableProps<T>) {
  const { initialState = {} } = props ?? {};
  const [state, dispatch] = useReducer<
    Reducer<IUseTableState<T>, IUseTableAction<T>>
  >(useTableReducer, initialState);

  const tableProps: ITableProps = {
    onSortModelChange: (newModel: ISortModel[]) => {
      dispatch({
        type: IUseTableActionTypes.SET_SORT,
        payload: newModel.reduce(
          (prev, curr) => ({
            ...prev,
            [curr.field]: curr.sort === 'asc' ? ESortBy.ASC : ESortBy.DESC,
          }),
          {},
        ),
      });
    },
    paginationModel: {
      pageSize: state.itemsPerPage ?? 10,
      page: state.page ?? 0,
    },
    onPaginationModelChange: (model: IPaginationModel) => {
      if (model.pageSize !== state.itemsPerPage) {
        dispatch({
          type: IUseTableActionTypes.SET_ITEMS_PER_PAGE,
          payload: model.pageSize,
        });
      }
      if (model.page !== state.page) {
        dispatch({
          type: IUseTableActionTypes.SET_PAGE,
          payload: model.page,
        });
      }
    },

    onCheckboxChange: (
      id?: number | string,
      event?: React.ChangeEvent<HTMLInputElement>,
      rowsIds?: (string | number)[],
      deselectAll?: boolean,
    ) => {
      const ids = state.selectedIds ?? [];
      let newIds = [...ids];
      if (id)
        newIds = ids.includes(id) ? ids.filter(i => i !== id) : [...ids, id];
      else if (event?.target.checked)
        newIds = deselectAll ? [] : (rowsIds ?? []);
      else newIds = [];
      dispatch({
        type: IUseTableActionTypes.SET_SELECTED_IDS,
        payload: newIds,
      });
    },
    selectedIds: state.selectedIds,
  };

  return { state, dispatch, tableProps, CustomTable };
}
