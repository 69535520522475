import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import { getEmployees } from './api';
import { ProfessionalInterface } from '@plenumpro/shared';

// export const useGetEmployees = () => {
//   return useQuery('professionals', getEmployees, {
//     staleTime: 5 * 60 * 1000,
//     onError: (error: AxiosError) => {
//       console.error('Error fetching professionals:', error.message);
//     },
//   });
// };

export const useGetEmployees = () => {
  return useQuery<ProfessionalInterface[], AxiosError>(
    'professionals',
    getEmployees,
    {
      staleTime: 5 * 60 * 1000,
      onError: error => {
        console.error('Error fetching professionals:', error.message);
      },
    },
  );
};
