import { FC } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { ModalDialog } from '@/components/common';
import ServiceForm from './ServiceForm';
import { ServiceInterface } from '@plenumpro/shared';
import { toast } from 'material-react-toastify';

interface CreateServiceProps {
  open: boolean;
  onClose: () => void;
  payload?: string;
}

const CreateService: FC<CreateServiceProps> = ({
  open,
  onClose,
  payload = 'USD',
}) => {
  const formMethods = useForm<ServiceInterface>();
  const handleFormError = (error: string) => {
    toast.error(`Error: ${error}`);
  };

  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      title="Create Service"
      width="500px"
    >
      <FormProvider {...formMethods}>
        <ServiceForm
          onClose={onClose}
          onError={handleFormError}
          currency={payload}
        />
      </FormProvider>
    </ModalDialog>
  );
};

export default CreateService;
