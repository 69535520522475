import { FC, memo, useState, VFC } from 'react';
import {
  Grid,
  FormLabel,
  FormHelperText,
  FormControlProps,
  OutlinedInputProps,
  FormHelperTextProps,
  FormLabelProps,
  TextFieldProps,
  Paper,
  Autocomplete,
  AutocompleteProps,
  TextField,
  Button,
  Typography,
  PaperProps,
} from '@mui/material';

import StylesWrapper from './SelectInput.styled';

export interface SelectInputOption {
  label: string;
  value: string;
}

export interface SelectInputProps
  extends Omit<FormControlProps, 'label' | 'error' | 'onChange' | 'value'> {
  label?: string;
  id?: string;
  required?: FormControlProps['required'];
  helperText?: string;
  options: SelectInputOption[];
  value: SelectInputOption;
  onChange: (
    val?: SelectInputOption | SelectInputOption['value'] | null,
  ) => void;
  onInputChange?: AutocompleteProps<
    string,
    boolean,
    boolean,
    boolean
  >['onInputChange'];
  getValueAsObject?: boolean;
  /**
   * Error component to be rendered below
   */
  errorMessage?: string;

  // These props provide you the option to modify the component but will unlikely be used
  inputProps?: Omit<OutlinedInputProps, 'type'>;
  formControlProps?: FormControlProps;
  formLabelProps?: FormLabelProps;
  outlinedInputProps?: OutlinedInputProps;
  textFieldProps?: TextFieldProps;
  formHelperTextProps?: FormHelperTextProps;
  filterOptions?: (options: SelectInputOption[]) => SelectInputOption[];
  fullwidth?: boolean;
  createNew?: boolean;
  handleCreate?: (value: string) => void;
  noOptionsText?: string;
}

const valueGuard = (val: unknown): val is SelectInputOption => {
  if (
    typeof val === 'object' &&
    val !== null &&
    'value' in val &&
    'label' in val
  ) {
    return true;
  }
  return false;
};

export const SelectInput: VFC<SelectInputProps> = ({
  errorMessage,
  formControlProps,
  formLabelProps,
  getValueAsObject = true,
  helperText,
  label,
  id,
  onChange,
  onInputChange,
  options,
  filterOptions,
  required,
  textFieldProps,
  value,
  disabled,
  fullwidth = false,
  createNew = false,
  handleCreate,
  noOptionsText = 'No options',
}) => {
  const hasError = !!errorMessage;
  const [inputValue, setInputValue] = useState('');

  const getParsedValue = () => {
    if (!value) {
      return null;
    }
    if (valueGuard(value)) {
      return value;
    }
    return options.find(
      ({ value: val }) => val === (value as SelectInputOption['value']),
    );
  };

  const PaperComponentCustom: FC<PaperProps> = ({ children }) => {
    const handleCreateButtonClick = () => {
      if (!inputValue) return;
      if (handleCreate) handleCreate(inputValue);
    };
    return (
      <Paper elevation={3} sx={{ mt: 1 }}>
        {children}
        {createNew && (
          <Button
            fullWidth
            color="primary"
            onMouseDown={event => {
              event.preventDefault();
              handleCreateButtonClick();
            }}
          >
            <Typography>{`New ${label}`}</Typography>
          </Button>
        )}
      </Paper>
    );
  };

  return (
    <StylesWrapper
      fullWidth={fullwidth}
      error={hasError}
      id={id}
      {...formControlProps}
    >
      <Grid container sx={{ mb: 1 }}>
        <Grid item>
          {label && (
            <FormLabel
              {...formLabelProps}
              sx={{
                color: '#4F4F4F',
                fontWeight: 700,
                fontSize: '16px',
                marginBottom: '8px',
              }}
            >
              {label}
            </FormLabel>
          )}
        </Grid>
        <Grid item>
          {/* This is a seperate helper text not === to error hence the error={false} */}
          <FormHelperText error={false}>{helperText}</FormHelperText>
        </Grid>
      </Grid>
      <Autocomplete
        autoComplete
        options={options}
        value={getParsedValue()}
        onChange={(e, newValue) => {
          onChange(getValueAsObject ? newValue : newValue?.value);
        }}
        PaperComponent={PaperComponentCustom}
        noOptionsText={noOptionsText}
        disabled={disabled}
        renderInput={params => (
          <TextField
            variant="outlined"
            placeholder="Select option"
            sx={{
              borderRadius: '4px',
              fontSize: '16px',
              lineHeight: '22.4px',
              backgroundColor: '#FFFFFF',
            }}
            error={hasError}
            {...params}
            {...textFieldProps}
          />
        )}
        size="small"
        filterOptions={filterOptions}
        onInputChange={(event, newInputValue, reason) => {
          setInputValue(newInputValue);
          if (onInputChange) {
            onInputChange(event, newInputValue, reason);
          }
        }}
      />
      <FormHelperText sx={{ mx: 0 }}>{errorMessage}</FormHelperText>
    </StylesWrapper>
  );
};

export default memo(SelectInput) as typeof SelectInput;
