import {
  Menu,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
} from '@mui/material';

const StyledMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    marginTop: '5px',
    width: '212px',
    borderRadius: '12px',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    [theme.breakpoints.down('md')]: {
      width: '180px',
    },
  },
  '& .MuiMenu-list': {
    padding: '0 0',
  },
}));

const StyledList = styled(List)({
  padding: 0,
  margin: 0,
});

const StyledListItem = styled(ListItem, {
  shouldForwardProp: prop => prop !== 'isFirst' && prop !== 'isLast',
})<{ isFirst?: boolean; isLast?: boolean }>(({ isFirst, isLast }) => ({
  padding: '12px 16px',
  gap: '12px', 
  borderRadius: isFirst
    ? '12px 12px 0 0'
    : isLast
    ? '0 0 12px 12px'
    : undefined,
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#F5F5F5',
  },
}));

const StyledListItemIcon = styled(ListItemIcon)({
  color: ' #BDBDBD',
  minWidth: '0',
});

const StyledListItemText = styled(ListItemText)({
  color: '#333',
});

export {
  StyledMenu,
  StyledList,
  StyledListItem,
  StyledListItemIcon,
  StyledListItemText,
};
