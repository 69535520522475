import * as React from 'react';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { Box, Paper, IconButton, Typography } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import {
  StringInputHook,
  SecondaryButton,
  ResponsiveFieldWrapper,
} from '@/components';
import {
  CreateCompanyValidation,
  CreateCompanyInterface,
} from '@plenumpro/shared';
import { useCreateCompany } from '@/services';
import { PlenumLogo } from '@/assets/icons';
import { formatPhoneNumber } from '@/utils';
import { useNavigate } from 'react-router-dom';

const CreateCompany: React.FC = () => {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const methods = useForm<CreateCompanyInterface>({
    resolver: classValidatorResolver(CreateCompanyValidation),
    mode: 'all',
    defaultValues: {
      name: '',
      contactPerson: '',
      contactEmail: '',
      address: '',
      contactPhone: '',
      password: '',
    },
  });

  const { handleSubmit, formState } = methods;
  const { mutate: createCompany, isLoading } = useCreateCompany();

  const onSubmit = async (data: CreateCompanyInterface) => {
    createCompany(data);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        paddingY: '30px',
      }}
    >
      <Paper
        sx={{
          borderRadius: '25px',
        }}
      >
        <Box
          sx={{
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            height: '100px',
            backgroundColor: theme => theme.palette.backgroundCustom?.main,
            borderRadius: '25px 25px 0 25px',
            '::before': {
              content: '""',
              position: 'absolute',
              backgroundColor: 'transparent',
              bottom: '-50px',
              right: 0,
              height: '50px',
              width: '25px',
              borderTopRightRadius: '25px',
              boxShadow: theme =>
                `0 -25px 0 0 ${theme.palette.backgroundCustom?.main}`,
            },
          }}
        >
          <PlenumLogo />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 5,
            pb: 6,
          }}
        >
          <FormProvider {...methods}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              noValidate
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '20px',
              }}
            >
              <StringInputHook
                name="name"
                type="text"
                placeholder="Company Name"
                required
                autoComplete="name"
                label="Company Name"
                formControlProps={{ fullWidth: true }}
              />
              <StringInputHook
                name="address"
                type="text"
                placeholder="Company Address"
                autoComplete="address"
                label="Company Address"
                formControlProps={{ fullWidth: true }}
              />

              <Box
                display="flex"
                flexDirection={{ xs: 'column', md: 'row' }}
                flexWrap="wrap"
                gap={3}
                width="100%"
              >
                <ResponsiveFieldWrapper>
                  <StringInputHook
                    name="contactPerson"
                    type="text"
                    placeholder="Manager Name"
                    required
                    autoComplete="contactPerson"
                    label="Manager Name"
                    formControlProps={{ fullWidth: true }}
                  />
                </ResponsiveFieldWrapper>
                <ResponsiveFieldWrapper>
                  <StringInputHook
                    placeholder="(303) 210-0000"
                    format={formatPhoneNumber}
                    type="tel"
                    required
                    name="contactPhone"
                    label="Phone"
                    formControlProps={{ fullWidth: true }}
                  />
                </ResponsiveFieldWrapper>
              </Box>
              <Box
                display="flex"
                flexDirection={{ xs: 'column', md: 'row' }}
                flexWrap="wrap"
                gap={3}
                width="100%"
              >
                <ResponsiveFieldWrapper>
                  <StringInputHook
                    name="contactEmail"
                    type="text"
                    placeholder="Email"
                    required
                    autoComplete="contactEmail"
                    label="Email"
                    formControlProps={{ fullWidth: true }}
                  />
                </ResponsiveFieldWrapper>
                <ResponsiveFieldWrapper>
                  <StringInputHook
                    name="password"
                    required
                    type={showPassword ? 'text' : 'password'}
                    autoComplete="current-password"
                    label="Password"
                    placeholder="Password"
                    formControlProps={{ fullWidth: true }}
                    endAdornment={
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    }
                  />
                </ResponsiveFieldWrapper>
              </Box>

              <Box
                mt={3}
                display="flex"
                justifyContent={{ xs: 'center', md: 'flex-end' }}
                width="100%"
              >
                <SecondaryButton
                  type="submit"
                  loading={isLoading}
                  disabled={formState.isSubmitting}
                  sx={{
                    width: { xs: '100%', md: '200px' },
                  }}
                >
                  REGISTER
                </SecondaryButton>
              </Box>
              <Typography variant="body2" sx={{ mt: 2 }}>
                Already have a company?{' '}
                <Box
                  component="span"
                  sx={{
                    color: 'secondary.main',
                    cursor: 'pointer',
                    textDecoration: 'underline',
                  }}
                  onClick={() => navigate('/signin')}
                >
                  Log in
                </Box>
              </Typography>
            </form>
          </FormProvider>
        </Box>
      </Paper>
    </Box>
  );
};

export default CreateCompany;
