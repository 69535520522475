import { Box, styled } from '@mui/material';

interface StyledMainProps {
  open: boolean;
}

export const StyledContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  minHeight: '100vh',
  backgroundColor: theme.palette.grey[500],
  [theme.breakpoints.down('md')]: {
    paddingLeft: 0,
  },
}));

export const StyledMain = styled(Box)<StyledMainProps>(({ open, theme }) => ({
  flexGrow: 1,
  overflowY: 'auto',
  paddingTop: '100px',
  // marginRight: '20px',
  transition: 'margin-left 0.3s ease',
  marginLeft: open ? '240px' : '66px',
  [theme.breakpoints.down('md')]: {
    marginLeft: 0,
    paddingTop: '65px',
  },
}));

export const calendarLoading = {
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};
