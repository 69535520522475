const PhoneIcon = () => {
  return (
    <svg
      width="9"
      height="14"
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginLeft: '1px' }}
    >
      <path
        d="M8.3335 12.667H1.66683V3.33366H8.3335M8.3335 0.666992H1.66683C0.926829 0.666992 0.333496 1.26033 0.333496 2.00033V14.0003C0.333496 14.3539 0.473972 14.6931 0.72402 14.9431C0.974069 15.1932 1.31321 15.3337 1.66683 15.3337H8.3335C8.68712 15.3337 9.02626 15.1932 9.2763 14.9431C9.52635 14.6931 9.66683 14.3539 9.66683 14.0003V2.00033C9.66683 1.6467 9.52635 1.30757 9.2763 1.05752C9.02626 0.807468 8.68712 0.666992 8.3335 0.666992Z"
        fill="#828282"
      />
    </svg>
  );
};

export default PhoneIcon;
