const HomeIcon = () => {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginLeft: '-2px' }}
    >
      <path
        d="M14.7889 6.89022L8.35293 0.458972C8.30659 0.41254 8.25155 0.375704 8.19095 0.35057C8.13036 0.325437 8.0654 0.3125 7.9998 0.3125C7.93421 0.3125 7.86925 0.325437 7.80866 0.35057C7.74806 0.375704 7.69302 0.41254 7.64668 0.458972L1.21074 6.89022C1.02324 7.07772 0.916992 7.33241 0.916992 7.59803C0.916992 8.1496 1.36543 8.59803 1.91699 8.59803H2.59512V13.1871C2.59512 13.4637 2.81855 13.6871 3.09512 13.6871H6.9998V10.1871H8.7498V13.6871H12.9045C13.1811 13.6871 13.4045 13.4637 13.4045 13.1871V8.59803H14.0826C14.3482 8.59803 14.6029 8.49335 14.7904 8.30428C15.1795 7.91366 15.1795 7.28085 14.7889 6.89022Z"
        fill="#828282"
      />
    </svg>
  );
};

export default HomeIcon;
