import { Avatar, Box, IconButton, Typography } from '@mui/material';

import {
  ContentBoxStyled,
  SectionStyled,
  TypographyStyled,
  BoxStyled,
  StyledProfileBox,
  StyledIconButton,
} from './DetailPanelContent.styles';

const DetailPanelContent = ({ row }: { row: any }) => {
  return (
    <ContentBoxStyled>
      <BoxStyled>
        <SectionStyled>
          <TypographyStyled>Services</TypographyStyled>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            {row?.services?.map((service: string, index: number) => (
              <Typography key={index} variant="subtitle1">
                {service}
              </Typography>
            ))}
          </Box>
        </SectionStyled>

        <SectionStyled>
          <TypographyStyled>Address</TypographyStyled>
          <Typography variant="subtitle1">{row?.address}</Typography>
          <Typography variant="body1" color="secondary.main">
            See on map
          </Typography>
        </SectionStyled>

        <SectionStyled noBorder>
          <TypographyStyled>Employees</TypographyStyled>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            {row?.employees?.map((employee: string, index: number) => (
              <StyledProfileBox>
                <StyledIconButton>
                  <Avatar />
                </StyledIconButton>
                <Typography key={index} variant="subtitle1">
                  {employee}
                </Typography>
              </StyledProfileBox>
            ))}
          </Box>
        </SectionStyled>
      </BoxStyled>
    </ContentBoxStyled>
  );
};

export default DetailPanelContent;
