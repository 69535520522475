import {
  Card,
  CardContent,
  Typography,
  Box,
  Divider,
  IconButton,
  Link,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import WorkIcon from '@mui/icons-material/Work';
import { StyledCard, StyledCardHeader } from './Card.styles';
import CreateJob from '@/components/Jobs/CreateJob';
import useToggle from '@/hooks/useToggle';

const JobsCard = () => {
  const createJobToggle = useToggle();

  return (
    <StyledCard>
      <CardContent>
        <StyledCardHeader>
          <Box display="flex" alignItems="center">
            <WorkIcon sx={{ color: '#BDBDBD', mr: 1 }} />
            <Typography variant="h6" sx={{ fontWeight: 600 }}>
              Jobs
            </Typography>
          </Box>
          <IconButton
            onClick={() => createJobToggle.setToOpen()}
            sx={{ m: 0, p: 0 }}
          >
            <AddIcon
              sx={{
                color: 'green',
                border: '1px solid green',
                borderRadius: 1,
              }}
            />
          </IconButton>
        </StyledCardHeader>

        <Divider />

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={2}
        >
          <Box>
            <Typography variant="h4" sx={{ fontWeight: 600 }}>
              4
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Jobs lined up today
            </Typography>
          </Box>
          <IconButton size="small">
            <ArrowForwardIosIcon />
          </IconButton>
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={2}
        >
          <Box>
            <Typography variant="h4" sx={{ fontWeight: 600 }}>
              25
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Open Jobs
            </Typography>
          </Box>
          <IconButton size="small">
            <ArrowForwardIosIcon />
          </IconButton>
        </Box>

        <Box mt={8}>
          <Link
            href="/jobs"
            underline="hover"
            sx={{ color: 'green', fontWeight: 400, fontSize: 14 }}
          >
            {` See all jobs >`}
          </Link>
        </Box>
      </CardContent>
      <CreateJob
        open={createJobToggle.isOpen}
        onClose={createJobToggle.setToClose}
        onCreateJob={() => {}}
      />
    </StyledCard>
  );
};

export default JobsCard;
