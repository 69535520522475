import { IconButton, useMediaQuery, useTheme } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import { StyledAvatar, StyledContainer } from './MenuList.styles';
import { PrimaryButton, SelectInput } from '@/components';
import { HEADER_MENU_ITEMS } from '@/utils/constants';
import { PROFILE_LINK_ITEMS } from '@/utils/constants/selectInputItems';
import useToggle from '@/hooks/useToggle';
import CreateJob from '@/components/Jobs/CreateJob';
import { CreateEstimate } from '@/components/Estimates';
import './MenuList.styles.css';
import { useLogoutUser } from '@/services';
import NewCreateCustomer from '@/components/NewCustomers/parts/CreateCustomer';

const MenuList = () => {
  const { mutate: logout } = useLogoutUser();
  const theme = useTheme();
  const navigate = useNavigate();
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));
  const createJobToggle = useToggle();
  const createEstimateToggle = useToggle();
  const createCustomerToggle = useToggle();
  const handleMenuItemClick = (item: any) => {
    if (item.text === 'Customer') createCustomerToggle.setToOpen();
    if (item.text === 'Estimate') createEstimateToggle.setToOpen();
    if (item.text === 'Job') createJobToggle.setToOpen();
  };

  return (
    <StyledContainer>
      <SelectInput options={HEADER_MENU_ITEMS} onClick={handleMenuItemClick}>
        <PrimaryButton
          sx={{
            height: '48px',
          }}
          color="primary"
          variant="contained"
          borderWidth="0"
        >
          <AddIcon />
          {!isMedium && ' New'}
        </PrimaryButton>
      </SelectInput>
      <SelectInput options={PROFILE_LINK_ITEMS} onClick={() => logout()}>
        <IconButton color="inherit">
          <StyledAvatar
            alt="Profile Picture"
            src="/path/to/profile/picture.jpg"
          />
        </IconButton>
      </SelectInput>

      <CreateJob
        open={createJobToggle.isOpen}
        onClose={createJobToggle.setToClose}
        onCreateJob={() => {}}
      />
      <CreateEstimate
        open={createEstimateToggle.isOpen}
        onClose={createEstimateToggle.setToClose}
        onCreateEstimate={() => {}}
      />
      <NewCreateCustomer
        open={createCustomerToggle.isOpen}
        onClose={createCustomerToggle.setToClose}
      />
    </StyledContainer>
  );
};

export default MenuList;
