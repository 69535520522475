export const AUTH0_NAMESPACE = 'https://plenum.com/';

export enum EAuth0Roles {
	SupperAdmin = 'rol_WtUoYxo6CS6OorvP',
	Admin = 'rol_lJdJW6nL8P3HKpqv',
	Professional = 'rol_ROq1kKf9ncYUec1N',
	Cutomer = 'rol_5gXYfJJhllgiPHlN',
}

export const PERMISSIONS_KEY = 'permissions';

export enum EPermissions {
	CREATE_PROFESSIONAL = 'create:professional',
	CREATE_SERVICE_CATALOG = 'create:service_catalog',
	READ_SERVICE_CATALOG = 'read:service_catalog',
	VIEW_DASHBOARD = 'view:dashboard',
	VIEW_INBOX = 'view:inbox',
	MANAGE_INBOX = 'manage:inbox',
	VIEW_CALENDAR = 'view:calendar',
	MANAGE_CALENDAR = 'manage:calendar',
	VIEW_CUSTOMERS = 'view:customers',
	MANAGE_CUSTOMERS = 'manage:customers',
	// VIEW_JOBS = 'view:jobs',
	// MANAGE_JOBS = 'manage:jobs',
	// VIEW_ESTIMATES = 'view:estimates',
	// MANAGE_ESTIMATES = 'manage:estimates',
	// VIEW_INVOICES = 'view:invoices',
	// MANAGE_INVOICES = 'manage:invoices',
	// VIEW_FINANCIALS = 'view:financials',
	// MANAGE_FINANCIALS = 'manage:financials',
	// VIEW_REPORTS = 'view:reports',
	// MANAGE_REPORTS = 'manage:reports',
	VIEW_SETTINGS = 'view:settings',
	MANAGE_SETTINGS = 'manage:settings',
	VIEW_EMPLOYEES = 'view:employees',
	MANAGE_EMPLOYEES = 'manage:employees',
	READ_SCHEDULING = 'read:scheduling',
}

export interface Auth0UserInterface {
	userMetadata: any | null;
	tenantId: string | undefined;
	email: string | undefined;
	name: string | undefined;
	nickname: string | undefined;
	picture: string | undefined;
	createdAt: string | undefined;
	role: string | undefined;
	sub: string | undefined;
	emailVerified: boolean | undefined;
	permissions?: EPermissions[] | undefined;
}

// pending create permission on auth0

// { permission: "view:jobs", description: "View job listings" },
// { permission: "manage:jobs", description: "Add, update, or delete jobs" },
// { permission: "view:estimates", description: "View estimates" },
// { permission: "manage:estimates", description: "Create, edit, or delete estimates" },
// { permission: "view:invoices", description: "View invoices" },
// { permission: "manage:invoices", description: "Create, edit, or delete invoices" },
// { permission: "view:financials", description: "View financial reports and information" },
// { permission: "manage:financials", description: "Manage financial data or reports" },
// { permission: "view:reports", description: "View reports and analytics" },
// { permission: "manage:reports", description: "Generate or delete reports" },
