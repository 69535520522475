import React from 'react';
import {
  Box,
  TablePagination,
  CircularProgress,
  TableCell,
  TableRow,
} from '@mui/material';

import { CustomGridColDef, DataRow, ITableProps } from '../types';

import TableRowComponent from './parts/TableRow';
import TableHeader from './parts/TableHeader';
import {
  StyledTable,
  StyledTableBody,
  StyledLoadingRow,
} from './CustomTable.styles';
import './CustomTable.style.css';
interface CustomTableProps extends ITableProps {
  rows: DataRow[];
  columns: CustomGridColDef[];
  checkboxSelection?: boolean;
  onDetailPanelContent?: (row: any) => React.ReactNode;
  loading?: boolean;
  detailPanelMaxHeight?: () => number;
  showFooter?: boolean;
  rowsPerPageOptions?: number[];
}

const CustomTable: React.FC<CustomTableProps> = ({
  rows,
  columns,
  checkboxSelection,
  onDetailPanelContent,
  loading = false,
  detailPanelMaxHeight,
  showFooter = true,
  rowsPerPageOptions = [10, 20, 50],
  ...props
}) => {
  const {
    onPaginationModelChange,
    paginationModel,
    onSortModelChange,
    onCheckboxChange,
    selectedIds,
  } = props;

  const handleSelectAllClick = (
    event: React.ChangeEvent<HTMLInputElement>,
    deselectAll: boolean,
  ) => {
    const rowIds = rows.map(n => n.id);
    onCheckboxChange(undefined, event, rowIds, deselectAll);
  };
  return (
    <Box>
      <StyledTable
        sx={{
          borderBottom: 'none',
          minHeight: loading ? 400 : 0,
          pr: 0,
          overflow: 'scroll',
        }}
        aria-label="customized table"
      >
        <TableHeader
          columns={columns}
          checkboxSelection={checkboxSelection}
          numSelected={selectedIds?.length ?? 0}
          rowCount={rows?.length ?? 0}
          onSelectAllClick={handleSelectAllClick}
          expand={!!onDetailPanelContent}
          onSortModelChange={onSortModelChange}
        />

        <StyledTableBody>
          {loading ? (
            <StyledLoadingRow>
              <TableCell colSpan={columns.length} align="center">
                <CircularProgress color="secondary" />
              </TableCell>
            </StyledLoadingRow>
          ) : rows?.length > 0 ? (
            rows.map(row => (
              <TableRowComponent
                key={row.id}
                row={row}
                columns={columns}
                isSelected={selectedIds && selectedIds.includes(row.id)}
                checkboxSelection={checkboxSelection}
                onCheckboxChange={id => onCheckboxChange(id)}
                onDetailPanelContent={onDetailPanelContent}
                {...(detailPanelMaxHeight && { detailPanelMaxHeight })}
              />
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} align="center">
                No data found
              </TableCell>
            </TableRow>
          )}
        </StyledTableBody>
      </StyledTable>

      {showFooter && (
        <Box
          sx={{
            position: 'fixed',
            right: 54,
          }}
        >
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={rows?.length ?? 0}
            rowsPerPage={paginationModel?.pageSize ?? 10}
            page={paginationModel?.page ?? 0}
            onPageChange={(event, newPage) => {
              onPaginationModelChange({
                page: newPage,
                pageSize: paginationModel?.pageSize,
              });
            }}
            onRowsPerPageChange={event => {
              const newSize = parseInt(event.target.value, 10);
              onPaginationModelChange({ page: 0, pageSize: newSize });
            }}
            disabled={loading}
          />
        </Box>
      )}
    </Box>
  );
};

export default CustomTable;
