import { TypographyVariantsOptions } from '@mui/material/styles';

const typographyVariants: TypographyVariantsOptions = {
  h1: {
    fontSize: '56px',
    lineHeight: '61.6px',
    fontWeight: 700,
  },
  h2: {
    fontSize: '48px',
    lineHeight: '52.8px',
    fontWeight: 700,
  },
  h3: {
    fontSize: '40px',
    lineHeight: '44px',
    fontWeight: 700,
  },
  h4: {
    fontSize: '32px',
    lineHeight: '35.2px',
    fontWeight: 700,
  },
  h5: {
    fontSize: '24px',
    lineHeight: '26.4px',
    fontWeight: 700,
  },
  h6: {
    fontSize: '20px',
    lineHeight: '22px',
    fontWeight: 600,
  },
  body1: {
    fontSize: '16px',
    lineHeight: '19.6px',
    fontWeight: 400,
  },
  body2: {
    fontSize: '14px',
    lineHeight: '19.6px',
    fontWeight: 400,
  },
  subtitle1: {
    fontSize: '16px',
    lineHeight: '19.6px',
    fontWeight: 400,
  },
  caption: {
    fontSize: '14px',
    lineHeight: '19.6px',
  },
};

export default typographyVariants;
