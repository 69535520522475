import {
  Box,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  ListItem,
  Drawer as MuiDrawer,
  styled,
  Typography,
} from '@mui/material';

interface StyledopenProps {
  open: boolean;
  path?: boolean;
}

const StyledDrawer = styled(MuiDrawer)<{ open: boolean }>(
  ({ open, theme }) => ({
    '& .MuiDrawer-paper': {
      width: open ? 240 : 65,
      transition: 'width 0.3s',
      backgroundColor: '#022213',
      color: '#FEFEFE',
      overflow: 'hidden',
      paddingTop: '12px',
      [theme.breakpoints.up('md')]: {
        paddingTop: '15px',
      },
    },
  }),
);

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  backgroundColor: theme.palette.backgroundCustom?.main,
  color: '#fff',
  ...theme.mixins.toolbar,
}));

const StyledListItemButton = styled(ListItemButton, {
  shouldForwardProp: prop => prop !== 'open' && prop !== 'path',
})<StyledopenProps>(({ open }) => ({
  minHeight: 48,
  paddingLeft: '20px',
  justifyContent: open ? 'center' : 'center',
}));

const StyledListItem = styled(ListItem)({
  display: 'block',
});

const StyledListItemIcon = styled(ListItemIcon, {
  shouldForwardProp: prop => prop !== 'open',
})<StyledopenProps>(({ open }) => ({
  minWidth: 0,
  marginRight: open ? '16px' : 'auto',
  justifyContent: 'center',
}));

const StyledListItemText = styled(ListItemText, {
  shouldForwardProp: prop => prop !== 'open' && prop !== 'path',
})<{ open?: boolean; path?: boolean }>(({ open, path }) => ({
  opacity: open ? 1 : 0,
  transition: 'opacity 0.3s ease',
  color: path ? '#279660' : '#828F87',
}));

const StyledTypography = styled(Typography, {
  shouldForwardProp: prop => prop !== 'path',
})<{ path?: boolean }>(({ path, theme }) => ({
  fontWeight: path ? 600 : 400,
  '&:hover': {
    color: theme.palette.secondary.main,
  },
}));

const StyledBox = styled(Box)<{ open?: boolean }>(({ open }) => ({
  ...(open && { marginLeft: '20px' }),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
}));

const StyledLogo = styled(Box)({
  height: 33,
  width: 'auto',
  marginRight: 'auto',
  marginLeft: 'auto',
  cursor: 'pointer',
});

export {
  StyledDrawer,
  DrawerHeader,
  StyledListItem,
  StyledListItemButton,
  StyledListItemIcon,
  StyledListItemText,
  StyledTypography,
  StyledLogo,
  StyledBox,
};
