import { useEffect, useState } from 'react';

import { IJobParams, IJob } from '@/utils';
import { getJobs } from '@/services';
import { JobListing } from '@/components';

const JobsListingPage = () => {
  const [jobs, setJobs] = useState<IJob[]>([]);
  const [laoding, setLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');

  useEffect(() => {
    getJobsData();
  }, []);

  const getJobsData = async (params?: IJobParams) => {
    try {
      setLoading(true);
      const newJobs = (await getJobs(params)) as unknown as IJob[];
      setJobs(newJobs);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const handleSearchChange = (text: string) => {
    setSearchText(text);
  };
  return (
    <JobListing
      loading={laoding}
      rows={jobs}
      refetchJobs={getJobsData}
      searchText={searchText}
      onSearchChange={handleSearchChange}
    />
  );
};

export default JobsListingPage;
