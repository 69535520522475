import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { ServiceInterface } from '@plenumpro/shared';
import { fetchAllServices } from './api';

export const useFetchAllServices = () => {
  return useQuery<ServiceInterface[], AxiosError>(
    'allServices',
    fetchAllServices,
    {
      staleTime: 5 * 60 * 1000,
      onError: (error: AxiosError) => {
        console.error('Error fetching sercices:', error.message);
      },
    },
  );
};
