import { FC } from 'react';

interface IconProps {
  isActive?: boolean;
  color?: string;
}

const JobsIcon: FC<IconProps> = ({ isActive = false, color }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0H12C12.5304 0 13.0391 0.210714 13.4142 0.585786C13.7893 0.960859 14 1.46957 14 2V4H18C18.5304 4 19.0391 4.21071 19.4142 4.58579C19.7893 4.96086 20 5.46957 20 6V17C20 17.5304 19.7893 18.0391 19.4142 18.4142C19.0391 18.7893 18.5304 19 18 19H2C1.46957 19 0.960859 18.7893 0.585786 18.4142C0.210714 18.0391 0 17.5304 0 17V6C0 4.89 0.89 4 2 4H6V2C6 0.89 6.89 0 8 0ZM12 4V2H8V4H12Z"
        fill={color || (isActive ? '#279660' : '#828F87')}
      />
    </svg>
  );
};

export default JobsIcon;
