import { useMutation, useQueryClient } from 'react-query';
import { createCustomer } from './api';
import { toast } from 'material-react-toastify';

export const useCreateCustomer = () => {
  const queryClient = useQueryClient();
  return useMutation(createCustomer, {
    onMutate: async newCustomer => {
      return { newCustomer };
    },
    onSuccess: () => {
      toast.success('Customer created successfully!');
      queryClient.invalidateQueries('customers');
    },
    onError: (error: any) => {
      toast.error(error.response.data.message);
      console.error('Error creating customer:', error);
    },
  });
};
