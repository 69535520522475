import { FC, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Grid, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';

import {
  StringInputHook,
  SecondaryButton,
  SelectHookInput,
} from '@/components';
import {
  CreateProfessionalInterface,
  EAuth0Roles,
  CreateProfessionalValidation,
  PROFESSIONAL_DEFAULTS_VALUES,
} from '@plenumpro/shared';
import { useCreateEmployee } from '@/services';

interface EmployeeFormProps {
  onClose: () => void;
}

const allowedRolesSet = new Set<EAuth0Roles>([
  EAuth0Roles.Admin,
  EAuth0Roles.Professional,
]);

const RolesOptions = Object.entries(EAuth0Roles)
  .filter(([roleKey, roleValue]) => allowedRolesSet.has(roleValue))
  .map(([roleKey, roleValue]) => ({ label: roleKey, value: roleValue }));

const EmployeeForm: FC<EmployeeFormProps> = ({ onClose }) => {
  const [showPassword, setShowPassword] = useState(false);

  const formMethods = useForm<CreateProfessionalInterface>({
    defaultValues: PROFESSIONAL_DEFAULTS_VALUES,
    resolver: classValidatorResolver(CreateProfessionalValidation),
    mode: 'all',
  });

  const { handleSubmit, reset } = formMethods;
  const { mutate: createEmployee, isLoading } = useCreateEmployee();
  const onSubmit = async (data: CreateProfessionalInterface) => {
    createEmployee(data, {
      onSuccess: () => {
        reset();
        onClose();
      },
    });
  };

  return (
    <>
      <FormProvider {...formMethods}>
        <Grid alignSelf={'center'} mt="32px">
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
          >
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                gap: '16px',
              }}
            >
              <StringInputHook label="Name" name="name" placeholder="Name" />
              <StringInputHook
                label="Email"
                name="email"
                placeholder="Email"
                fullWidth
              />
              <StringInputHook
                label="Working Hours"
                name="workingHours"
                type="number"
                format={value => String(value)}
                placeholder="Working Hours"
                max="24"
                fullWidth
              />
              <StringInputHook
                label="Day Start Time"
                name="dayStartTime"
                placeholder="Day Start Time"
                type="time"
                fullWidth
              />
              <StringInputHook
                label="Password"
                name="password"
                placeholder="Password"
                type={showPassword ? 'text' : 'password'}
                fullWidth
                endAdornment={
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                }
              />
              <SelectHookInput
                label="Select role"
                name="role"
                options={RolesOptions}
                getValueAsObject={false}
              />
            </Box>
            <SecondaryButton
              type="submit"
              variant="contained"
              loading={isLoading}
              sx={{ width: '100%', marginTop: '20px', marginBottom: '50px' }}
            >
              Create
            </SecondaryButton>
          </form>
        </Grid>
      </FormProvider>
    </>
  );
};

export default EmployeeForm;
