import { TagContainerStyled, TagTextStyled } from './Chip.styles';

interface IChip {
  text: string;
}

const Chip: React.FC<IChip> = ({ text }) => {
  return (
    <TagContainerStyled>
      <TagTextStyled>{text}</TagTextStyled>
    </TagContainerStyled>
  );
};
export default Chip;
