import { Box, Dialog, styled, Typography } from '@mui/material';

export const StyledDialog = styled(Dialog)(
  ({ width }: { width?: string }) => ({
    '& .MuiDialog-paper': {
      maxWidth: width || '1110px',
      width: '100%',
      marginRight: '16px',
      marginLeft: '16px',
      alignItems: 'center',
      overflow: 'auto',
    },
  }),
);

export const ContentWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '50px',
  height: '100%',
  [theme.breakpoints.up('md')]: {
    marginTop: '42px',
  },
}));

export const CloseButton = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '15px',
  right: '15px',
  border: `1px solid ${theme.palette.grey[50]}`,
  borderRadius: '4px',
  height: '34px',
  width: '34px',
  cursor: 'pointer',
  [theme.breakpoints.up('md')]: {
    top: '42px',
    right: '30px',
  },
}));

export const StyledTitleWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

export const StyledTitle = styled(Typography)(() => ({
  textAlign: 'center',
}));

export const StyledDescription = styled(Typography)(() => ({
  marginTop: '12px',
  textAlign: 'center',
  maxWidth: '440px',
}));

export const StyledChildren = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));
