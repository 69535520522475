import { Box } from '@mui/material';
import { useTableHook } from '@/hooks/useTable';
import { StyledTitle } from '../CustomerProfile.styles';
import { getTableColumns } from './prarts/tableColumns';

const EstimateTable = () => {
  const columns = getTableColumns();

  const { tableProps, CustomTable } = useTableHook<any>({
    initialState: {
      itemsPerPage: 50,
    },
  });

  return (
    <Box sx={{ flexGrow: 1 }}>
      <StyledTitle>Estimates</StyledTitle>
      <CustomTable
        {...tableProps}
        rows={[]}
        columns={columns}
        rowsPerPageOptions={[10, 15, 20, 50, 100]}
      />
    </Box>
  );
};

export default EstimateTable;
