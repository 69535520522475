import { IsNotEmpty, IsOptional, IsString, IsUUID } from 'class-validator';
import { ECategoryStatus } from './category';

export enum ESubCategoryFields {
	Id = 'id',
	Name = 'name',
	Description = 'description',
	Status = 'status',
	CreatedAt = 'createdAt',
	UpdatedAt = 'updatedAt',
	Categories = 'categories',
}
export interface SubCategoryInterface {
	id: string;
	name: string;
	description?: string;
	status: ECategoryStatus;
	categoryId: string;
	createdAt: Date;
	updatedAt: Date;
}

export class CreateSubCategoryValidation
	implements Partial<SubCategoryInterface>
{
	@IsNotEmpty({ message: 'Sub-Category name is required!' })
	@IsString({ message: 'Sub-Category name is required!' })
	name: string;

	@IsOptional()
	@IsString()
	description?: string;

	@IsNotEmpty({ message: 'Category is required!' })
	@IsUUID('4', { message: 'Category is required!' })
	categoryId: string;
}
