import { FC, useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import JobForm from './parts/JobForm';
import { TJobForm } from '@/utils';
import { ModalDialog } from '@/components/common';

interface CreateJobProps {
  open: boolean;
  onClose: () => void;
  onCreateJob: () => void;
}

const CreateJob: FC<CreateJobProps> = ({ open, onClose }) => {
  const formMethods = useForm();

  const selectJobLater = () => onClose();

  const handleCreateJob = (jobData: TJobForm) => {};

  return (
    <ModalDialog open={open} onClose={onClose} title="Create New Job">
      <FormProvider {...formMethods}>
        <JobForm
          onBack={selectJobLater}
          onCreateCustomer={handleCreateJob}
          onClose={onClose}
        />
      </FormProvider>
    </ModalDialog>
  );
};

export default CreateJob;
