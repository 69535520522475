import { styled } from '@mui/material/styles';
import { Avatar, Box } from '@mui/material';

export const StyledAvatar = styled(Avatar)(({ theme }) => ({
  height: '48px',
  width: '48px',
  background: theme.palette.primary.main,
  [theme.breakpoints.down('md')]: {
    height: '40px',
    width: '40px',
  },
}));

export const StyledContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '24px',

  [theme.breakpoints.down('md')]: {
    gap: '6px',
  },
}));
