import { styled } from '@mui/system';
import { Box, IconButton, Typography } from '@mui/material';
import { PrimaryButton } from '@/components/common';

interface SectionStyledProps {
  noBorder?: boolean;
}

export const ContentBoxStyled = styled(Box)<{ height?: number }>(
  ({ theme, height }) => ({
    maxHeight: height,
    transition: 'max-height 0.5s ease',
    backgroundColor: '#fff',
    overflow: 'hidden',
    padding: 0,
    margin: 0,
  }),
);

export const SectionStyled = styled(Box, {
  shouldForwardProp: prop => prop !== 'noBorder',
})<SectionStyledProps>(({ theme, noBorder }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  width: '30%',
  borderRight: noBorder ? 'none' : `1px solid ${theme.palette.grey[50]}`,
}));

export const TypographyStyled = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: 700,
}));

export const ButtonStyled = styled(PrimaryButton)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
}));

export const BoxStyled = styled(Box)(() => ({
  padding: '25px',
  display: 'flex',
  gap: '32px',
}));

export const StyledProfileBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
}));

export const StyledIconButton = styled(IconButton)(() => ({
  padding: 0,
  margin: 0,
}));
