import { z } from 'zod';

export const JobFormSchema = z.object({
  customerName: z
    .string({ message: 'Customer name is required' })
    .min(1, 'Customer name is required'),
  dateoptions: z
    .string({ message: 'Please select your date slot' })
    .min(1, 'Please select your date slot'),
  timeoptions: z
    .string({ message: 'Please select your time slot' })
    .min(1, 'Please select your time slot'),
  status: z
    .string({ message: 'Status is required' })
    .min(1, 'Status is required'),
  jobDescription: z
    .string({ message: 'Job description is required' })
    .min(1, 'Job description is required'),
  amount: z
    .string({ message: 'Amount is required' })
    .min(1, 'Amount is required'),
});
export type TJobForm = z.infer<typeof JobFormSchema>;
