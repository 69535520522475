import { styled, Box } from '@mui/material';

export const ContactInfoWrapper = styled(Box)(({ theme }) => ({
  maxWidth: '497px',
  minHeight: '366px',
  width: '100%',
  padding: '24px 14px',
  marginTop: '24px',
  borderBottom: `1px solid ${theme.palette.grey[50]}`,
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
}));

export const AddressWrapper = styled(Box)(({ theme }) => ({
  maxWidth: '497px',
  minHeight: '244px',
  padding: '0px 14px 24px 14px',
  marginTop: '24px',
  borderBottom: `1px solid ${theme.palette.grey[50]}`,
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
}));

export const ButtonsContainer = styled(Box)(({ theme }) => ({
  maxWidth: '582px',
  display: 'block',
  padding: '0px 14px',
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
  },
  justifyContent: 'space-between',
  paddingBottom: '15px',
  marginTop: '24px',
  gap: '12px',
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    paddingBottom: '53px',
  },
}));
