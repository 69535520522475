const SearchIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 19.0002L14.657 14.6572M14.657 14.6572C15.3998 13.9143 15.9891 13.0324 16.3912 12.0618C16.7932 11.0911 17.0002 10.0508 17.0002 9.00021C17.0002 7.9496 16.7932 6.90929 16.3912 5.93866C15.9891 4.96803 15.3998 4.08609 14.657 3.34321C13.9141 2.60032 13.0321 2.01103 12.0615 1.60898C11.0909 1.20693 10.0506 1 8.99996 1C7.94936 1 6.90905 1.20693 5.93842 1.60898C4.96779 2.01103 4.08585 2.60032 3.34296 3.34321C1.84263 4.84354 0.999756 6.87842 0.999756 9.00021C0.999756 11.122 1.84263 13.1569 3.34296 14.6572C4.84329 16.1575 6.87818 17.0004 8.99996 17.0004C11.1217 17.0004 13.1566 16.1575 14.657 14.6572Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SearchIcon;
