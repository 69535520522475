import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Grid } from '@mui/material';

import { StringInputHook, SecondaryButton } from '@/components';
import {
  CATEGORY_DEFAULT_VALUES,
  CategoryInterface,
  CreateCategoryValidation,
} from '@plenumpro/shared';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { useAddCategories } from '@/services';

interface CategoryFormProps {
  onClose: () => void;
}

const CategoryForm: FC<CategoryFormProps> = ({ onClose }) => {
  const formMethods = useForm<CategoryInterface>({
    resolver: classValidatorResolver(CreateCategoryValidation),
    defaultValues: CATEGORY_DEFAULT_VALUES,
    mode: 'all',
  });

  const { mutate: addCategory, isLoading } = useAddCategories();

  const { handleSubmit, reset } = formMethods;

  const onSubmit = async (data: CategoryInterface) => {
    addCategory(data, {
      onSuccess: () => {
        reset();
        onClose();
      },
    });
  };

  return (
    <FormProvider {...formMethods}>
      <Grid alignSelf={'center'} mt="32px" width="80%">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              gap: '16px',
            }}
          >
            <StringInputHook
              label="Name"
              name="name"
              placeholder="Name"
              formControlProps={{ fullWidth: true }}
            />
            <StringInputHook
              label="Description"
              name="description"
              placeholder="Description"
              multiline
              formControlProps={{ fullWidth: true }}
            />
          </Box>
          <SecondaryButton
            type="submit"
            variant="contained"
            loading={isLoading}
            sx={{ width: '100%', marginTop: '20px', marginBottom: '50px' }}
          >
            Create Category
          </SecondaryButton>
        </form>
      </Grid>
    </FormProvider>
  );
};

export default CategoryForm;
