import { useQuery } from 'react-query';
import { toast } from 'material-react-toastify';
import { getCustomer, getCustomers } from './api';

export const useGetCustomers = () => {
  return useQuery('customers', getCustomers, {
    staleTime: 5 * 60 * 1000,
    onSuccess: (data: any) => {
      return data.data;
    },
    onError: (error: any) => {
      const errorMessage = error?.data?.message?.message;
      toast.error(errorMessage);
    },
  });
};

export const useGetCustomer = (customerId: string) => {
  return useQuery(['customer', customerId], () => getCustomer(customerId), {
    enabled: !!customerId,
    onSuccess: (data: any) => {
      console.log({ data });
    },
    onError: (error: any) => {
      const errorMessage = error?.data?.message?.message;
    },
  });
};
