import { ECustomerContactMethod } from '../customers';

export enum ESchedulingStatus {
	Active = 'ACTIVE',
	Inactive = 'INACTIVE',
	Pending = 'PENDING',
	Confirmed = 'CONFIRMED',
	Cancelled = 'CANCELLED',
}

export enum EScheduling {
	Id = 'id',
	Address = 'address',
	Category = 'category',
	SubCategory = 'subCategory',
	ServiceCost = 'serviceCost',
	Emergency = 'emergency',
	IssuesExplain = 'issuesExplain',
	Date = 'date',
	Time = 'time',
	Customer = 'customer',
	Service = 'service',
	CustomerEmail = 'customerEmail',
}

export interface PersonalInfoInterface {
	firstName: string;
	lastName?: string;
	email: string;
	phone: string;
	contactMethod?: ECustomerContactMethod;
}

// TODO update according to new schema leaving it as used on frontendside
export interface SchedulingInterface {
	address: string;
	serviceNeed: string;
	serviceLooking: string;
	serviceId: string;
	serviceCost: number;
	emergency: string;
	issuesExplain?: string;
	date: Date;
	time: string;
	personalInfo: PersonalInfoInterface;
}

export interface ISchedulingInterface {
	address: string;
	categoryId?: string;
	subCategoryId?: string;
	serviceId?: string;
	serviceCost?: number;
	emergency: string;
	issuesExplain?: string;
	date: Date;
	time: string;
	tenantId: string;
	customerId: string;
}
export interface CreateSchedulingInterface {
	address: string;
	categoryId?: string;
	subCategoryId?: string;
	serviceId?: string;
	serviceCost?: number;
	emergency: string;
	issuesExplain?: string;
	date: Date;
	time: string;
	personalInfo: PersonalInfoInterface;
	tenantId: string;
}
