import { styled, alpha, InputBase } from '@mui/material';

export const SearchContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '55px',
  border: `1px solid ${alpha(theme.palette.common.black, 0.2)}`,
  backgroundColor: 'transparent',
  width: '384px',
  height: '48px',
  display: 'flex',
  alignItems: 'center',
  padding: '12px 24px',
  [theme.breakpoints.down('lg')]: {
    width: '300px',
    height: '48px',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: theme.palette.text.primary,
  flex: 1,
}));

export const SearchIconWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
