const AddIcon = ({ color = '#279660' }) => {
  return (
    <svg
      width="14px"
      height="14px"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6 8H0V6H6V0H8V6H14V8H8V14H6V8Z" fill={color} />
    </svg>
  );
};

export default AddIcon;
