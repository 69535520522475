import { IsNotEmpty, IsOptional, IsString } from 'class-validator';

export enum ECategoryFields {
	Id = 'id',
	Name = 'name',
	Description = 'description',
	Status = 'status',
	CreatedAt = 'createdAt',
	UpdatedAt = 'updatedAt',
}

export enum ECategoryStatus {
	Active = 'ACTIVE',
	Inactive = 'INACTIVE',
}

export interface CategoryInterface {
	id: string;
	name: string;
	description?: string;
	status: ECategoryStatus;
	createdAt: Date;
	updatedAt: Date;
}

export interface CategoryOptions {
	label: string;
	value: string;
}

export const CATEGORY_DEFAULT_VALUES = { name: '', description: '' };

export class CreateCategoryValidation implements Partial<CategoryInterface> {
	@IsNotEmpty({ message: 'Category name is required!' })
	@IsString({ message: 'Category name is required!' })
	name: string;

	@IsOptional()
	@IsString()
	description?: string;
}
