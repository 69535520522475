import { request } from '@/utils';

interface SignInData {
  username: string;
  password: string;
}

const base = '/auth';

export const getUserApi = () => request({ url: `${base}/user`, method: 'get' });

export const signInApi = (data: SignInData) =>
  request({ url: `${base}/login`, method: 'post', data });

export const logoutApi = () =>
  request({ url: `${base}/logout`, method: 'post' });
