import { CustomGridColDef } from '@/hooks/useTable/types';
import {
  EPriceType,
  EServiceFields,
  EServiceStatus,
  EServiceType,
} from '@plenumpro/shared';
import { Box } from '@mui/material';
import { dateFormat, getHumanizeEnum } from '@/utils';

export const getTableColumns = (
  currency: string = 'USD',
): CustomGridColDef[] => [
  {
    field: EServiceFields.Name,
    headerName: 'Service Name',
    minWidth: 150,
    flex: 1,
    sortable: true,
    hide: true,
    renderHeader: () => <Box sx={{ paddingLeft: '45px' }}>Service Name</Box>,
  },
  {
    field: EServiceFields.Description,
    headerName: 'Description',
    minWidth: 150,
    flex: 1,
    sortable: true,
  },
  {
    field: EServiceFields.Price,
    headerName: `Price (${currency})`,
    minWidth: 150,
    flex: 1,
    sortable: true,
  },
  {
    field: EServiceFields.Duration,
    headerName: 'Duration (minutes)',
    minWidth: 150,
    flex: 1,
    sortable: true,
  },
  {
    field: EServiceFields.PriceType,
    headerName: 'Price Type',
    minWidth: 150,
    flex: 1,
    sortable: false,
    renderCell: params => getHumanizeEnum(EPriceType, params.row.priceType),
  },
  {
    field: EServiceFields.ServiceType,
    headerName: 'Service Type',
    minWidth: 250,
    flex: 1,
    sortable: false,
    renderCell: params => getHumanizeEnum(EServiceType, params.row.serviceType),
  },
  {
    field: EServiceFields.Status,
    headerName: 'Status',
    minWidth: 150,
    flex: 1,
    sortable: false,
    renderCell: params => getHumanizeEnum(EServiceStatus, params.row.status),
  },
  {
    field: EServiceFields.CreatedAt,
    headerName: 'Registration Date',
    type: 'date',
    minWidth: 50,
    flex: 1,
    sortable: false,
    renderCell: params => {
      const date = params.row.createdAt
        ? dateFormat(params.row.createdAt)
        : 'N/A';
      return date;
    },
  },
];
