import React from 'react';
import {
  CardContent,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Box,
  Divider,
  IconButton,
} from '@mui/material';
import { green, red } from '@mui/material/colors';
import CircleIcon from '@mui/icons-material/Circle';
import { StyledCard, StyledCardHeader } from './Card.styles';
import { MapCheckInIcon } from '@/assets/icons';

const employees = [
  {
    name: 'Aydin Mehr',
    job: '#804',
    time: '8:12 AM',
    status: 'on-time',
    avatar: 'https://i.pravatar.cc/300',
  },
  {
    name: 'Afshin Mehr',
    job: '#812',
    time: '12:21 PM',
    status: 'late',
    avatar: 'https://i.pravatar.cc/301',
  },
  {
    name: 'Brian M',
    job: '#811',
    time: '9:05 AM',
    status: 'on-time',
    avatar: 'https://i.pravatar.cc/302',
  },
  {
    name: 'Arvin R',
    job: '#809',
    time: '8:02 AM',
    status: 'on-time',
    avatar: 'https://i.pravatar.cc/303',
  },
];

const CheckInsCard = () => {
  return (
    <StyledCard>
      <CardContent>
        <StyledCardHeader>
          <Box display="flex" alignItems="center" gap={1}>
            <MapCheckInIcon />
            <Typography variant="h6" sx={{ fontWeight: 600 }}>
              Employee Check Ins
            </Typography>
          </Box>
        </StyledCardHeader>

        <Divider />

        <Box
          sx={{
            overflowY: 'auto',
            maxHeight: '230px',
          }}
        >
          <List>
            {employees.map((employee, index) => (
              <React.Fragment key={index}>
                <ListItem sx={{ padding: '8px 0' }}>
                  <ListItemAvatar>
                    <Avatar alt={employee.name} src={employee.avatar} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography variant="body1" sx={{ fontWeight: 600 }}>
                        {employee.name}
                      </Typography>
                    }
                    secondary={
                      <Typography variant="body2">{`Job ${employee.job}`}</Typography>
                    }
                  />
                  <Box display="flex" alignItems="center">
                    <IconButton size="small" disableRipple>
                      <CircleIcon
                        fontSize="small"
                        sx={{
                          width: '7px',
                          height: '7px',
                          color:
                            employee.status === 'on-time'
                              ? green[500]
                              : red[500],
                        }}
                      />
                    </IconButton>
                    <Typography variant="body2">{employee.time}</Typography>
                  </Box>
                </ListItem>
                {index < employees.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </List>
        </Box>
      </CardContent>
    </StyledCard>
  );
};

export default CheckInsCard;
