import { FC, useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import {
  StringInputHook,
  SecondaryButton,
  SelectHookInput,
} from '@/components';
import {
  CreateServiceValidation,
  EPriceType,
  EServiceType,
  ServiceInterface,
  SERVICES_DEFAULT_VALUES,
} from '@plenumpro/shared';
import { useFetchCategoriesOptions, useFetchCategoryId } from '@/services';
import { useAddServices } from '@/services/servicesCatalog/mutation';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';

interface ServiceFormProps {
  onClose: () => void;
  onError: (error: string) => void;
  currency: string;
}

const ServiceForm: FC<ServiceFormProps> = ({ onClose, currency }) => {
  const formMethods = useForm<ServiceInterface>({
    defaultValues: SERVICES_DEFAULT_VALUES,
    resolver: classValidatorResolver(CreateServiceValidation),
    mode: 'all',
  });

  const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>(
    null,
  );
  const { mutate: addServices, isLoading } = useAddServices();

  const { data: categories } = useFetchCategoriesOptions();
  const { data: categoryOptions, isLoading: loadingCategoryOptions } =
    useFetchCategoryId(selectedCategoryId || '');

  const { handleSubmit, formState, reset } = formMethods;

  const onSubmit = async (data: ServiceInterface) => {
    addServices(
      {
        ...data,
        price: Number(data.price),
        duration: Number(data.duration),
      },
      {
        onSuccess: () => {
          reset();
          onClose();
        },
      },
    );
  };

  const handleCategoryChange = useCallback((value: string) => {
    setSelectedCategoryId(value);
  }, []);

  return (
    <FormProvider {...formMethods}>
      <Grid alignSelf={'center'} mt="32px" width="80%">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              gap: '16px',
            }}
          >
            <StringInputHook label="Name" name="name" placeholder="Name" />
            <StringInputHook
              label="Description"
              name="description"
              placeholder="Description"
              fullWidth
              multiline
            />
            <StringInputHook
              label={`Price (${currency})`}
              name="price"
              type="number"
              placeholder="Price"
              fullWidth
            />
            <StringInputHook
              label="Duration (minutes)"
              name="duration"
              type="number"
              placeholder="Duration"
              fullWidth
            />

            <SelectHookInput
              label="Select Category"
              name="categoryId"
              options={categories || []}
              getValueAsObject={false}
              onChangeSideEffect={handleCategoryChange}
            />

            <SelectHookInput
              label="Select SubCategory"
              name="subCategoryId"
              options={loadingCategoryOptions ? [] : categoryOptions || []}
              getValueAsObject={false}
            />

            <SelectHookInput
              label="Price Type"
              name="priceType"
              getValueAsObject={false}
              options={Object.values(EPriceType).map(value => ({
                label: value.replace(/_/g, ' ').toUpperCase(),
                value,
              }))}
            />

            <SelectHookInput
              label="Service Type"
              name="serviceType"
              getValueAsObject={false}
              options={Object.values(EServiceType).map(value => ({
                label: value.replace(/_/g, ' ').toUpperCase(),
                value,
              }))}
            />
          </div>
          <SecondaryButton
            type="submit"
            variant="contained"
            loading={isLoading}
            disabled={formState.isSubmitting}
            sx={{
              width: '100%',
              marginTop: '20px',
              marginBottom: '50px',
            }}
          >
            Create Service
          </SecondaryButton>
        </form>
      </Grid>
    </FormProvider>
  );
};

export default ServiceForm;
