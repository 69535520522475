import { styled, Box, Typography } from '@mui/material';

export const StyledChip = styled(Box)({
  height: '60px',
  width: '198px',
  paddingTop: '8px',
  marginTop: '8px',
  paddingBottom: '8px',
  paddingLeft: '12px',
  paddingRight: '12px',
  display: 'flex',
  flexDirection: 'row',
  gap: '12px',
  background: 'white',
  borderRadius: '24px',
  alignItems: 'center',
});

export const StyledIconWrapper = styled(Box)({
  height: '40px',
  width: '40px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#F1F1F1',
  borderRadius: '20px',
});

export const StyledValues = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
});

export const StyledHeading = styled(Box)(({ theme }) => ({
  font: 'subtitle1',
  color: theme.palette.grey[200],
}));

export const StyledPrice = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  fontWeight: 500,
  color: theme.palette.primary.main,
}));
