import { useRoutes } from 'react-router-dom';
import { Box } from '@mui/material';

import getRoutes from './routes';

const App = () => {
  const routes = useRoutes(getRoutes());
  return (
    <Box
      sx={{
        backgroundColor: 'grey.500',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {routes}
    </Box>
  );
};

export default App;
