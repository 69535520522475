import { FC } from 'react';
import { Box } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

import {
  StyledActionsContainer,
  StyledButtonGroup,
  StyledInnerButtonGroup,
  StyledMainContainer,
  StyledTableContainer,
  StyledTypography,
} from './EmployeesListing.styles';
import { getTableColumns } from './tableColumns';
import { useTableHook } from '@/hooks/useTable';
import useToggle from '@/hooks/useToggle';
import {
  PrimaryButton,
  ResponsiveFieldWrapper,
  SelectHookInput,
} from '@/components/common';
import { ProfessionalInterface, EAuth0Roles } from '@plenumpro/shared';
import { useGetEmployees } from '@/services';
import CreateEmployee from '../CreateEmployee';

interface ICategoryListingProps {
  rows: ProfessionalInterface[];
  searchText: string;
}

const EmployeesListing: FC<ICategoryListingProps> = () => {
  const createEmployeeToggle = useToggle();
  const { data, isLoading } = useGetEmployees();
  const columns = getTableColumns();
  const professionalsData = (data || []).map(
    (professional: ProfessionalInterface) => ({
      ...professional,
    }),
  );

  const allowedRolesSet = new Set<EAuth0Roles>([
    EAuth0Roles.Admin,
    EAuth0Roles.Professional,
  ]);

  const RolesOptions = Object.entries(EAuth0Roles)
    .filter(([roleKey, roleValue]) => allowedRolesSet.has(roleValue))
    .map(([roleKey, roleValue]) => ({ label: roleKey, value: roleValue }));

  const { tableProps, CustomTable } = useTableHook<any>({
    initialState: {
      itemsPerPage: 50,
    },
  });

  const formMethods = useForm<ProfessionalInterface>({
    defaultValues: {
      name: '',
      email: '',
      password: '',
      role: EAuth0Roles.Professional,
    },
    mode: 'all',
  });

  const { handleSubmit } = formMethods;

  const onSubmit = (data: ProfessionalInterface) => {
    // onCreateEmployee(data);
    // onClose();
  };

  const noOfEmployees =
    `${professionalsData.length} Employee` +
    (professionalsData.length > 1 ? 's' : '');

  return (
    <StyledMainContainer>
      <Box>
        <StyledTypography>{noOfEmployees}</StyledTypography>
        <StyledActionsContainer>
          <StyledButtonGroup>
            <FormProvider {...formMethods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <ResponsiveFieldWrapper>
                  <SelectHookInput
                    // label="Select role"
                    name="role"
                    options={RolesOptions}
                    getValueAsObject={false}
                    formControlProps={{ fullWidth: true }}
                  />
                </ResponsiveFieldWrapper>
              </form>
            </FormProvider>
            <StyledInnerButtonGroup>
              <PrimaryButton onClick={createEmployeeToggle.setToOpen}>
                Create Employee
              </PrimaryButton>
            </StyledInnerButtonGroup>
          </StyledButtonGroup>
        </StyledActionsContainer>
      </Box>
      <StyledTableContainer>
        <CustomTable
          {...tableProps}
          rows={data || []}
          columns={columns}
          loading={isLoading}
          rowsPerPageOptions={[10, 15, 20, 50, 100]}
        />
      </StyledTableContainer>
      <CreateEmployee
        open={createEmployeeToggle.isOpen}
        onClose={createEmployeeToggle.setToClose}
      />
    </StyledMainContainer>
  );
};

export default EmployeesListing;
