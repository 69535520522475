import { SecondaryButton } from '@/components';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

const NotFoundPage = () => {
  const naviagte = useNavigate();
  const handleGoBack = () => {
    return naviagte(-1);
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: { xs: '70px', lg: '100px' },
        height: '100vh',
        paddingX: '20px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: { xs: '20px', lg: '40px' },
        }}
      >
        <Typography
          variant="h1"
          sx={{ fontSize: { xs: '100px ', lg: '140px' } }}
        >
          404
        </Typography>
        <Typography variant="h5" sx={{ fontWeight: { xs: 500, lg: 600 } }}>
          Page not found!
        </Typography>
      </Box>
      <SecondaryButton action={handleGoBack}>Go Back</SecondaryButton>
    </Box>
  );
};

export default NotFoundPage;
