import {
	IsNotEmpty,
	IsString,
	IsOptional,
	IsNumber,
	ArrayMinSize,
	IsEnum,
	IsArray,
} from 'class-validator';
import { WeekDays } from '../common';
import { IsEnumKey } from '../customValidators';

export enum ETimeZones {
	UTC = 'UTC - Coordinated Universal Time',
	EST = 'EST - Eastern Standard Time (UTC-5)',
	EDT = 'EDT - Eastern Daylight Time (UTC-4)',
	CST = 'CST - Central Standard Time (UTC-6)',
	CDT = 'CDT - Central Daylight Time (UTC-5)',
	MST = 'MST - Mountain Standard Time (UTC-7)',
	MDT = 'MDT - Mountain Daylight Time (UTC-6)',
	PST = 'PST - Pacific Standard Time (UTC-8)',
	PDT = 'PDT - Pacific Daylight Time (UTC-7)',
	GMT = 'GMT - Greenwich Mean Time',
	CET = 'CET - Central European Time (UTC+1)',
	CEST = 'CEST - Central European Summer Time (UTC+2)',
	IST = 'IST - Indian Standard Time (UTC+5:30)',
	JST = 'JST - Japan Standard Time (UTC+9)',
	AEST = 'AEST - Australian Eastern Standard Time (UTC+10)',
	AEDT = 'AEDT - Australian Eastern Daylight Time (UTC+11)',
	NZST = 'NZST - New Zealand Standard Time (UTC+12)',
	NZDT = 'NZDT - New Zealand Daylight Time (UTC+13)',
}

export enum ECurrency {
	AED = 'AED - United Arab Emirates dirham',
	AFN = 'AFN - Afghan afghani',
	ALL = 'ALL - Albanian lek',
	AMD = 'AMD - Armenian dram',
	ANG = 'ANG - Netherlands Antillean guilder',
	AOA = 'AOA - Angolan kwanza',
	ARS = 'ARS - Argentine peso',
	AUD = 'AUD - Australian dollar',
	AWG = 'AWG - Aruban florin',
	AZN = 'AZN - Azerbaijani manat',
	BAM = 'BAM - Bosnia and Herzegovina convertible mark',
	BBD = 'BBD - Barbadian dollar',
	BDT = 'BDT - Bangladeshi taka',
	BGN = 'BGN - Bulgarian lev',
	BHD = 'BHD - Bahraini dinar',
	BIF = 'BIF - Burundian franc',
	BMD = 'BMD - Bermudian dollar',
	BND = 'BND - Brunei dollar',
	BOB = 'BOB - Bolivian boliviano',
	BRL = 'BRL - Brazilian real',
	BSD = 'BSD - Bahamian dollar',
	BTN = 'BTN - Bhutanese ngultrum',
	BWP = 'BWP - Botswana pula',
	BYN = 'BYN - Belarusian ruble',
	BZD = 'BZD - Belize dollar',
	CAD = 'CAD - Canadian dollar',
	CDF = 'CDF - Congolese franc',
	CHF = 'CHF - Swiss franc',
	CLP = 'CLP - Chilean peso',
	CNY = 'CNY - Chinese yuan',
	COP = 'COP - Colombian peso',
	CRC = 'CRC - Costa Rican colón',
	CUP = 'CUP - Cuban peso',
	CVE = 'CVE - Cape Verdean escudo',
	CZK = 'CZK - Czech koruna',
	DJF = 'DJF - Djiboutian franc',
	DKK = 'DKK - Danish krone',
	DOP = 'DOP - Dominican peso',
	DZD = 'DZD - Algerian dinar',
	EGP = 'EGP - Egyptian pound',
	ERN = 'ERN - Eritrean nakfa',
	ETB = 'ETB - Ethiopian birr',
	EUR = 'EUR - Euro',
	FJD = 'FJD - Fijian dollar',
	FKP = 'FKP - Falkland Islands pound',
	FOK = 'FOK - Faroese króna',
	GBP = 'GBP - British pound',
	GEL = 'GEL - Georgian lari',
	GGP = 'GGP - Guernsey pound',
	GHS = 'GHS - Ghanaian cedi',
	GIP = 'GIP - Gibraltar pound',
	GMD = 'GMD - Gambian dalasi',
	GNF = 'GNF - Guinean franc',
	GTQ = 'GTQ - Guatemalan quetzal',
	GYD = 'GYD - Guyanese dollar',
	HKD = 'HKD - Hong Kong dollar',
	HNL = 'HNL - Honduran lempira',
	HRK = 'HRK - Croatian kuna',
	HTG = 'HTG - Haitian gourde',
	HUF = 'HUF - Hungarian forint',
	IDR = 'IDR - Indonesian rupiah',
	ILS = 'ILS - Israeli new shekel',
	IMP = 'IMP - Isle of Man pound',
	INR = 'INR - Indian rupee',
	IQD = 'IQD - Iraqi dinar',
	IRR = 'IRR - Iranian rial',
	ISK = 'ISK - Icelandic króna',
	JEP = 'JEP - Jersey pound',
	JMD = 'JMD - Jamaican dollar',
	JOD = 'JOD - Jordanian dinar',
	JPY = 'JPY - Japanese yen',
	KES = 'KES - Kenyan shilling',
	KGS = 'KGS - Kyrgyzstani som',
	KHR = 'KHR - Cambodian riel',
	KID = 'KID - Kiribati dollar',
	KMF = 'KMF - Comorian franc',
	KRW = 'KRW - South Korean won',
	KWD = 'KWD - Kuwaiti dinar',
	KYD = 'KYD - Cayman Islands dollar',
	KZT = 'KZT - Kazakhstani tenge',
	LAK = 'LAK - Lao kip',
	LBP = 'LBP - Lebanese pound',
	LKR = 'LKR - Sri Lankan rupee',
	LRD = 'LRD - Liberian dollar',
	LSL = 'LSL - Lesotho loti',
	LYD = 'LYD - Libyan dinar',
	MAD = 'MAD - Moroccan dirham',
	MDL = 'MDL - Moldovan leu',
	MGA = 'MGA - Malagasy ariary',
	MKD = 'MKD - Macedonian denar',
	MMK = 'MMK - Myanmar kyat',
	MNT = 'MNT - Mongolian tögrög',
	MOP = 'MOP - Macanese pataca',
	MRU = 'MRU - Mauritanian ouguiya',
	MUR = 'MUR - Mauritian rupee',
	MVR = 'MVR - Maldivian rufiyaa',
	MWK = 'MWK - Malawian kwacha',
	MXN = 'MXN - Mexican peso',
	MYR = 'MYR - Malaysian ringgit',
	MZN = 'MZN - Mozambican metical',
	NAD = 'NAD - Namibian dollar',
	NGN = 'NGN - Nigerian naira',
	NIO = 'NIO - Nicaraguan córdoba',
	NOK = 'NOK - Norwegian krone',
	NPR = 'NPR - Nepalese rupee',
	NZD = 'NZD - New Zealand dollar',
	OMR = 'OMR - Omani rial',
	PAB = 'PAB - Panamanian balboa',
	PEN = 'PEN - Peruvian sol',
	PGK = 'PGK - Papua New Guinean kina',
	PHP = 'PHP - Philippine peso',
	PKR = 'PKR - Pakistani rupee',
	PLN = 'PLN - Polish złoty',
	PYG = 'PYG - Paraguayan guaraní',
	QAR = 'QAR - Qatari riyal',
	RON = 'RON - Romanian leu',
	RSD = 'RSD - Serbian dinar',
	RUB = 'RUB - Russian ruble',
	RWF = 'RWF - Rwandan franc',
	SAR = 'SAR - Saudi riyal',
	SBD = 'SBD - Solomon Islands dollar',
	SCR = 'SCR - Seychellois rupee',
	SDG = 'SDG - Sudanese pound',
	SEK = 'SEK - Swedish krona',
	SGD = 'SGD - Singapore dollar',
	SHP = 'SHP - Saint Helena pound',
	SLL = 'SLL - Sierra Leonean leone',
	SOS = 'SOS - Somali shilling',
	SRD = 'SRD - Surinamese dollar',
	SSP = 'SSP - South Sudanese pound',
	STN = 'STN - São Tomé and Príncipe dobra',
	SYP = 'SYP - Syrian pound',
	SZL = 'SZL - Eswatini lilangeni',
	THB = 'THB - Thai baht',
	TJS = 'TJS - Tajikistani somoni',
	TMT = 'TMT - Turkmenistani manat',
	TND = 'TND - Tunisian dinar',
	TOP = 'TOP - Tongan paʻanga',
	TRY = 'TRY - Turkish lira',
	TTD = 'TTD - Trinidad and Tobago dollar',
	TVD = 'TVD - Tuvaluan dollar',
	TWD = 'TWD - New Taiwan dollar',
	TZS = 'TZS - Tanzanian shilling',
	UAH = 'UAH - Ukrainian hryvnia',
	UGX = 'UGX - Ugandan shilling',
	USD = 'USD - United States dollar',
	UYU = 'UYU - Uruguayan peso',
	UZS = 'UZS - Uzbekistani som',
	VES = 'VES - Venezuelan bolívar',
	VND = 'VND - Vietnamese đồng',
	VUV = 'VUV - Vanuatu vatu',
	WST = 'WST - Samoan tālā',
	XAF = 'XAF - Central African CFA franc',
	XCD = 'XCD - East Caribbean dollar',
	XOF = 'XOF - West African CFA franc',
	XPF = 'XPF - CFP franc',
	YER = 'YER - Yemeni rial',
	ZAR = 'ZAR - South African rand',
	ZMW = 'ZMW - Zambian kwacha',
	ZWL = 'ZWL - Zimbabwean dollar',
}

export enum ELanguage {
	ar = 'Arabic',
	bn = 'Bengali',
	zh = 'Chinese (Mandarin)',
	en = 'English',
	fr = 'French',
	de = 'German',
	hi = 'Hindi',
	it = 'Italian',
	ja = 'Japanese',
	ko = 'Korean',
	ms = 'Malay',
	fa = 'Persian',
	pt = 'Portuguese',
	pa = 'Punjabi',
	ru = 'Russian',
	es = 'Spanish',
	sw = 'Swahili',
	ta = 'Tamil',
	tr = 'Turkish',
	ur = 'Urdu',
	vi = 'Vietnamese',
}

export enum ENotificationPreference {
	Email = 'Email Notifications',
	Sms = 'SMS Notifications',
	Push = 'Push Notifications',
	None = 'No Notifications',
	InApp = 'In-App Notifications',
}

export interface CompanySettingsInterface {
	timeZone: ETimeZones;
	currency: ECurrency;
	language: ELanguage;
	businessHours?: number;
	serviceAreas?: string;
	notificationPreferences?: ENotificationPreference;
	tenantId: string;
	workingDays: WeekDays[];
}

export const COMPANY_SETTINGS_DEFAULT_VALUES: Partial<CompanySettingsInterface> =
	{
		timeZone: ETimeZones[ETimeZones.UTC],
		currency: ECurrency[ECurrency.USD],
		language: ELanguage[ELanguage.en],
		businessHours: 0,
		serviceAreas: '',
		notificationPreferences:
			ENotificationPreference[ENotificationPreference.Email],
		workingDays: [
			WeekDays.Mon,
			WeekDays.Tue,
			WeekDays.Wed,
			WeekDays.Thu,
			WeekDays.Fri,
		],
	};

export class CompanySettingsValidation
	implements Partial<CompanySettingsInterface>
{
	@IsNotEmpty()
	@IsEnumKey(ETimeZones, { message: 'Invalid time zone.' })
	timeZone: ETimeZones;

	@IsNotEmpty({ message: 'Currency is required!' })
	@IsEnumKey(ECurrency, { message: 'Invalid currency.' })
	currency: ECurrency;

	@IsNotEmpty({ message: 'Language is required!' })
	@IsEnumKey(ELanguage, { message: 'Invalid language.' })
	language: ELanguage;

	@IsOptional()
	@IsNumber()
	businessHours: number;

	@IsOptional()
	@IsString()
	serviceAreas: string;

	@IsOptional()
	@IsArray({ message: 'Working days must be an array.' })
	@IsEnum(WeekDays, { each: true, message: 'Invalid working day.' })
	@ArrayMinSize(1, { message: 'At least one working day must be selected.' })
	workingDays: WeekDays[];

	@IsOptional()
	@IsEnumKey(ENotificationPreference, {
		message: 'Invalid notification preference.',
	})
	notificationPreferences: ENotificationPreference;
}
