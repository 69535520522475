import { useMutation, useQueryClient } from 'react-query';
import { addServices } from './api';
import { toast } from 'material-react-toastify';

export const useAddServices = () => {
  const queryClient = useQueryClient();
  return useMutation(addServices, {
    onMutate: async newServices => {
      return { newServices };
    },
    onSuccess: () => {
      toast.success('Service created successful!');
      queryClient.invalidateQueries('allServices');
    },
    onError: (error: any) => {
      console.error('Error updating user:', error);
      const errorMessage = error?.data?.message?.message;
      toast.error(errorMessage);
    },
  });
};
