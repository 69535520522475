export interface DataRow {
  id: number | string;
  [key: string]: any;
}

export enum ESortBy {
  ASC = 'ASC',
  DESC = 'DESC',
}
export interface IPaginationModel {
  pageSize: number;
  page: number;
}

export interface ISortModel {
  field: string;
  sort: 'asc' | 'desc';
}

export interface ITableProps {
  paginationModel: IPaginationModel;
  onSortModelChange: (newModel: ISortModel[]) => void;
  onPaginationModelChange: (model: IPaginationModel) => void;
  onCheckboxChange: (
    id?: number | string,
    event?: React.ChangeEvent<HTMLInputElement>,
    rowIds?: (string | number)[],
    deselectAll?: boolean,
  ) => void;
  selectedIds?: (string | number)[];
}
export interface CustomGridColDef {
  field: string; // Unique identifier for the column
  headerName?: string; // Column header text
  description?: string; // Column description tooltip
  width?: number; // Width of the column
  minWidth?: number; // Minimum width of the column
  maxWidth?: number; // Maximum width of the column
  sortable?: boolean; // Enable sorting for the column
  filterable?: boolean; // Enable filtering for the column
  editable?: boolean; // Enable editing for the column
  hide?: boolean; // Hide the column
  align?: 'left' | 'right' | 'center'; // Column text alignment
  flex?: number; // Flex-grow for the column
  link?: (params: CustomRenderCellParams) => React.ReactNode; // Custom cell renderer
  renderCell?: (params: CustomRenderCellParams) => React.ReactNode; // Custom cell renderer
  renderHeader?: () => React.ReactNode; // Custom header renderer
  valueGetter?: (params: any) => any; // Function to derive a cell value
  valueSetter?: (params: any) => any; // Function to set a cell value
  valueFormatter?: (params: any) => string; // Function to format a cell value
  valueParser?: (value: any, params: any) => any; // Parse the input before saving
  sortComparator?: (v1: any, v2: any) => number; // Custom sort comparator
  disableColumnMenu?: boolean; // Disable column menu options
  colSpan?: number; // Column span across multiple columns
  cellClassName?: string | ((params: CustomRenderCellParams) => string); // Class name for cell
  headerClassName?: string; // Class name for the header
  hideSortIcons?: boolean; // Hide sorting icons
  disableReorder?: boolean; // Disable column reordering
  disableExport?: boolean; // Disable exporting this column
  resizable?: boolean; // Enable column resizing
  pinned?: 'left' | 'right'; // Pin the column to left or right
  headerAlign?: 'left' | 'center' | 'right'; // Header text alignment
  type?: 'string' | 'number' | 'date' | 'boolean' | 'singleSelect'; // Type of data in the column
  groupable?: boolean; // Enable/disable column grouping
  cellTooltip?: (params: CustomRenderCellParams) => string; // Tooltip for cells
  headerTooltip?: string; // Tooltip for the header
  aggregatedValueGetter?: (params: any) => any; // Value getter for aggregation
  disableClickEventBubbling?: boolean; // Prevent row click event bubbling
}

export interface CustomRenderCellParams {
  id: string | number; // Unique identifier for the row
  field: string; // The field (column) this cell belongs to
  value: any; // The value of the cell
  row: DataRow; // The entire row data
  formattedValue?: any; // The formatted value of the cell
  isEditable?: boolean; // Whether the cell is editable
  isSelected?: boolean; // Whether the row is selected
  api?: any; // API instance for additional functionalities (if needed)
  colDef?: CustomGridColDef; // Column definition for this cell
  rowIndex?: number; // Index of the row
  cellClassName?: string; // Class name for the cell
}
