import React from 'react';

const AddressIcon = () => {
  return (
    <div>
      <svg width="19" height="23" viewBox="0 0 19 23" fill="none">
        <path
          d="M1.5 9C1.50006 2.98668 5.5 1 9.5 1C13.5 1 17.5 3.4 17.5 9C17.5 14.6 12.1667 19.3333 9.5 21C6.66667 19.5 1.49994 15 1.5 9Z"
          fill="#BDBDBD"
          stroke="#BDBDBD"
          strokeWidth="2"
        />
        <circle
          cx="9.5"
          cy="9"
          r="3"
          fill="#BDBDBD"
          stroke="#FEFEFE"
          strokeWidth="2"
        />
      </svg>
    </div>
  );
};

export default AddressIcon;
