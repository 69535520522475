import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { ToastContainer } from 'material-react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import 'reflect-metadata';
import App from './App';
import { customTheme } from './theme';
import { UserProvider } from './context';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const queryClient = new QueryClient();

root.render(
  <BrowserRouter>
    <ThemeProvider theme={customTheme}>
      <QueryClientProvider client={queryClient}>
        <UserProvider>
          <React.StrictMode>
            <ToastContainer position="top-right" />
            <CssBaseline />
            <App />
          </React.StrictMode>
          {process.env.NODE_ENV === 'development' && (
            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
          )}
        </UserProvider>
      </QueryClientProvider>
    </ThemeProvider>
    ,
  </BrowserRouter>,
);
