import { FC, ReactNode, useCallback } from 'react';

import { PrimaryButton, SearchBar, SelectInput } from '../../common';

import {
  StyledActionsContainer,
  StyledButtonGroup,
  StyledInnerButtonGroup,
  StyledMainContainer,
  StyledTypography,
  StyledTableContainer,
} from './EstimatesListing.styles';

import DetailPanelContent from './DetailPanelContent';
import { getTableColumns } from './tableColumns';

import { DownArrowIcon } from '@/assets/icons';
import { IUseTableActionTypes, useTableHook } from '@/hooks/useTable';
import { IEstimateParams, IEstimate } from '@/utils';

import useToggle from '@/hooks/useToggle';
import { CUSTOMER_MENU_ITEMS } from '@/utils/constants/selectInputItems';
import CreateEstimate from '../CreateEstimate';
import { Box } from '@mui/material';

interface IEstimateListingProps {
  loading: boolean;
  rows: IEstimate[];
  refetchEstimates: (params: IEstimateParams) => void;
  searchText: string;
  onSearchChange: (text: string) => void;
}

const EstimateListing: FC<IEstimateListingProps> = ({
  loading,
  rows,
  refetchEstimates,
  searchText,
  onSearchChange,
}) => {
  const createToggle = useToggle();

  const getDetailPanelContent = useCallback(
    ({ row }: { row: any }): ReactNode => {
      return <DetailPanelContent row={row} />;
    },
    [],
  );

  const getDetailPanelMaxHeight = useCallback(() => 800, []);

  const columns = getTableColumns();
  const { tableProps, CustomTable, dispatch } = useTableHook<any>({
    initialState: {
      itemsPerPage: 50,
    },
  });
  const handleSearch = () => {
    const trimmedText = searchText.trim();

    dispatch({
      type: trimmedText
        ? IUseTableActionTypes.SET_FILTER
        : IUseTableActionTypes.REMOVE_FILTER,
      payload: {
        name: trimmedText || undefined,
      },
    });
    refetchEstimates({
      filters: trimmedText ? [{ customerName: trimmedText }] : [],
      page: 1,
      pageSize: 50,
    });
  };

  const handleSearchChange = (text: string) => {
    onSearchChange(text);
    if (text.trim() === '') {
      refetchEstimates({
        filters: [],
        page: 1,
        pageSize: 50,
      });
    }
  };

  return (
    <StyledMainContainer>
      <Box>
        <StyledTypography>{`${rows.length} estimates`}</StyledTypography>
        <StyledActionsContainer>
          <SearchBar
            placeholder="Search estimates"
            value={searchText}
            onChange={e => handleSearchChange(e.target.value)}
          />
          <StyledButtonGroup>
            <StyledInnerButtonGroup>
              <PrimaryButton onClick={handleSearch}>Filter</PrimaryButton>
              <PrimaryButton>Edit columns</PrimaryButton>
            </StyledInnerButtonGroup>
            <StyledInnerButtonGroup>
              <PrimaryButton onClick={createToggle.setToOpen}>
                Create Estimate
              </PrimaryButton>
              <SelectInput options={CUSTOMER_MENU_ITEMS} onClick={() => {}}>
                <PrimaryButton>
                  Actions <DownArrowIcon />
                </PrimaryButton>
              </SelectInput>
            </StyledInnerButtonGroup>
          </StyledButtonGroup>
        </StyledActionsContainer>
      </Box>
      <StyledTableContainer>
        <CustomTable
          {...tableProps}
          rows={rows}
          columns={columns}
          loading={loading}
          checkboxSelection
          rowsPerPageOptions={[10, 15, 20, 50, 100]}
          onDetailPanelContent={row => getDetailPanelContent({ row })}
          detailPanelMaxHeight={getDetailPanelMaxHeight}
        />
      </StyledTableContainer>
      <CreateEstimate
        open={createToggle.isOpen}
        onClose={createToggle.setToClose}
        onCreateEstimate={() => {}}
      />
    </StyledMainContainer>
  );
};

export default EstimateListing;
