import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost:4000/api/v1',
  withCredentials: true,
});

export interface RequestOptions extends AxiosRequestConfig {
  // Extend if needed for additional request options
}

export const request = async (
  options: RequestOptions,
): Promise<AxiosResponse | AxiosError> => {
  try {
    const response = await client(options);
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('Axios error:', error.response?.data || error.message);
      return Promise.reject(error.response);
    } else {
      console.error('Unexpected error:', error);
      return Promise.reject(error);
    }
  }
};
