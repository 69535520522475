import { FC, ReactNode } from 'react';
import moment from 'moment';
import { LocationOn, Smartphone } from '@mui/icons-material';
import {
  Avatar,
  Box,
  ClickAwayListener,
  Fade,
  Tooltip,
  Typography,
} from '@mui/material';

import { IEvent } from '@/utils';
import { CalenderIcon } from '@/assets/icons';
import {
  tooltipStyles,
  modifiers,
  boxContainerStyles,
  innerBoxStyles,
  commonTypography,
  assignedBoxStyles,
  attendeeBoxStyles,
  avatarName,
  avatarImage,
  avatarInnerBox,
  commonTextStyle,
  calenderIcon,
} from './EventTooltip.styles';

interface IEventTooltipProps {
  event: IEvent;
  setOpenTooltipId: (id: number | null) => void;
  isOpen: boolean;
  children: ReactNode;
  view: any;
}

const EventTooltip: FC<IEventTooltipProps> = ({
  event,
  setOpenTooltipId,
  isOpen,
  children,
  view,
}) => {
  const position = view === 'week' ? ['bottom', 'right', 'left'] : ['bottom'];
  return (
    <ClickAwayListener onClickAway={() => setOpenTooltipId(null)}>
      <Tooltip
        PopperProps={{
          modifiers: modifiers(position),
          sx: tooltipStyles,
        }}
        onClose={() => setOpenTooltipId(null)}
        open={isOpen}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 0 }}
        title={
          <Box sx={boxContainerStyles}>
            <Box sx={innerBoxStyles}>
              <Typography sx={commonTextStyle}>{event.title}</Typography>
              <Typography sx={commonTypography}>
                <CalenderIcon style={calenderIcon} color='#BDBDBD'/>
                {moment(event.start).format('MMM DD, YYYY h:mm A')} -{' '}
                {moment(event.end).format('h:mm A')}
              </Typography>
              <Typography sx={commonTypography}>
                <LocationOn fontSize="small" sx={{ fill: '#BDBDBD' }} />
                {event.data?.appointment?.address}
              </Typography>
              <Typography sx={commonTypography}>
                <Smartphone fontSize="small" sx={{ fill: '#BDBDBD' }} />{' '}
                {event.phone}
              </Typography>
            </Box>
            <Box sx={innerBoxStyles}>
              <Typography sx={commonTextStyle}>Job {event.jobId}</Typography>
              <Typography sx={commonTypography}>{event.service}</Typography>
              <Typography sx={commonTypography}>{event.price}</Typography>
            </Box>
            <Box sx={innerBoxStyles}>
              <Typography sx={commonTextStyle}>Assigned to</Typography>
              <Box sx={assignedBoxStyles}>
                {event?.attendees?.length
                  ? event.attendees.map((attendee: any, index: number) => (
                      <Box key={index} sx={avatarInnerBox}>
                        <Box sx={attendeeBoxStyles(index)}>
                          <Avatar
                            src={attendee.attendee}
                            sx={avatarImage}
                            alt="Attendee"
                          />
                        </Box>
                        <Typography sx={avatarName}>{attendee.name}</Typography>
                      </Box>
                    ))
                  : null}
              </Box>
            </Box>
          </Box>
        }
      >
        <span>{children}</span>
      </Tooltip>
    </ClickAwayListener>
  );
};

export default EventTooltip;
