import { styled } from '@mui/system';
import { Box, Card, IconButton, Typography } from '@mui/material';

export const StyledContainer = styled(Box)(() => ({
  marginTop: '46px',
  display: 'flex',
  flexWrap: 'wrap',
  rowGap: '12px',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const StyledTitleTypography = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: '16px',
}));
export const StyledTitle = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: '24px',
}));

export const StyledButtonGroup = styled(Box)(() => ({
  display: 'flex',
  gap: '16px',
  flexWrap: 'wrap',
}));

export const StyledButtonSubGroup = styled(Box)(() => ({
  display: 'flex',
  gap: '12px',
}));

export const StyledCard = styled(Card)(() => ({
  boxShadow: '0px 1px 2px 0px #0000001A',
  height: '100%',
}));

export const StyldContentWrapper = styled(Box)(({ theme }) => ({
  padding: '15px',
  [theme.breakpoints.up('md')]: {
    padding: '24px',
  },
}));

export const StyldDetailsWrapper = styled(Box)(({ theme }) => ({
  padding: '0px 16px 0px 16px',
  [theme.breakpoints.up('md')]: {
    padding: '0px 33px 0px 37px',
  },
}));

export const StyledHeaderBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const StyledPaymentActionBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '16px',
  marginTop: '16px',
}));

export const StyledContentColumn = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '16px',
}));

export const StyledItemBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '2px',
}));

export const StyledLabelTypography = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: '14px',
  color: '#828282',
}));

export const StyledInfoIconBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  rowGap: '2px',
  columnGap: '8px',
}));

export const StyledMap = styled(Box)(() => ({
  height: '194px',
  backgroundColor: '#c4c2c2',
}));

export const StyledIconButton = styled(IconButton)(() => ({
  height: '30px',
  width: '26px',
}));

export const StyledId = styled('span')(() => ({
  color: '#279660',
}));

export const StyledGrid = styled(Box)(({ theme }) => ({
  padding: '24px 0 30px 0',
  display: 'grid',
  gap: '24px',
  gridTemplateColumns: '1fr',
  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: '1fr 1fr',
  },
  [theme.breakpoints.up('lg')]: {
    gridTemplateColumns: '1fr 1fr 1fr',
    gridTemplateRows: 'auto auto',
  },
  gridAutoRows: '1fr',

  '& > :nth-of-type(1), & > :nth-of-type(2), & > :nth-of-type(3)': {
    gridRow: 'span 1',
  },
  '& > :nth-of-type(4), & > :nth-of-type(5)': {
    gridRow: 'span 1',
  },
}));
