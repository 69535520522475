import { AxiosResponse } from 'axios';
import { request } from '@/utils';
import {
  CategoryInterface,
  CategoryOptions,
  SubCategoryInterface,
} from '@plenumpro/shared';

const base = '/categories';

export const addCategoriesApi = (data: Partial<CategoryInterface> | null) =>
  request({ url: base, method: 'post', data });

export const addsubcategoriesApi = (
  data: Partial<SubCategoryInterface> | null,
) => request({ url: `${base}/subCategory`, method: 'post', data });

export const getCategoryIdApi = async (
  categoryId: string,
): Promise<CategoryOptions[]> => {
  const response = await request({
    url: `${base}/${categoryId}/options`,
    method: 'get',
  });
  return (response as AxiosResponse<CategoryOptions[]>).data;
};

export const getAllCategoriesApi = async (): Promise<CategoryInterface[]> => {
  const response = await request({
    url: base,
    method: 'get',
  });
  return (response as AxiosResponse<CategoryInterface[]>).data;
};

export const getSubCategoriesApi = async (): Promise<
  SubCategoryInterface[]
> => {
  const response = await request({
    url: `${base}/subCategory`,
    method: 'get',
  });
  return (response as AxiosResponse<SubCategoryInterface[]>).data;
};

export const fetchCategoriesOptionsApi = async (): Promise<
  CategoryOptions[]
> => {
  const response = await request({
    url: `${base}/options`,
    method: 'get',
  });
  return (response as AxiosResponse<CategoryOptions[]>).data;
};
