import { FC } from 'react';

interface IconProps {
  isActive?: boolean;
}

const MenuIcon: FC<IconProps> = ({ isActive = false }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 10 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 3.875C3.62132 3.875 4.125 3.37132 4.125 2.75C4.125 2.12868 3.62132 1.625 3 1.625C2.37868 1.625 1.875 2.12868 1.875 2.75C1.875 3.37132 2.37868 3.875 3 3.875Z"
        stroke="#828282"
        strokeWidth="2.25"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={isActive ? '#279660' : '#828F87'}
      />
      <path
        d="M3 12.125C3.62132 12.125 4.125 11.6213 4.125 11C4.125 10.3787 3.62132 9.875 3 9.875C2.37868 9.875 1.875 10.3787 1.875 11C1.875 11.6213 2.37868 12.125 3 12.125Z"
        stroke="#828282"
        strokeWidth="2.25"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={isActive ? '#279660' : '#828F87'}
      />
      <path
        d="M3 20.375C3.62132 20.375 4.125 19.8713 4.125 19.25C4.125 18.6287 3.62132 18.125 3 18.125C2.37868 18.125 1.875 18.6287 1.875 19.25C1.875 19.8713 2.37868 20.375 3 20.375Z"
        stroke="#828282"
        strokeWidth="2.25"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={isActive ? '#279660' : '#828F87'}
      />
    </svg>
  );
};

export default MenuIcon;
