import { useQuery } from 'react-query';
import { getScheduling } from './api';
import { request } from '@/utils';

export const useGetScheduling = () => {
  return useQuery('scheduling', getScheduling, {
    staleTime: 5 * 60 * 1000,
    onError: (error: any) => {
      console.error('Error fetching scheduling:', error.message);
    },
  });
};

const getSlotsAvailablityApi = async ({
  tenantId,
  serviceId,
  date,
}: {
  tenantId: string;
  serviceId?: string;
  date: string;
}) => {
  const url = `availability/dates?tenantId=${tenantId}&date=${date}${serviceId && serviceId !== 'Other' ? `&serviceId=${serviceId}` : ''}`;
  return request({
    url,
    method: 'get',
  });
};

export const useFetchSlotsAvailability = ({
  tenantId,
  serviceId,
  date,
}: {
  tenantId: string;
  serviceId?: string;
  date: string;
}) => {
  return useQuery<any>(
    ['getSlots', tenantId, serviceId, date],
    () => getSlotsAvailablityApi({ tenantId, serviceId, date }),
    {
      enabled: !!tenantId,
      onSuccess: data => {},
      onError: (error: any) => {},
    },
  );
};
