import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';

export const TagContainerStyled = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  padding: '4px 8px',
  borderRadius: '15px',
  background: '#4CC374',
}));

export const TagTextStyled = styled(Typography)({
  fontWeight: 700,
  fontSize: '16px',
  color: '#186942',
});
