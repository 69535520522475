import { useContext } from 'react';
import { UserContext } from '@/context';
import { EPermissions } from '@plenumpro/shared';
import { SidebarItem } from '@/utils/constants/sidebarItems';

const useCanAccess = (
  requiredPermissions: EPermissions[] | undefined,
): boolean => {
  const { user } = useContext(UserContext);

  if (!requiredPermissions || requiredPermissions.length === 0) {
    return true;
  }
  if (!user || !user.permissions) {
    return false;
  }
  return requiredPermissions.some(permission =>
    user.permissions?.includes(permission),
  );
};

export const canAccessSidebarItems = (
  items: SidebarItem[],
  permissions: EPermissions[] | undefined,
) => {
  return items.filter(item =>
    // !item.permissions ||
    item?.permissions?.some(permission => permissions?.includes(permission)),
  );
};

export default useCanAccess;
